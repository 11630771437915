/* eslint-disable */
import React, { useState,useEffect} from 'react';
import { Box } from "@mui/material";
import CommonLandingPageAppBar from "../common-landing-page-appbar";
import LandingPageFooter from '../landingPages/landing-page-footer';
import "../../../css/pricing/css/bootstrap.css";
import "../../../css/pricing/css/responsive.css";
import "../../../css/pricing/css/style.css";
import star1 from '../../../assets/star1.png';
import star2 from '../../../assets/star2.png';
import savemorebg from '../../../assets/saveMore_bg.svg';
import linearrow from '../../../assets/Line arrow-right (1).svg';
import checkin from '../../../assets/checkIcn.svg';
import popularimg from '../../../assets/popularImg.svg';
import righttick from '../../../assets/rightTick.svg';
import close from '../../../assets/close.svg';
import contactbg from '../../../assets/contactBg.svg';
import clientlogo from '../../../assets/client-logo.svg';
import clientlogomobile from '../../../assets/clientLogo-mobi.svg';
import asset1 from '../../../assets/Asset 1.svg';
import asset2 from '../../../assets/Asset 2.svg';
import asset3 from '../../../assets/Asset 3.svg';
import asset4 from '../../../assets/Asset 4.svg';
import asset5 from '../../../assets/Asset 5.svg';
import asset6 from '../../../assets/Asset 6.svg';
import asset9 from '../../../assets/Asset 9.svg';
import asset10 from '../../../assets/Asset 10.svg';
import asset11 from '../../../assets/Asset 11.svg';
import { useNavigate } from 'react-router-dom';

function PricingPages() {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('enterprise');
    const [isCompareFeatureOpen, setIsCompareFeatureOpen] = useState(true);
    const [isCpaModelOpen, setIsCpaModelOpen] = useState(true);
    const [isFlatFeeModelOpen, setIsFlatFeeModelOpen] = useState(false);
    const [openFAQ, setOpenFAQ] = useState(1);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleContactClick = () => {
        window.open(
            "https://outlook.office365.com/owa/calendar/ProductDemo@ttlmedia.in/bookings/",
            "_blank",
            "noopener noreferrer"
        );
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
    };

    const toggleCompareFeature = () => {
        setIsCompareFeatureOpen(!isCompareFeatureOpen);
        setIsCpaModelOpen(false);
        setIsFlatFeeModelOpen(false);
    };

    const toggleCpaModel = () => {
        setIsCpaModelOpen(!isCpaModelOpen);
        setIsFlatFeeModelOpen(false);
    };

    const toggleFlatFeeModel = () => {
        setIsFlatFeeModelOpen(!isFlatFeeModelOpen);
        setIsCpaModelOpen(false);
    };
    const toggleFAQ = (faqIndex) => {
        setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
    };
     
    const ecLandingNav = async () => {
        navigate('/ec-landing-page/915432167890/Organic');
    }

    useEffect(() => {
        const storedActiveTab = localStorage.getItem('activeTab');
        if (storedActiveTab) {
          setActiveTab(storedActiveTab);
        }
      }, []);
      useEffect(() => {
        if (location.pathname !== '/signin') {
          const storedActiveTab = localStorage.getItem('activeTab');
          if (storedActiveTab) {
            setActiveTab(storedActiveTab);
          }
        }
      }, [location.pathname]);
      const signinNav = () => {
        if (activeTab === 'creators') {
          localStorage.setItem('activeTab', 'creators'); 
        }
        navigate('/signin'); 
      };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CommonLandingPageAppBar
                    handleDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                    setMobileOpen={setMobileOpen} />

            </Box><div className="PricingDetails">

                {/*START :: Creator Campaigns Section*/}
                <section className="CreatorSection">
                    <div className="container">
                        <div className="CreatorDetails">
                            <div className="StarImg">
                                <img src={star1} alt="star1 img" />
                            </div>
                            <div className="StarImg text-end">
                                <img src={star2} alt="star2 img" />
                            </div>
                            <p className="MainHeading">
                                Choose the
                                <span style={{ backgroundColor: "#21d73399", borderRadius: 50 , padding: "0px 0px 5px 0px"}}>
                                    {" "}
                                    creator campaigns{" "}
                                </span>
                                that perfectly align with your business goals.&nbsp;
                            </p>
                            <p className="content">
                                Execute targeted collaborations to maximize your brand's reach and engagement. Leverage strategic partnerships with the right creators to ensure your message resonates with the ideal audience, driving impactful results.
                            </p>
                        </div>
                    </div>
                </section>
                {/*START :: Tabbing Section*/}
                <section className="TabbingSection">
                    <div className="TabsDetails">
                        <div className="TabsSection">
                            <div className="card" style={{ border: "none" }}>
                                <nav>
                                    <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                        <button
                                            className={`nav-link ${activeTab === 'enterprise' ? 'active' : ''}`}
                                            id="nav-home-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-home"
                                            aria-selected={activeTab === 'enterprise'}
                                            onClick={() => handleTabChange('enterprise')}
                                        >
                                            Enterprise Clients
                                        </button>
                                        <button
                                            className={`nav-link ${activeTab === 'creators' ? 'active' : ''}`}
                                            id="nav-profile-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-profile"
                                            aria-selected={activeTab === 'creators'}
                                            onClick={() => handleTabChange('creators')}
                                        >
                                            Creators&nbsp;
                                        </button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div
                                        className="tab-pane fade active show"
                                        id="nav-home"
                                        role="tabpanel"
                                        aria-labelledby="nav-home-tab"
                                    >
                                        {/*START :: Save More With Goodplans For Enterprise client*/}
                                        {activeTab === 'enterprise' && (

                                            <section className="GoodplansSection">
                                                <div className="container">
                                                    <div className="PlansDetails">
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-4">
                                                                <div
                                                                    className="ModelInfo"
                                                                    style={{
                                                                        backgroundImage: `url(${savemorebg})`,
                                                                    }}
                                                                >
                                                                    <p className="MainHeading">
                                                                        <span style={{ color: "#9BE8A3" }}>Save More</span>
                                                                        <br /> With Right Plans
                                                                    </p>
                                                                    <p className="content">
                                                                        Select the best plan according to your brand's preferences.
                                                                    </p>
                                                                    <img src={linearrow} alt="linearrow img" />
                                                                    {/* <div class="text-end">
      <img src="img/saveMore_bg.svg" width="100%">
  </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="ModelInfo1 active-popular">
                                                                    {/* <div className="onHoverDiv text-end"> */}
                                                                    <div className="popularimg text-end">

                                                                        <img src={popularimg} alt="popularimg img" />
                                                                    </div>
                                                                    <p className="MainHeading">Flat Fee Model</p>
                                                                    <p className="content">
                                                                        Ideal for driving brand awareness, generating leads,
                                                                        executing long-term campaigns, and supporting the
                                                                        growth of emerging or established brands.
                                                                    </p>
                                                                    <button className="TryBtn" onClick={ecLandingNav}>For Businesses</button>
                                                                    <div className="CreatorCount">
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Count
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Campaign Success Manager
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Campaign Blueprint
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Personalized Creator Match
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Performance Reports
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Campaign Customization
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Production Support
                                                                        </p>
                                                                    </div>
                                                                    <p className="InfoLink">No credit card required</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="ModelInfo1">
                                                                    <p className="MainHeading">CPA Model</p>
                                                                    <p className="content">
                                                                        Designed for initial requirements or first-time
                                                                        content creation needs for businesses
                                                                    </p>
                                                                    <button className="TryBtn" onClick={ecLandingNav}>For Businesses</button>
                                                                    <div className="CreatorCount">
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" /> Account Manager
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Creator Management
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Activity Monitoring
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Project Coordination
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Content Variety
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p className="">
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Creator Insights
                                                                        </p>
                                                                    </div>
                                                                    <p className="InfoLink no-credit-margin">No credit card required</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        )}
                                        {/*START :: Save More With Goodplans For Creators*/}
                                        {activeTab === 'creators' && (
                                            <section className="GoodplansSection">
                                                <div className="container">
                                                    <div className="PlansDetails">
                                                        <div className="row-creators">
                                                            <div className="col-md-12 col-lg-4">
                                                                <div
                                                                    className="ModelInfo"
                                                                    style={{
                                                                        backgroundImage: `url(${savemorebg})`,
                                                                    }}
                                                                >
                                                                    <p className="MainHeading">
                                                                        <span style={{ color: "#9BE8A3" }}>Save More</span>
                                                                        <br /> With Right Plans
                                                                    </p>
                                                                    <p className="content">
                                                                        Select the best plan according to your brand's preferences.
                                                                    </p>
                                                                    <img src={linearrow} alt="linearrow img" />
                                                                    {/* <div class="text-end">
<img src="img/saveMore_bg.svg" width="100%">
</div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="ModelInfo1">
                                                                    <p className="MainHeading">CPA Model</p>
                                                                    <p className="content">
                                                                        Are you a creator specializing in digital content? You’re in the right place. Discover projects that align with your interests and apply to those within your geographical area.
                                                                    </p>
                                                                    <button className="TryBtn" onClick={signinNav}>For Businesses</button>
                                                                    <div className="CreatorCount">
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" /> Account Support
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Profile Growth Support (on request)
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Payment Support
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Diverse Content Opportunities
                                                                        </p>
                                                                        <div
                                                                            className=""
                                                                            style={{
                                                                                borderBottom: "1px solid #E0EBFF",
                                                                                margin: "5px 0px"
                                                                            }} />
                                                                        <p>
                                                                            <img src={checkin} alt="checkin img" />
                                                                            Access Profile Insights
                                                                        </p>

                                                                    </div>
                                                                    <p className="InfoLink nocredit-for-creators">No credit card required</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="ReviewInfo collaborate">
                                                                    <p className="MainHeading">
                                                                        Collaborate and Grow with Fellow Creators.
                                                                    </p>
                                                                    <div className="CreatorCount">
                                                                        <div>
                                                                            Learn from fellow creators on how they manage their daily routines to help you grow. Collaborate with creators in your area to share ideas, build your profile, and develop a niche based on the best content. Receive tips and ideas for growing your audience.
                                                                        </div>
                                                                        <div className='creator-collab'>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Guaranteed Project Flow</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Assured Payments</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Data-Driven Insights</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Expert Guidance</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Comprehensive Support</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                        {/*START :: Features Section*/}
                                        <section className="FeaturesSection no-mobi">
                                            <div className="container">
                                                <div className="accordion" id="accordionExample1">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">

                                                            <button
                                                                className={`accordion-button ${isCompareFeatureOpen ? '' : 'collapsed'}`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseAllFeatures"
                                                                aria-expanded={isCompareFeatureOpen}
                                                                aria-controls="collapseAllFeatures"
                                                                onClick={toggleCompareFeature}
                                                            >
                                                                Compare all features
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseAllFeatures"
                                                            // className="accordion-collapse collapse show"
                                                            className={`accordion-collapse collapse ${isCompareFeatureOpen ? "show" : ""}`}
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#accordionExample1"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="FeaturesDetails">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Features</th>
                                                                                <th scope="col" className="text-center">
                                                                                    CPA Model
                                                                                </th>
                                                                                <th scope="col" className="text-center">
                                                                                    Flat Fee Model
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Budget</td>
                                                                                <td className="text-center">
                                                                                    5000+ Billing Results
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    Based on Packages
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Count</td>
                                                                                <td className="text-center">Upto 9</td>
                                                                                <td className="text-center">From 10 -100</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Account Manager</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Success Manager</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />

                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Blueprint</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Personalized Creator Match</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Management</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Performance Reports</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Activity Monitoring</td>
                                                                                <td className="text-center">7 Days</td>
                                                                                <td className="text-center">
                                                                                    Campaign Duration
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Health Checks</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Project Coordination</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Customization</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Content Variety</td>
                                                                                <td className="text-center">Only Reels</td>
                                                                                <td className="text-center">
                                                                                    Reels, Video Stories, Creative Stories
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Immersive Activities</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Community Outreach</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Search</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Export</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Insights</td>
                                                                                <td className="text-center">Basic</td>
                                                                                <td className="text-center">Advanced</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Production Support</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Updates</td>
                                                                                <td className="text-center">Email</td>
                                                                                <td className="text-center">
                                                                                    Whatsapp/Email
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Help Desk</td>
                                                                                <td className="text-center">Email</td>
                                                                                <td className="text-center">
                                                                                    Whatsapp/Email
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ borderColor: "transparent" }}>
                                                                                <td />
                                                                                <td className="text-center">
                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                        FOR BUSINESSES
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                        FOR BUSINESSES
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="NoCredit">
                                                                                <td />
                                                                                <td className="text-center">
                                                                                    No credit card required
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    No credit card required
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="FeaturesSection no-desktop show-mobi">
                                            <div className="container">
                                                <div className="accordion" id="accordionCompareExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FeaturesMainHeading">
                                                            <button
                                                                className={`accordion-button ${isCompareFeatureOpen ? '' : 'collapsed'}`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseAllFeatures"
                                                                aria-expanded={isCompareFeatureOpen}
                                                                aria-controls="collapseAllFeatures"
                                                                onClick={toggleCompareFeature}
                                                            >
                                                                Compare all features
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseFeatures"
                                                            // className="accordion-collapse collapse show"
                                                            className={`accordion-collapse collapse ${isCompareFeatureOpen ? "show" : ""}`}
                                                            aria-labelledby="FeaturesMainHeading"
                                                            data-bs-parent="#accordionCompareExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="FeaturesDetails">
                                                                    <div
                                                                        className="accordion"
                                                                        id="accordionAllFeaturesExample"
                                                                    >
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="headingOne"
                                                                            >
                                                                                <button
                                                                                    className={`accordion-button ${isCpaModelOpen ? '' : 'collapsed'}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    // aria-expanded="true"
                                                                                    aria-expanded={isCpaModelOpen}
                                                                                    aria-controls="collapseOne"
                                                                                    onClick={toggleCpaModel}
                                                                                >
                                                                                    <p style={{ marginBottom: 0 }}>
                                                                                        CPA Model
                                                                                        <br />
                                                                                        <span>5000+ Billing Results</span>
                                                                                    </p>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseOne"
                                                                                // className="accordion-collapse collapse show"
                                                                                className={`accordion-collapse collapse ${isCpaModelOpen ? "show" : ""}`}
                                                                                aria-labelledby="headingOne"
                                                                                data-bs-parent="#accordionAllFeaturesExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Budget</td>
                                                                                                <td className="text-center">
                                                                                                    5000+ Billing Results
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Count</td>
                                                                                                <td className="text-center">
                                                                                                    Upto 9
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Account Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Success Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Blueprint</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Personalized Creator Match</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Management</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Performance Reports</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Activity Monitoring</td>
                                                                                                <td className="text-center">
                                                                                                    7 Days
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Health Checks</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Project Coordination</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Customization</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Content Variety</td>
                                                                                                <td className="text-center">
                                                                                                    Only Reels
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Immersive Activities</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Community Outreach</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Search</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Export</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Insights</td>
                                                                                                <td className="text-center">Basic</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Production Support</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Updates</td>
                                                                                                <td className="text-center">Email</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Help Desk</td>
                                                                                                <td className="text-center">Email</td>
                                                                                            </tr>
                                                                                            <tr
                                                                                                style={{ borderColor: "transparent" }}
                                                                                            >
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                                        FOR BUSINESSES
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="NoCredit">
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    No credit card required
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="headingThree"
                                                                            >
                                                                                <button
                                                                                    className={`accordion-button collapsed${isFlatFeeModelOpen ? '' : 'collapsed'}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseThree"
                                                                                    // aria-expanded="false"
                                                                                    aria-expanded={isFlatFeeModelOpen}
                                                                                    aria-controls="collapseThree"
                                                                                    onClick={toggleFlatFeeModel}
                                                                                >
                                                                                    <p style={{ marginBottom: 0 }}>
                                                                                        Flat Fee Model
                                                                                        <br />
                                                                                        <span>Based on Packages</span>
                                                                                    </p>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseThree"
                                                                                // className="accordion-collapse collapse"
                                                                                className={`accordion-collapse collapse ${isFlatFeeModelOpen ? "show" : ""}`}
                                                                                aria-labelledby="headingThree"
                                                                                data-bs-parent="#accordionAllFeaturesExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Budget</td>
                                                                                                <td className="text-center">
                                                                                                    Based on Packages
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Count</td>
                                                                                                <td className="text-center">
                                                                                                    From 10 -100
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Account Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Success Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Blueprint</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Personalized Creator Match</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Management</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Performance Reports</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Activity Monitoring</td>
                                                                                                <td className="text-center">
                                                                                                    Campaign Duration
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Health Checks</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Project Coordination</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Customization</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Content Variety</td>
                                                                                                <td className="text-center">
                                                                                                    Reels, Video Stories, Creative
                                                                                                    Stories
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Immersive Activities</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Community Outreach</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Search</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Export</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Insights</td>
                                                                                                <td className="text-center">
                                                                                                    Advanced
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Production Support</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Updates</td>
                                                                                                <td className="text-center">
                                                                                                    Whatsapp/Email
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Help Desk</td>
                                                                                                <td className="text-center">
                                                                                                    Whatsapp/Email
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr
                                                                                                style={{ borderColor: "transparent" }}
                                                                                            >
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                                        FOR BUSINESSES
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="NoCredit">
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    No credit card required
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/*START :: Contact Us section*/}
                                        <section
                                            className="ContactUs no-mobi"
                                            style={{ backgroundImage: `url(${contactbg})` }}
                                        >
                                            <div className="container">
                                                <div className="ContactDetails">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <p className="content">
                                                                Got questions about our Enterprise plans? Or are you
                                                                an agency looking for a personalized demo?
                                                            </p>
                                                            <button className="ContactBtn" onClick={handleContactClick}>Contact Us</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="ContactUs no-desktop show-mobi">
                                            <div className="container">
                                                <div className="ContactDetails">
                                                    <img src={contactbg} alt="contactbg img" />

                                                    <p className="content">
                                                        Got questions about our Enterprise plans? Or are you an
                                                        agency looking for a personalized demo?
                                                    </p>
                                                    <button className="ContactBtn" onClick={handleContactClick}>Contact Us</button>
                                                </div>
                                            </div>
                                        </section>
                                        {/*START :: Trusted by Organisations*/}
                                        <section className="TrustedOrganizations no-mobi">
                                            <div className="container">
                                                <div className="OrganizationInfo">
                                                    <p className="MainHeading">
                                                        Trusted by Organisations
                                                    </p>

                                                    <div className="logo-business">
                                                        {/* <div className='logo-images'>
                                                            <img src={artwork17} alt="artwork17 img" />
                                                        </div> */}
                                                        <div className='logo-images-logo'>
                                                            <img src={asset1} alt="asset1 img" />
                                                        </div>
                                                        <div className='logo-images'>
                                                            <img src={asset2} alt="asset2 img" />
                                                        </div>
                                                        <div className='logo-images'>
                                                            <img src={asset3} alt="asset3 img" />
                                                        </div>
                                                        <div className='logo-images pricing-setu-logo'>
                                                            <img src={asset4} alt="asset4 img" />
                                                        </div>
                                                        <div className='logo-images-logo'>
                                                            <img src={asset5} alt="asset5 img" />
                                                        </div>
                                                        <div className='logo-images'>
                                                            <img src={asset6} alt="asset6 img" />
                                                        </div>
                                                        <div className='logo-images'>
                                                            <img src={asset9} alt="asset9 img" />
                                                        </div>

                                                        <div className='logo-images'>
                                                            <img src={asset10} alt="asset10 img" />
                                                        </div>
                                                        <div className='logo-images'>
                                                            <img src={asset11} alt="asset11 img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="TrustedOrganizations no-desktop show-mobi">
                                            <div className="container">
                                                <div className="OrganizationInfo">
                                                    <p className="MainHeading">
                                                        Trusted by Organisations
                                                    </p>
                                                    <div className='pricing-logo-set'>
                                                        <div className='pricing-logo-business-mobile-view' >
                                                            <div className='pricing-logo-images-logo'>
                                                                <img src={asset1} alt="asset1 img" />
                                                            </div>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset2} alt="asset2 img" />
                                                            </div>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset3} alt="asset3 img" />
                                                            </div>
                                                        </div>
                                                        <div className='pricing-logo-business-mobile-view' >
                                                            <div className='pricing-logo-images pricing-setu-logo'>
                                                                <img src={asset4} alt="asset4 img" />
                                                            </div>
                                                            <div className='pricing-logo-images-logo'>
                                                                <img src={asset5} alt="asset5 img" />
                                                            </div>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset6} alt="asset6 img" />
                                                            </div>
                                                        </div>
                                                        <div className='pricing-logo-business-mobile-view'>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset9} alt="asset9 img" />
                                                            </div>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset10} alt="asset10 img" />
                                                            </div>
                                                            <div className='pricing-logo-images'>
                                                                <img src={asset11} alt="asset11 img" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </section>
                                        {/*START :: Frequently Asked Questions*/}
                                        <section className="QuestionsSection">
                                            <div className="container">
                                                <div className="QueDetails">
                                                    <p className="MainHeading no-mobi">
                                                        Frequently Asked Questions
                                                    </p>
                                                    <p className="MainHeading show-mobi no-desktop">FAQ</p>
                                                    <div className="accordion" id="accordionQueExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    // className="accordion-button"
                                                                    // className={`accordion-button ${openFAQ ? '' : 'collapsed'}`}
                                                                    className={`accordion-button ${openFAQ === 1 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    // aria-expanded="true"
                                                                    aria-expanded={openFAQ === 1}
                                                                    aria-controls="collapseOne"
                                                                    onClick={() => toggleFAQ(1)}
                                                                >
                                                                    Are there any contracts and commitments?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseOne"
                                                                // className="accordion-collapse collapse show"
                                                                className={`accordion-collapse collapse ${openFAQ === 1 ? "show" : ""}`}
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    For Enterprise Clients, contracts are applicable only for the duration of the campaign. While the intellectual property (IP) remains with the creators, Oye Creators retains the rights, which can be purchased at an additional premium after the content goes live.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 2 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 2}
                                                                    aria-controls="collapseTwo"
                                                                    onClick={() => toggleFAQ(2)}
                                                                >
                                                                    What payment options do I have?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseTwo"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 2 ? "show" : ""}`}
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    All payments must be made prior to the campaign going live. Once the campaign is live, no further payments are required unless additional services or value-added features are purchased.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 3 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 3}
                                                                    aria-controls="collapseThree"
                                                                    onClick={() => toggleFAQ(3)}
                                                                >
                                                                    Can I change my plan?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseThree"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 3 ? "show" : ""}`}
                                                                aria-labelledby="headingThree"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    You can modify your plan up until the campaign goes live. Once the campaign is live, no changes to the plan are permitted.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFour">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 4 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 4}
                                                                    aria-controls="collapseFour"
                                                                    onClick={() => toggleFAQ(4)}
                                                                >
                                                                    Do I have to negotiate prices with the creators?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFour"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 4 ? "show" : ""}`}
                                                                aria-labelledby="headingFour"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    No, one of the key benefits of our platform is that pricing is determined based on the engagement metrics of the creators' profiles, eliminating the need for individual negotiations.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFive">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 5 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 4}
                                                                    aria-controls="collapseFive"
                                                                    onClick={() => toggleFAQ(5)}
                                                                >
                                                                    Are there any discounts available for startups?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 5 ? "show" : ""}`}
                                                                aria-labelledby="headingFive"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    We offer specialized startup plans. For more details, please contact our support team at help@ttlmedia.in.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFive">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 6 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 4}
                                                                    aria-controls="collapseFive"
                                                                    onClick={() => toggleFAQ(6)}
                                                                >
                                                                    Do you provide refunds?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 6 ? "show" : ""}`}
                                                                aria-labelledby="headingFive"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    We do not offer refunds; however, the amount paid can be utilized to create new campaigns. This option is available only in specific cases and is subject to the discretion of our Support Administrators.                                                                 </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="nav-profile"
                                        role="tabpanel"
                                        aria-labelledby="nav-profile-tab"
                                    >
                                        {/*START :: Save More With Goodplans For Creators*/}
                                        {activeTab === 'creators' && (
                                            <>
                                                <section className="GoodplansSection">
                                                    <div className="container">
                                                        <div className="PlansDetails">
                                                            <div className="row-creators">
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div
                                                                        className="ModelInfo"
                                                                        style={{
                                                                            backgroundImage: `url(${savemorebg})`,
                                                                        }}
                                                                    >
                                                                        <p className="MainHeading">
                                                                            <span style={{ color: "#9BE8A3" }}>Save More</span>
                                                                            <br /> With Right Plans
                                                                        </p>
                                                                        <p className="content">
                                                                            Select the best plan according to your brand's preferences.
                                                                        </p>
                                                                        <img src={linearrow} alt="linearrow img" />
                                                                        {/* <div class="text-end">
<img src="img/saveMore_bg.svg" width="100%">
</div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="ModelInfo1">
                                                                        <p className="MainHeading">CPA Model</p>
                                                                        <p className="content">
                                                                            Are you a creator specializing in digital content? You’re in the right place. Discover projects that align with your interests and apply to those within your geographical area.
                                                                        </p>
                                                                        <button className="TryBtn">For Businesses</button>
                                                                        <div className="CreatorCount">
                                                                            <p>
                                                                                <img src={checkin} alt="checkin img" /> Account Support
                                                                            </p>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    borderBottom: "1px solid #E0EBFF",
                                                                                    margin: "5px 0px"
                                                                                }} />
                                                                            <p>
                                                                                <img src={checkin} alt="checkin img" />
                                                                                Profile Growth Support (on request)
                                                                            </p>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    borderBottom: "1px solid #E0EBFF",
                                                                                    margin: "5px 0px"
                                                                                }} />
                                                                            <p>
                                                                                <img src={checkin} alt="checkin img" />
                                                                                Payment Support
                                                                            </p>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    borderBottom: "1px solid #E0EBFF",
                                                                                    margin: "5px 0px"
                                                                                }} />
                                                                            <p>
                                                                                <img src={checkin} alt="checkin img" />
                                                                                Diverse Content Opportunities
                                                                            </p>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    borderBottom: "1px solid #E0EBFF",
                                                                                    margin: "5px 0px"
                                                                                }} />
                                                                            <p>
                                                                                <img src={checkin} alt="checkin img" />
                                                                                Access Profile Insights
                                                                            </p>

                                                                        </div>
                                                                        <p className="InfoLink">No credit card required</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="ReviewInfo">
                                                                        <p className="MainHeading">
                                                                            Collaborate and Grow with Fellow Creators.
                                                                        </p>
                                                                        <div className="CreatorCount">
                                                                            <div>
                                                                                Learn from fellow creators on how they manage their daily routines to help you grow. Collaborate with creators in your area to share ideas, build your profile, and develop a niche based on the best content. Receive tips and ideas for growing your audience.
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Guaranteed Project Flow</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Assured Payments</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Data-Driven Insights</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Expert Guidance</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-1 col-1 pr-0mobi">
                                                                                    <img src={checkin} alt="checkin img" />
                                                                                </div>
                                                                                <div class="col-md-11 col-11 pl-0mobi">
                                                                                    <p>Comprehensive Support</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section></>
                                        )}
                                        {/*START :: Features Section*/}
                                        <section className="FeaturesSection no-mobi">
                                            <div className="container">
                                                <div className="accordion" id="accordionExample1">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button
                                                                className={`accordion-button ${isCompareFeatureOpen ? '' : 'collapsed'}`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseAllFeatures"
                                                                aria-expanded={isCompareFeatureOpen}
                                                                aria-controls="collapseAllFeatures"
                                                                onClick={toggleCompareFeature}
                                                            >
                                                                Compare all features
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseAllFeatures"
                                                            // className="accordion-collapse collapse show"
                                                            className={`accordion-collapse collapse ${isCompareFeatureOpen ? "show" : ""}`}
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#accordionExample1"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="FeaturesDetails">
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Features</th>
                                                                                <th scope="col" className="text-center">
                                                                                    CPA Model
                                                                                </th>
                                                                                <th scope="col" className="text-center">
                                                                                    Flat Fee Model
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Budget</td>
                                                                                <td className="text-center">
                                                                                    5000+ Billing Results
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    Based on Packages
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Count</td>
                                                                                <td className="text-center">Upto 9</td>
                                                                                <td className="text-center">From 10 -100</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Account Manager</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Success Manager</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Blueprint</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Personalized Creator Match</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Management</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Performance Reports</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Activity Monitoring</td>
                                                                                <td className="text-center">7 Days</td>
                                                                                <td className="text-center">
                                                                                    Campaign Duration
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Health Checks</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Project Coordination</td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Campaign Customization</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Content Variety</td>
                                                                                <td className="text-center">Only Reels</td>
                                                                                <td className="text-center">
                                                                                    Reels, Video Stories, Creative Stories
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Immersive Activities</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Community Outreach</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Search</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Export</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Creator Insights</td>
                                                                                <td className="text-center">Basic</td>
                                                                                <td className="text-center">Advanced</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Production Support</td>
                                                                                <td className="text-center">
                                                                                    <img src={close} alt="close img" />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <img src={righttick} alt="righttick img" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Updates</td>
                                                                                <td className="text-center">Email</td>
                                                                                <td className="text-center">
                                                                                    Whatsapp/Email
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Help Desk</td>
                                                                                <td className="text-center">Email</td>
                                                                                <td className="text-center">
                                                                                    Whatsapp/Email
                                                                                </td>
                                                                            </tr>
                                                                            <tr style={{ borderColor: "transparent" }}>
                                                                                <td />
                                                                                <td className="text-center">
                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                        FOR BUSINESSES
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                        FOR BUSINESSES
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="NoCredit">
                                                                                <td />
                                                                                <td className="text-center">
                                                                                    No credit card required
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    No credit card required
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="FeaturesSection no-desktop show-mobi">
                                            <div className="container">
                                                <div className="accordion" id="accordionCompareExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="FeaturesMainHeading">
                                                            <button
                                                                className={`accordion-button ${isCompareFeatureOpen ? '' : 'collapsed'}`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseAllFeatures"
                                                                aria-expanded={isCompareFeatureOpen}
                                                                aria-controls="collapseAllFeatures"
                                                                onClick={toggleCompareFeature}
                                                            >
                                                                Compare all features
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseFeatures"
                                                            // className="accordion-collapse collapse show"
                                                            className={`accordion-collapse collapse ${isCompareFeatureOpen ? "show" : ""}`}
                                                            aria-labelledby="FeaturesMainHeading"
                                                            data-bs-parent="#accordionCompareExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="FeaturesDetails">
                                                                    <div
                                                                        className="accordion"
                                                                        id="accordionComAllExample"
                                                                    >
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="headingOne"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapseOne"
                                                                                >
                                                                                    <p style={{ marginBottom: 0 }}>
                                                                                        CPA Model
                                                                                        <br />
                                                                                        <span>5000+ Billing Results</span>
                                                                                    </p>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseOne"
                                                                                className="accordion-collapse collapse show"
                                                                                aria-labelledby="headingOne"
                                                                                data-bs-parent="#accordionComAllExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Budget</td>
                                                                                                <td className="text-center">
                                                                                                    5000+ Billing Results
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Count</td>
                                                                                                <td className="text-center">
                                                                                                    Upto 9
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Account Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Success Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Blueprint</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Personalized Creator Match</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Management</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Performance Reports</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Activity Monitoring</td>
                                                                                                <td className="text-center">
                                                                                                    7 Days
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Health Checks</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Project Coordination</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Customization</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Content Variety</td>
                                                                                                <td className="text-center">
                                                                                                    Only Reels
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Immersive Activities</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Community Outreach</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Search</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Export</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Insights</td>
                                                                                                <td className="text-center">Basic</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Production Support</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={close} alt="close img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Updates</td>
                                                                                                <td className="text-center">Email</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Help Desk</td>
                                                                                                <td className="text-center">Email</td>
                                                                                            </tr>
                                                                                            <tr
                                                                                                style={{ borderColor: "transparent" }}
                                                                                            >
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                                        FOR BUSINESSES
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="NoCredit">
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    No credit card required
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="headingThree"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseThree"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapseThree"
                                                                                >
                                                                                    <p style={{ marginBottom: 0 }}>
                                                                                        Flat Fee Model
                                                                                        <br />
                                                                                        <span>Based on Packages</span>
                                                                                    </p>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseThree"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="headingThree"
                                                                                data-bs-parent="#accordionComAllExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Budget</td>
                                                                                                <td className="text-center">
                                                                                                    Based on Packages
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Count</td>
                                                                                                <td className="text-center">
                                                                                                    From 10 -100
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Account Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Success Manager</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Blueprint</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Personalized Creator Match</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Management</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Performance Reports</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Activity Monitoring</td>
                                                                                                <td className="text-center">
                                                                                                    Campaign Duration
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Health Checks</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Project Coordination</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Campaign Customization</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Content Variety</td>
                                                                                                <td className="text-center">
                                                                                                    Reels, Video Stories, Creative
                                                                                                    Stories
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Immersive Activities</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Community Outreach</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Search</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Export</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Creator Insights</td>
                                                                                                <td className="text-center">
                                                                                                    Advanced
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Production Support</td>
                                                                                                <td className="text-center">
                                                                                                    <img src={righttick} alt="righttick img" />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Updates</td>
                                                                                                <td className="text-center">
                                                                                                    Whatsapp/Email
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Help Desk</td>
                                                                                                <td className="text-center">
                                                                                                    Whatsapp/Email
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr
                                                                                                style={{ borderColor: "transparent" }}
                                                                                            >
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    <button className="TryForFreeBtn" onClick={ecLandingNav}>
                                                                                                        FOR BUSINESSES
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="NoCredit">
                                                                                                <td />
                                                                                                <td className="text-center">
                                                                                                    No credit card required
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/*START :: Contact Us section*/}
                                        <section
                                            className="ContactUs no-mobi"
                                            style={{ backgroundImage: `url(${contactbg})` }}
                                        >
                                            <div className="container">
                                                <div className="ContactDetails">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <p className="content">
                                                                Got questions about our Enterprise plans? Or are you
                                                                an agency looking for a personalized demo?
                                                            </p>
                                                            <button className="ContactBtn" onClick={handleContactClick}>Contact Us</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="ContactUs no-desktop show-mobi">
                                            <div className="container">
                                                <div className="ContactDetails">
                                                    <img src={contactbg} alt="contactbg img" />
                                                    <p className="content">
                                                        Got questions about our Enterprise plans? Or are you an
                                                        agency looking for a personalized demo?
                                                    </p>
                                                    <button className="ContactBtn" onClick={handleContactClick}>Contact Us</button>
                                                </div>
                                            </div>
                                        </section>
                                        {/*START :: Trusted by Organisations*/}
                                        <section className="TrustedOrganizations no-mobi">
                                            <div className="container">
                                                <div className="OrganizationInfo">
                                                    <p className="MainHeading">
                                                        Trusted by Organisations
                                                    </p>
                                                    <div className="ImgSec">
                                                        <img src={clientlogo} alt="clientlogo img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="TrustedOrganizations no-desktop show-mobi">
                                            <div className="container">
                                                <div className="OrganizationInfo">
                                                    <p className="MainHeading">
                                                        Trusted by Organisations
                                                    </p>
                                                    <div className="ImgSec">
                                                        <img src={clientlogomobile} alt="clientlogomobile img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {/*START :: Frequently Asked Questions*/}
                                        <section className="QuestionsSection">
                                            <div className="container">
                                                <div className="QueDetails">
                                                    <p className="MainHeading no-mobi">
                                                        Frequently Asked Questions
                                                    </p>
                                                    <p className="MainHeading show-mobi no-desktop">FAQ</p>
                                                    <div className="accordion" id="accordionCreatorQueExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    Are there any contracts and commitments?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionCreatorQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    For Enterprise Clients, contracts are applicable only for the duration of the campaign. While the intellectual property (IP) remains with the creators, Oye Creators retains the rights, which can be purchased at an additional premium after the content goes live.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTwo"
                                                                >
                                                                    What payment options do I have?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseTwo"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionCreatorQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    All payments must be made prior to the campaign going live. Once the campaign is live, no further payments are required unless additional services or value-added features are purchased.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseThree"
                                                                >
                                                                    Can I change my plan?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseThree"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingThree"
                                                                data-bs-parent="#accordionCreatorQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    You can modify your plan up until the campaign goes live. Once the campaign is live, no changes to the plan are permitted.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFour">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFour"
                                                                >
                                                                    Do I have to negotiate prices with the creators?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFour"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingFour"
                                                                data-bs-parent="#accordionCreatorQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    No, one of the key benefits of our platform is that pricing is determined based on the engagement metrics of the creators' profiles, eliminating the need for individual negotiations.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFive">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFive"
                                                                >
                                                                    Are there any discounts available for startups?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingFive"
                                                                data-bs-parent="#accordionCreatorQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    We offer specialized startup plans. For more details, please contact our support team at help@ttlmedia.in.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingFive">
                                                                <button
                                                                    // className="accordion-button collapsed"
                                                                    className={`accordion-button ${openFAQ === 6 ? '' : 'collapsed'}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    // aria-expanded="false"
                                                                    aria-expanded={openFAQ === 4}
                                                                    aria-controls="collapseFive"
                                                                    onClick={() => toggleFAQ(5)}
                                                                >
                                                                    Do you provide refunds?
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                // className="accordion-collapse collapse"
                                                                className={`accordion-collapse collapse ${openFAQ === 6 ? "show" : ""}`}
                                                                aria-labelledby="headingFive"
                                                                data-bs-parent="#accordionQueExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    We do not offer refunds; however, the amount paid can be utilized to create new campaigns. This option is available only in specific cases and is subject to the discretion of our Support Administrators.                                                                 </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*START :: Footer Section*/}
                <LandingPageFooter />
            </div></>
    )
}
export default PricingPages;
