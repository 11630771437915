import React, { useState } from 'react';
import "../../css/ec-login.css";
import EcLoginForm from './ec-login-form';
import { Box, ThemeProvider } from '@mui/material';
import { useTheme } from '@emotion/react';
import CommonAppbarDrawer from "../common/common-sidebar-navbar";

const EcLoginPage = ({ cities, userDetails, handleBillingCityInput }) => {
    const theme = useTheme();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="body-container">
                <Box sx={{ display: "flex" }}>
                    <div className="common-desktop-nav">
                        <CommonAppbarDrawer />
                    </div>
                    <Box component="main" sx={{ width: "100%" }}>
                        <div className="login-container">
                            <div className="right-login1">
                                <div className="login-right-container">
                                    <h3 className="heading1">Please Enter Enterprise Client Details</h3>
                                    <div className="input-field">
                                        {!isLoggedIn && (
                                            <EcLoginForm
                                                handleLogin={handleLoginSuccess}
                                                cities={cities}
                                                userDetails={userDetails}
                                                handleBillingCityInput={handleBillingCityInput}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </div>
        </ThemeProvider>
    );
};

export default EcLoginPage;