import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Button, IconButton, Popover, TextField, Typography, Skeleton, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getPlatformGrowthOverview } from './admin-dashboard-repository';

function PlatformGrowthPage() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [dateFilterValue, setDateFilterValue] = useState({ from: "", to: "" });
    const [dateFilterApply, setDateFilterApply] = useState(false);
    const [platformGrowth, setPlatformGrowth] = useState({
        totalCreators: 0, totalCampaigns: 0, enterpriseClients: 0, totalRevenue: 0
    });
    const [loading, setLoading] = useState(false);
    const openFilter = Boolean(anchorEl);
    const id = openFilter ? 'simple-popover' : undefined;

    const handleClearFilter = () => {
        setDateFilterValue({ from: "", to: "" });
        setFromDate(null);
        setToDate(null);
        setDateFilterApply(false);
    }

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleFilterClose = () => {
        setAnchorEl(null);
    }

    const handleFromDateChange = (newDate) => {
        const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
        setFromDate(formattedDate);
        if (toDate && newDate && dayjs(toDate).isBefore(newDate)) {
            setToDate(null);
        }
    };

    const handleToDateChange = (newDate) => {
        const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
        setToDate(formattedDate);
    };

    const handleDateChange = () => {
        setDateFilterValue({ from: fromDate, to: toDate });
        setAnchorEl(null);
        setDateFilterApply(true);
    }

    const platformGrowthMap = [
        { icon: <GroupOutlinedIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: platformGrowth.totalCreators, label: 'Creators', tooltip: 'Total count of all registered creators, segmented by Private, Public, and Incomplete profiles' },
        { icon: <CampaignOutlinedIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: platformGrowth.totalCampaigns, label: 'Total Campaigns', tooltip: 'Overall number of campaigns hosted on the platform' },
        { icon: <AccountCircleOutlinedIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: platformGrowth.enterpriseClients, label: 'Enterprise Clients', tooltip: 'Number of Enterprise Clients' },
        { icon: <ShoppingCartOutlinedIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: platformGrowth.totalRevenue, label: 'Total Revenue', tooltip: 'Financial gains accumulated from the platform operations' }
    ];

    useEffect(() => {
        getPlatformGrowthOverview(setPlatformGrowth, setLoading, dateFilterValue);
    }, [dateFilterValue]);

    return (
        <>
            <div className='platform-growth-text-row'>
                <div className='platform-growth-text'>Platform Growth Overview</div>
                <div>
                    {dateFilterApply
                        ? <IconButton aria-describedby={id} onClick={handleClearFilter}>
                            <EventBusyIcon fontSize='large' />
                        </IconButton>
                        : <IconButton aria-describedby={id} onClick={(e) => handleFilterClick(e)}>
                            <CalendarMonthIcon fontSize='large' />
                        </IconButton>
                    }
                    <Popover id={id} open={openFilter} anchorEl={anchorEl}
                        onClose={handleFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Typography sx={{ p: 3 }}>
                            <div className='date-range-div'>
                                <div className='date-range-col'>
                                    <div className='date-range-text'>From Date:</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={fromDate ? dayjs(fromDate) : null}
                                            onChange={handleFromDateChange}
                                            format="YYYY-MM-DD"
                                            renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className='date-range-col'>
                                    <div className='date-range-text'>To Date:</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={toDate ? dayjs(toDate) : null}
                                            onChange={handleToDateChange}
                                            format="YYYY-MM-DD"
                                            minDate={fromDate ? dayjs(fromDate) : null}
                                            renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='date-range-footer'>
                                <Button disabled={fromDate === null || toDate === null} onClick={handleDateChange}>Apply Filter</Button>
                            </div>
                        </Typography>
                    </Popover>
                </div>
            </div>
            <Grid container spacing={5}>
                {platformGrowthMap.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                        <div style={{ position: 'relative' }}>
                            {loading && (
                                <Skeleton variant="rectangular" width="100%" height="100%"
                                    sx={{
                                        position: 'absolute', top: 0, left: 0, zIndex: 1,
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                    }}
                                />
                            )}
                            <Card sx={{ minWidth: 200, position: 'relative', zIndex: 0, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <CardContent>
                                    <div className='icon-background'>
                                        {item.icon}
                                    </div>
                                    <Typography sx={{ mt: 1.5, mb: 1.5, fontSize: '1.3rem' }} color="text.secondary">
                                        {item.value}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title={item.label}>
                                            <Typography variant="body2" sx={{ fontSize: '1.4rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {item.label}
                                            </Typography>
                                        </Tooltip>
                                        <Tooltip title={item.tooltip}>
                                            <InfoOutlinedIcon sx={{ m: 1 }} />
                                        </Tooltip>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default PlatformGrowthPage
