import React, { useContext } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, IconButton } from '@mui/material';
import { UserDetailsContext } from '../../context/user-details-context';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NorthIcon from '@mui/icons-material/North';
import { useLeadFilters } from '../../context/eclead-filter-context';

function ECLeadsTableHead({ selectedECLead, setSelectedECLead, handleSelectAllClick, setCurrentPage, setGoToPage, pageSize, totalECLeads, currentPage }) {
    const { loggedUser } = useContext(UserDetailsContext);
    const { leadFilters, setLeadFilters } = useLeadFilters();

    const headCells = [
        { id: 1, numeric: false, disablePadding: true, label: 'Title' },
        { id: 2, numeric: true, disablePadding: true, label: 'Type' },
        { id: 3, numeric: true, disablePadding: true, label: 'Location' },
        { id: 4, numeric: false, disablePadding: true, label: 'Status', sortable: true },
        { id: 5, numeric: false, disablePadding: true, label: 'From', sortable: true },
        { id: 6, numeric: false, disablePadding: true, label: 'Proposal', sortable: true },
        { id: 7, numeric: false, disablePadding: true, label: 'Probability', sortable: true },
        { id: 9, numeric: false, disablePadding: true, label: 'Actions' }
    ];

    if (loggedUser && loggedUser.role !== 'LEAD_AGENT') {
        headCells.splice(7, 0, { id: 8, numeric: false, disablePadding: true, label: 'Agent', sortable: true });
    }

    const handleLeadSortChange = (fieldName, value) => {
        setLeadFilters((leadFilters) => {
            return {
                ...leadFilters,
                [fieldName]: value
            };
        });
    };

    function recordsOnPage(currentPage, pageSize, totalRecords) {
        const totalPages = Math.ceil(totalRecords / pageSize);
        
        if (currentPage < totalPages) {
            return pageSize;
        } else if (currentPage === totalPages) {
            return totalRecords % pageSize || pageSize;
        }
    }

    return (
        <TableHead>
            <TableRow>
                {loggedUser && loggedUser.access.includes('Lead:Assign') &&
                    <TableCell padding="checkbox">
                        <Checkbox color="secondary" inputProps={{ 'aria-label': 'select all desserts' }}
                            onChange={handleSelectAllClick} checked={ recordsOnPage(currentPage, pageSize, totalECLeads) === selectedECLead.length } />
                    </TableCell>
                }
                {headCells.map((headCell) => (
                    <>
                        <TableCell sx={{ backgroundColor: 'transparent', fontFamily: 'DM Sans', color: '#7D8FA9', opacity: 1, fontSize: '16px', fontWeight: 600 }}
                            key={headCell.id} align='center' padding={headCell.disablePadding ? '5px' : 'normal'}>
                            <div className='leads-header-filter-icon'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                                onClick={() => {
                                    const newSortingType = leadFilters.sortBy === headCell.label && leadFilters.sortingType === 'ASC' ? 'DESC' : 'ASC';
                                    handleLeadSortChange('sortBy', headCell.label);
                                    handleLeadSortChange('sortingType', newSortingType);
                                    setSelectedECLead([]);
                                    setCurrentPage(1);
                                    setGoToPage(1);
                                }}
                                onMouseEnter={(e) => {
                                    const icon = e.currentTarget.querySelector('.arrow-icon');
                                    if (icon) {
                                        icon.style.display = 'block';
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    const icon = e.currentTarget.querySelector('.arrow-icon');
                                    if (icon && leadFilters.sortBy !== headCell.label) {
                                        icon.style.display = 'none';
                                    }
                                }}
                            >
                                <span>{headCell.label}</span>
                                {headCell.sortable && (
                                    <IconButton sx={{ padding: 0 }}>
                                        {leadFilters.sortBy === headCell.label
                                            ? leadFilters.sortingType === 'ASC' ? <ArrowUpwardIcon className="arrow-icon" /> : <ArrowDownwardIcon className="arrow-icon" />
                                            : leadFilters.sortBy === '' && leadFilters.sortBy === null ? null : <NorthIcon className="arrow-icon" fontSize='small' sx={{ display: 'none' }}/>
                                        }
                                    </IconButton>
                                )}
                            </div>
                        </TableCell>
                    </>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default ECLeadsTableHead;
