import { TableRow, styled } from "@mui/material";
import Switch from '@mui/material/Switch';

export const AutocompleteBoxStyle = {
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0px',
    fontSize: '15px',
    border: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '@media screen and (max-width: 1360px)': {
    '.MuiAutocomplete-root .MuiOutlinedInput-root': {
      // width: 364
    }
  }
};

export const FollowersBoxStyle = {
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0px'
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    width: '100%'
  }
}

export const CategoriesBoxStyle = {
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0px',
    fontSize: '15px',
    border: 'none',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },

  '& .MuiAutocomplete-root': {
    background: '#fff',
    padding: '5px 5px 3px',
    marginLeft: '2px'
  },
};

export const sortbyBoxStyle = {
  '& .MuiFormControl-root': {
    width: 250,
    backgroundColor: "#f5f5f5",
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root': {
    height: '45px',
    paddingRight: '32px',
    fontWeight: 100,
    color: 'gray'
  },
}

export const campaignNameText = {
  '& .MuiInputBase-input': {
    fontSize: '1.3em',
    fontWeight: '600'
  }
}

export const alertMessageStyle = {
  '@media screen and (max-width: 768px)': {
    '& .MuiSnackbarContent-root': {
      marginRight: '12em'
    }
  }
}

export const filterbyAccountBoxStyle = {
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: "0px 10px",
    width: 250,
    backgroundColor: "#f5f5f5",
  },
}

export const searchbyBoxStyle = {
  '& .MuiOutlinedInput-input': {
    padding: '5.5px 18px',

  },
  '& .MuiFormControl-root': {
    backgroundColor: '#f5f5f5',
    width: '551px',
  },
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#dde0e438',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const getStatusStyles = (status) => {
  switch (status) {
    case 'Registered':
      return {
        backgroundColor: '#FFFFFF',
        color: '#586a84',
        opacity: 0.8,
        border: '0.7868627309799194px solid #586A84',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#FFFFFF',
          color: '#586a84',
        }
      };
    case 'New Lead':
      return {
        backgroundColor: '#f45ea9',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#f45ea9',
          color: '#fff',
        }
      };
    case 'Notified':
      return {
        backgroundColor: '#ffa500',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#ffa500',
          color: '#fff',
        }
      };
    case 'In Discussion':
      return {
        backgroundColor: '#008000',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#008000',
          color: '#fff',
        }
      };
    case 'Rejected':
      return {
        backgroundColor: '#ff0000',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#ff0000',
          color: '#fff',
        }
      };
    default:
      return {};
  }
};

export const billTextfieldBoxStyle = {
  '& .MuiInputBase-input': {
    padding: '10.5px 14px',
    backgroundColor: '#f5f7f9',
    width: '18vw'
  },
  '@media screen and (max-width: 1360px)': {
    '& .MuiInputBase-input': {
      width: '22vw',
    },
  },
  '@media screen and (max-width: 1024px)': {
    '& .MuiInputBase-input': {
      width: '22vw',
    },
  },
  '@media screen and (max-width: 820px)': {
    '& .MuiInputBase-input': {
      width: '250px',
    },
  },
  '@media screen and (max-width: 599px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '52vw',
      fontSize: '15px'
    },
  },
  '@media screen and (max-width: 375px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '57vw',
      fontSize: '15px'
    },
  },
}

export const countryDropdownBoxStyle = {
  '& .MuiInputBase-input': {
    padding: '10.5px 14px',
    backgroundColor: '#f5f7f9',
    width: '254px'
  },
  '@media screen and (max-width: 1360px)': {
    '& .MuiInputBase-input': {
      width: '20vw',
    },
  },
  '@media screen and (max-width: 1024px)': {
    '& .MuiInputBase-input': {
      width: '20vw',
    },
  },
  '@media screen and (max-width: 820px)': {
    '& .MuiInputBase-input': {
      width: '234px',
    },
  },
  '@media screen and (max-width: 599px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '47vw',
      fontSize: '15px'
    },
  },
  '@media screen and (max-width: 375px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '51vw',
      fontSize: '15px'
    },
  },
}

export const itemsTextfieldBoxStyle = {
  '& .MuiInputBase-input': {
    padding: '10.5px 14px',
    backgroundColor: '#f5f7f9',
    width: '18vw'
  },
  '@media screen and (max-width: 1360px)': {
    '& .MuiInputBase-input': {
      width: '22vw',
    },
  },
  '@media screen and (max-width: 1024px)': {
    '& .MuiInputBase-input': {
      width: '22vw',
    },
  },
  '@media screen and (max-width: 820px)': {
    '& .MuiInputBase-input': {
      width: '250px',
    },
  },
  '@media screen and (max-width: 599px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '52vw',
      fontSize: '15px'
    },
  },
  '@media screen and (max-width: 375px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '52vw',
      fontSize: '15px'
    },
  },
}

export const notesTextfieldBoxStyle = {
  '& .MuiInputBase-input': {
    padding: '10.5px 14px',
    backgroundColor: '#f5f7f9',
    width: '680px'
  },
  '@media screen and (max-width: 1360px)': {
    '& .MuiInputBase-input': {
      width: '48vw',
    },
  },
  '@media screen and (max-width: 1024px)': {
    '& .MuiInputBase-input': {
      width: '48vw',
    },
  },
  '@media screen and (max-width: 768px)': {
    '& .MuiInputBase-input': {
      width: '80vw',
    },
  },
  '@media screen and (max-width: 599px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '112vw',
      fontSize: '15px'
    },

  },
  '@media screen and (max-width: 375px)': {
    '& .MuiInputBase-input': {
      padding: '10.5px 10px',
      width: '122vw',
      fontSize: '15px'
    },

  },
}

export const datepickerBoxStyle = {
  '& .MuiInputBase-input': {
    padding: '8.5px 14px',
  },
  '& .MuiFormControl-root': {
    backgroundColor: '#f5f5f5'
  }
};

export const customInputBoxStyle = {
  '& .MuiInputBase-input': {

  },
  '& .MuiFormControl-root': {
    backgroundColor: '#f5f5f5'
  }
};

export const TableRowStyledBox = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    color: '#7d8fa9',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: "DM Sans",
    lineHeight: '1.5rem',
    letterSpacing: '0.01071em',
    padding: '20px 12px'
  }
}));

export const TableCellStyledBox = {
  color: '#586a84',
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: "DM Sans",
  lineHeight: '1.5rem',
  letterSpacing: '0.01071em'
};

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9c27b0',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const getECInternalStatusStyles = (status) => {
  switch (status) {
    case 'Prospect':
      return {
        backgroundColor: '#f45ea9',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#f45ea9',
          color: '#fff',
        }
      };
    case 'Pitching':
      return {
        backgroundColor: '#7b5ede',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#7b5ede',
          color: '#fff',
        }
      };
    case 'In Discussion':
      return {
        backgroundColor: '#ffa500',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#ffa500',
          color: '#fff',
        }
      };
    case 'In Review':
      return {
        backgroundColor: '#008000',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#008000',
          color: '#fff',
        }
      };
    case 'Not Responding':
      return {
        backgroundColor: '#e12828',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#e12828',
          color: '#fff',
        }
      };
    case 'Re-open':
      return {
        backgroundColor: '#1976d2',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#1976d2',
          color: '#fff',
        }
      };
    case 'Lost':
      return {
        backgroundColor: '#08a0ae',
        color: '#fff',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#08a0ae',
          color: '#fff',
        }
      };
    case 'Won':
      return {
        backgroundColor: '#FFFFFF',
        color: '#586a84',
        opacity: 0.8,
        border: '0.7868627309799194px solid #586A84',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#FFFFFF',
          color: '#586a84',
        }
      };
    default:
      return {};
  }
};
export const ECLocationBoxStyle = {
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '0px 0px',
    backgroundColor: "#F5F8FA",
    border: "#CBD6E2"
  }
}
export const TableRowPopupStyledBox = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    color: '#000',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: "DM Sans",
    lineHeight: '1.5rem',
    letterSpacing: '0.01071em',
    padding: '20px 12px'
  }
}));