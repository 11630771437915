import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, IconButton, Popover, TextField, Typography, Skeleton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getCreatorsOverview } from './admin-dashboard-repository';
ChartJS.register(ArcElement, Tooltip, Legend);

function CreatorProfileInsights() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateFilterValue, setDateFilterValue] = useState({ from: "", to: "" });
  const [dateFilterApply, setDateFilterApply] = useState(false);
  const [creatorOverview, setCreatorOverview] = useState({
    creators: 0, activeCreators: 0, privateProfile: 0, incompleteProfile: 0,
  });
  const [loading, setLoading] = useState(false);
  const openFilter = Boolean(anchorEl);
  const id = openFilter ? 'simple-popover' : undefined;

  const handleClearFilter = () => {
    setDateFilterValue({ from: "", to: "" });
    setFromDate(null);
    setToDate(null);
    setDateFilterApply(false);
  }

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleFilterClose = () => {
    setAnchorEl(null);
  }

  const handleFromDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setFromDate(formattedDate);
    if (toDate && newDate && dayjs(toDate).isBefore(newDate)) {
      setToDate(null);
    }
  };

  const handleToDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setToDate(formattedDate);
  };

  const handleDateChange = () => {
    setDateFilterValue({ from: fromDate, to: toDate });
    setAnchorEl(null);
    setDateFilterApply(true);
  }

  useEffect(() => {
    getCreatorsOverview(setCreatorOverview, setLoading, dateFilterValue);
  }, [dateFilterValue]);

  const ongoingTaskData = {
    labels: ['Active Creators: ' + creatorOverview.activeCreators, 'Private Profiles: ' + creatorOverview.privateProfile,
    'Incomplete Profiles: ' + creatorOverview.incompleteProfile],
    datasets: [
      {
        label: '',
        data: [creatorOverview.activeCreators, creatorOverview.privateProfile, creatorOverview.incompleteProfile],
        backgroundColor: [
          '#3c50e0', '#8fd0ef', '#0fadcf'
        ],
        borderColor: [
          '#3c50e0', '#8fd0ef', '#0fadcf'
        ]
      }
    ]
  };

  const ongoingTaskOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          padding: 10,
          generateLabels: function (chart) {
            const data = chart.data;
            return [{
              text: 'Total Creators: ' + creatorOverview.creators,
              fillStyle: 'transparent',
              hidden: false,
              index: -1
            }].concat(
              data.labels.map((label, i) => ({
                text: label,
                fillStyle: data.datasets[0].backgroundColor[i],
                hidden: false,
                index: i
              }))
            );
          }
        },
        onClick: null
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <Skeleton variant="rectangular" width="100%" height="100%"
          sx={{
            position: 'absolute', top: 0, left: 0, zIndex: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
          }}
        />
      )}
      <Card sx={{ minWidth: 500, position: 'relative', zIndex: 0, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          <div className='platform-growth-text-row'>
            <div className='platform-growth-text'>Creators Overview</div>
            <div>
              {dateFilterApply
                ? <IconButton aria-describedby={id} onClick={handleClearFilter}>
                  <EventBusyIcon fontSize='large' />
                </IconButton>
                : <IconButton aria-describedby={id} onClick={(e) => handleFilterClick(e)}>
                  <CalendarMonthIcon fontSize='large' />
                </IconButton>
              }
              <Popover id={id} open={openFilter} anchorEl={anchorEl}
                onClose={handleFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <Typography sx={{ p: 3 }}>
                  <div className='date-range-div'>
                    <div className='date-range-col'>
                      <div className='date-range-text'>From Date:</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={fromDate ? dayjs(fromDate) : null}
                          onChange={handleFromDateChange}
                          format="YYYY-MM-DD"
                          renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className='date-range-col'>
                      <div className='date-range-text'>To Date:</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={toDate ? dayjs(toDate) : null}
                          onChange={handleToDateChange}
                          format="YYYY-MM-DD"
                          minDate={fromDate ? dayjs(fromDate) : null}
                          renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='date-range-footer'>
                    <Button disabled={fromDate === null || toDate === null} onClick={handleDateChange}>Apply Filter</Button>
                  </div>
                </Typography>
              </Popover>
            </div>
          </div>
        </CardContent>
        <CardContent sx={{ justifyContent: 'center', display: 'flex' }}>
          <div style={{ position: 'relative', width: '70%', maxWidth: '400px' }}>
            <Doughnut data={ongoingTaskData} options={ongoingTaskOptions} />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default CreatorProfileInsights;
