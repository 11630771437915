import { getConfig } from '../common/env-config';

export const refreshToken = async () => {
    const { baseUrl } = getConfig();
    const response = await fetch(`${baseUrl}/refresh-token`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            phoneNumber: localStorage.getItem("phoneNumber"),
            refreshToken: localStorage.getItem("refreshToken")
        })
    });
    const responseBody = await response.json();

    if (!response.ok) {
        console.error("Failed to get refresh token");
    } else {
        localStorage.setItem("accessToken", responseBody.accessToken);
        localStorage.setItem("refreshToken", responseBody.refreshToken);
        localStorage.setItem("refreshTokenExpired", responseBody.refreshTokenExpired);
        window.location.reload();
    }
}