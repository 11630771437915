import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Autocomplete, Button, IconButton, Popover, TextField, Typography } from '@mui/material';
import { getLeadsStatus } from '../leads-helper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const headCells = [
    { id: 'fullName', numeric: false, disablePadding: true, label: 'Full Name' },
    { id: 'phoneNumber', numeric: true, disablePadding: true, label: 'Phone Number' },
    { id: 'email', numeric: true, disablePadding: true, label: 'Email' },
    { id: 'createdTime', numeric: true, disablePadding: true, label: 'Create Time' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'tags', numeric: false, disablePadding: true, label: 'Tags' }
];

function LeadsTableHead(props) {
    const [openDateFilter, setOpenDateFilter] = useState(false);
    const [openStatusFilter, setOpenStatusFilter] = useState(false);
    const [openTagsFilter, setOpenTagsFilter] = useState(false);
    const [filterColumn, setFilterColumn] = useState('');
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const { handleSelectAllClick, selected, setSelected, leadsStatus, setLeadsStatus, leadsTags,
        setDateFilterValue, statusFilterValue, setStatusFilterValue, tagsFilterValue,
        setTagsFilterValue, setCurrentPage, setGoToPage, setIsStatusEditing
    } = props;

    useEffect(() => {
        getLeadsStatus(setLeadsStatus);
    }, [setLeadsStatus]);

    const handleFromDateChange = (newDate) => {
        const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
        setFromDate(formattedDate);
        if (toDate && newDate && dayjs(toDate).isBefore(newDate)) {
            setToDate(null);
        }
    };

    const handleToDateChange = (newDate) => {
        const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
        setToDate(formattedDate);
    };

    const handleDateChange = () => {
        setDateFilterValue({ from: fromDate, to: toDate });
        setOpenDateFilter(!openDateFilter);
        setCurrentPage(1);
        setGoToPage(1);
        setSelected([]);
        setAnchorEl(null);
    }

    const handleStatusChange = (event, newValue) => {
        setStatusFilterValue(newValue);
        setOpenStatusFilter(!openStatusFilter);
        setCurrentPage(1);
        setGoToPage(1);
        setSelected([]);
        setAnchorEl(null);
    }

    const handleTagsChange = (event, newValue) => {
        setTagsFilterValue(newValue);
        setOpenTagsFilter(!openTagsFilter);
        setCurrentPage(1);
        setGoToPage(1);
        setSelected([]);
        setAnchorEl(null);
    }

    const handleLeadsFilters = (event, filter) => {
        setFilterColumn(filter);
        setAnchorEl(event.currentTarget);
        setIsStatusEditing(false);
    }

    const handleLeadsFilterClose = () => {
        setAnchorEl(null);
    }

    const openFilter = Boolean(anchorEl);
    const id = openFilter ? 'simple-popover' : undefined;

    const handleClearLeadsFilters = (event, filter) => {
        setCurrentPage(1);
        if (filter === 'Create Time') {
            setDateFilterValue({ from: "", to: "" });
            setFromDate(null);
            setToDate(null);
            setOpenDateFilter(!openDateFilter);
        } else if (filter === 'Status') {
            setStatusFilterValue(null);
            setOpenStatusFilter(!openStatusFilter);
        } else if (filter === 'Tags') {
            setTagsFilterValue(null);
            setOpenTagsFilter(!openTagsFilter);
        }
    }

    const handleAutocompleteOpen = () => {
        setOptionsOpen(true);
    };

    const handleAutocompleteClose = () => {
        setOptionsOpen(false);
    };

    const renderFilterContent = () => {
        switch (filterColumn) {
            case 'Create Time':
                return (
                    <>
                        <div className='date-range-div'>
                            <div className='date-range-col'>
                                <div className='date-range-text'>From Date:</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={fromDate ? dayjs(fromDate) : null}
                                        onChange={handleFromDateChange}
                                        format="YYYY-MM-DD"
                                        renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='date-range-col'>
                                <div className='date-range-text'>To Date:</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={toDate ? dayjs(toDate) : null}
                                        onChange={handleToDateChange}
                                        format="YYYY-MM-DD"
                                        minDate={fromDate ? dayjs(fromDate) : null}
                                        renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='date-range-footer'>
                            <Button disabled={fromDate === null || toDate === null} onClick={handleDateChange}>Apply Filter</Button>
                        </div>
                    </>
                );
            case 'Status':
                return (
                    <Autocomplete
                        disablePortal
                        id="status-combo-box"
                        options={leadsStatus}
                        sx={{ width: 300 }}
                        onChange={handleStatusChange}
                        onOpen={handleAutocompleteOpen}
                        onClose={handleAutocompleteClose}
                        value={statusFilterValue}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Status" />
                        )}
                    />
                );
            case 'Tags':
                return (
                    <Autocomplete
                        disablePortal
                        id="tags-combo-box"
                        options={leadsTags}
                        getOptionLabel={(option) => option}
                        sx={{ width: 300 }}
                        onChange={handleTagsChange}
                        onOpen={handleAutocompleteOpen}
                        onClose={handleAutocompleteClose}
                        value={tagsFilterValue}
                        renderOption={(props, option) => (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Tags" />
                        )}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="secondary" inputProps={{ 'aria-label': 'select all desserts' }}
                        onChange={handleSelectAllClick} checked={selected.length > 0}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <>
                        <TableCell sx={{ backgroundColor: 'transparent', fontFamily: 'DM Sans', color: '#7D8FA9', opacity: 1, fontSize: '16px', fontWeight: 600 }}
                            key={headCell.id} align='center' padding={headCell.disablePadding ? '5px' : 'normal'}>
                            <div className='leads-header-filter-icon'>
                                {headCell.label}
                                {(headCell.label === 'Create Time' || headCell.label === 'Status' || headCell.label === 'Tags') &&
                                    ((headCell.label === 'Create Time' && openDateFilter && 'Create Time') ||
                                        (headCell.label === 'Status' && openStatusFilter && 'Status') ||
                                        (headCell.label === 'Tags' && openTagsFilter && 'Tags')
                                        ? <IconButton aria-describedby={id} onClick={(e) => handleClearLeadsFilters(e, headCell.label)}>
                                            <FilterAltOffIcon />
                                        </IconButton>
                                        : <IconButton aria-describedby={id} onClick={(e) => handleLeadsFilters(e, headCell.label)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    )
                                }
                            </div>
                        </TableCell>
                    </>
                ))}
            </TableRow>
            <Popover id={id} open={openFilter} anchorEl={anchorEl}
                onClose={handleLeadsFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Typography sx={{ p: optionsOpen ? '30px 30px 200px' : 3 }}>
                    {renderFilterContent()}
                </Typography>
            </Popover>
        </TableHead>
    )
}
LeadsTableHead.propTypes = {
    handleSelectAllClick: PropTypes.func.isRequired,
    selected: PropTypes.any,
    setSelected: PropTypes.any,
    leadsStatus: PropTypes.any,
    setLeadsStatus: PropTypes.any,
    leadsTags: PropTypes.any,
    setDateFilterValue: PropTypes.any,
    statusFilterValue: PropTypes.any,
    setStatusFilterValue: PropTypes.any,
    tagsFilterValue: PropTypes.any,
    setTagsFilterValue: PropTypes.any,
    setCurrentPage: PropTypes.any,
    setGoToPage: PropTypes.any,
    setIsStatusEditing: PropTypes.any
};
export default LeadsTableHead;
