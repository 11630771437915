import { getConfig } from "../env-config";

const { baseUrl } = getConfig();

export const getAccessToken = async (agentId) => {
    try {
        const response = await fetch(`${baseUrl}/get-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: agentId
            })
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('phoneNumber', '915432167890');
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("userId", data.userId);
        } else {
            console.error('Error fetching access token: ', data.message);
        }
    } catch (error) {
        console.error('Error fetching access token: ', error);
    }
};
