/* eslint-disable */
import React, { useState, useEffect } from 'react';
import archivetick from '../../../assets/archive-tick.svg';
import documenttxt from '../../../assets/document-text.svg';
import eclp1 from '../../../assets/EC_LP Creatives2-01.svg';
import eclp2 from '../../../assets/EC_LP Creatives2-02.svg';
import eclp3 from '../../../assets/EC_LP Creatives2-03.svg';
import eclp4 from '../../../assets/EC_LP Creatives2-04.svg';
import eye from '../../../assets/eye.svg';
import fullstop from '../../../assets/full-stop.png';
import group15 from '../../../assets/Group 15.svg';
import heart from '../../../assets/heart.svg';
import message from '../../../assets/message.svg';
import profile2 from '../../../assets/profile-2user.svg';
import send2 from '../../../assets/send-2.svg';
import timer from '../../../assets/timer.svg';
import asset1 from '../../../assets/Asset 1.svg';
import asset2 from '../../../assets/Asset 2.svg';
import asset3 from '../../../assets/Asset 3.svg';
import asset4 from '../../../assets/Asset 4.svg';
import asset5 from '../../../assets/Asset 5.svg';
import asset6 from '../../../assets/Asset 6.svg';
import asset9 from '../../../assets/Asset 9.svg';
import asset10 from '../../../assets/Asset 10.svg';
import asset11 from '../../../assets/Asset 11.svg';
import "../../../css/ec-landing/ec-responsive.css";
import "../../../css/ec-landing/ec-style.css";
import LandingPageFooter from '../landingPages/landing-page-footer';
import { Alert, Box, Snackbar } from '@mui/material';
import CommonLandingPageAppBar from '../common-landing-page-appbar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAccessToken } from './ec-landing-repo';
import { ECLocationBoxStyle } from '../../../mui/mui-styling';
import ECLandingLocation from './ec-landing-location';
import { getConfig } from '../env-config';
import { useNavigate } from 'react-router-dom';

function EcLandingPage() {
    const navigate = useNavigate();
    const { baseUrl } = getConfig();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [location, setLocation] = useState(null);
    const [externalLead, setExternalLead] = useState({
        fullName: '', contactMobile: '', contactEmail: '', companyName: '',
        leadLocation: null, placeId: null, newNote: '', termsCondition: false
    });
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [openNotifyAlert, setOpenNotifyAlert] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        const route = window.location.pathname;
        const parts = route.split('/');
        const agentId = parts[2];
        const adId = parts[3];
        localStorage.setItem("ecleadsource", adId);
        getAccessToken(agentId);
    }, []);

    const handleChange = (fieldName, value) => {
        const isSameValue = externalLead[fieldName] === value;
        const isNotEmptyOrNull = value !== '' && value !== null;

        setExternalLead((externalLead) => {
            if (fieldName === 'placeId') {
                return {
                    ...externalLead,
                    'placeId': value
                };
            }
            if (fieldName === 'leadLocation') {
                return {
                    ...externalLead,
                    [fieldName]: value
                };
            }
            return {
                ...externalLead,
                [fieldName]: value,
            };
        });

        if (isNotEmptyOrNull && !isSameValue) {
            setIsFormDirty(true);
        } else if (isSameValue) {
            setIsFormDirty(false);
        } else {
            setIsFormDirty(false);
        }
    };

    const handleSave = async () => {
        try {
            setIsFormDirty(false);
            const { fullName, contactMobile, contactEmail, companyName, leadLocation, placeId, newNote } = externalLead;

            const response = await fetch(`${baseUrl}/admin/save-external-ec-lead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify({
                    fullName, contactMobile, contactEmail, companyName, leadLocation, placeId, newNote,
                    source: localStorage.getItem("ecleadsource")
                })
            });
            const responseBody = await response.json();
            if (!response.ok) {
                if (responseBody.message && responseBody.message && responseBody.message.includes("Mobile")) {
                    handleNotifyAlert();
                    setLocation(null);
                    setExternalLead({
                        fullName: '', contactMobile: '', contactEmail: '', companyName: '',
                        leadLocation: null, placeId: null, newNote: '', termsCondition: false,
                    });
                } else if (responseBody.message && responseBody.message && responseBody.message.includes("Email")) {
                    handleNotifyAlert();
                    setLocation(null);
                    setExternalLead({
                        fullName: '', contactMobile: '', contactEmail: '', companyName: '',
                        leadLocation: null, placeId: null, newNote: '', termsCondition: false
                    });
                } else if (responseBody.message && responseBody.message && responseBody.message.includes("User")) {
                    handleNotifyAlert();
                    setLocation(null);
                    setExternalLead({
                        fullName: '', contactMobile: '', contactEmail: '', companyName: '',
                        leadLocation: null, placeId: null, newNote: '', termsCondition: false
                    });
                } else if (response.status === 499) {
                    refreshToken();
                } else {
                    handleErrorAlert();
                    console.error('Error saving external ec lead details:', responseBody.message);
                }
            } else {
                handleSuccessAlert();
                setLocation(null);
                setExternalLead({
                    fullName: '', contactMobile: '', contactEmail: '', companyName: '',
                    leadLocation: null, placeId: null, newNote: '', termsCondition: false
                });
            }
        } catch (error) {
            handleErrorAlert();
            console.error('Error saving external ec lead details:', error);
        }

    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (isFormDirty) {
            const handleBeforeUnload = (e) => {
                const currentPath = window.location.pathname;
                if (isFormDirty && currentPath.includes("/ec-landing-page")) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    e.returnValue = message;
                    return message;
                }
            };

            const handleNavClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            const handlePopState = (e) => {
                const currentPath = window.location.pathname;
                if (isFormDirty && currentPath.includes("/ec-landing-page")) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    const userConfirmed = window.confirm(message);
                    if (!userConfirmed) {
                        window.history.pushState(null, '', window.location.pathname);
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick);
            });

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
                navLinks.forEach((link) => {
                    link.removeEventListener('click', handleNavClick);
                });
            };
        }
    }, [isFormDirty]);

    const disable = externalLead.fullName === "" || externalLead.fullName.length < 2 ||
        externalLead.contactMobile === "" || externalLead.contactMobile.length < 10 ||
        externalLead.contactEmail === "" || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(externalLead.contactEmail) ||
        externalLead.companyName === "" || externalLead.companyName.length < 2 ||
        location == null || externalLead.termsCondition === false || externalLead.newNote === "";

    const handleSuccessAlert = () => {
        setOpenSuccessAlert(true);
    };

    const handleErrorAlert = () => {
        setOpenErrorAlert(true);
    };

    const handleNotifyAlert = () => {
        setOpenNotifyAlert(true);
    };

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessAlert(false);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorAlert(false);
    };

    const handleNotifyAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenNotifyAlert(false);
    };

    const ecReadCaseNav = async () => {
        window.open("/blog", "_blank");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CommonLandingPageAppBar
                    handleDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                    setMobileOpen={setMobileOpen} />

            </Box>
            <div className="LandingPage">
                {/*START :: Form Section */}
                <section className="FormSection">
                    <div className="container">
                        <div className="FormsDetails">
                            <div className="row row-discover">
                                <div className="col-md-12 col-lg-6">
                                    <div className="DiscoverCreator">
                                        <p className="MainHeading">
                                            Discover <span>Creators. Maximize ROI.</span> Go to Market.
                                        </p>
                                        <p className="content">
                                            Find your brand’s untapped potential by leveraging the influence of over 500K+ geographically targeted creators. Amplify your ROI and achieve unrivalled market dominance. Start your first campaign INR 3000* Only.
                                        </p>
                                        <div className="text-end no-mobi">
                                            <img src={group15} alt="group15 img" />

                                        </div>
                                        <div className="text-center no-desktop show-mobi mb-3 mt-3 MobiImg">
                                            <img src={group15} alt="group15 img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="FormInfo">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Full Name<span style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" name="fullName"
                                                        value={externalLead.fullName} onChange={(e) => handleChange("fullName", e.target.value.replace(/[^A-Za-z\s]/g, ''))}
                                                        maxLength={30} />
                                                    {externalLead.fullName !== "" && externalLead.fullName.length < 2 && (
                                                        <small style={{ color: 'red' }}>Length must be at least 2 characters</small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-15mobi">
                                                <div className="form-group">
                                                    <label>Mobile Number<span style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" name="contactMobile"
                                                        value={externalLead.contactMobile} onChange={(e) => handleChange("contactMobile", e.target.value.replace(/\D/g, ''))}
                                                        maxLength={10}
                                                    />
                                                    {externalLead.contactMobile !== "" && externalLead.contactMobile.length !== 10 && (
                                                        <small style={{ color: 'red' }}>Enter a valid 10-digit number</small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Email Address<span style={{ color: 'red' }}> *</span></label>
                                                    <input type="email" className="form-control" name="contactEmail"
                                                        value={externalLead.contactEmail}
                                                        onChange={(e) => handleChange("contactEmail", e.target.value)} />
                                                    {externalLead.contactEmail != null && externalLead.contactEmail !== '' && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(externalLead.contactEmail) &&
                                                        <small style={{ color: 'red' }}>Please enter valid email address</small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Brand Name<span style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" name="companyName"
                                                        value={externalLead.companyName} maxLength={50}
                                                        onChange={(e) => handleChange("companyName", e.target.value)} />
                                                    {externalLead.companyName !== "" && externalLead.companyName.length < 2 && (
                                                        <small style={{ color: 'red' }}>Length must be at least 2 characters</small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-15mobi">
                                                <div className="form-group">
                                                    <label>Location<span style={{ color: 'red' }}> *</span></label>
                                                    <Box sx={ECLocationBoxStyle}>
                                                        <ECLandingLocation handleChange={handleChange} locationDescription={externalLead.leadLocation} location={location} setLocation={setLocation} />
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Website / Insta Handle</label>
                                                    <input type="text" className="form-control" name="newNote"
                                                        value={externalLead.newNote}
                                                        onChange={(e) => handleChange("newNote", e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-check mt-3">
                                            <input type="checkbox" className="form-check-input" name='termsCondition' checked={externalLead.termsCondition}
                                                id="terms-condition" onChange={(e) => handleChange('termsCondition', !externalLead.termsCondition)}
                                            />
                                            <label className="form-check-label" htmlFor="terms-condition">
                                                By clicking submit, you are providing us with your consent
                                                to communicate via email or phone, regarding the demo you
                                                have requested.
                                            </label>
                                        </div>
                                        <div className="no-mobi">
                                            <button type="submit" className="ScheduleCall" onClick={handleSave}
                                                style={{
                                                    backgroundColor: disable ? 'transparent' : '#0F67EA',
                                                    color: disable ? '#6c757d' : 'white',
                                                    cursor: disable ? 'not-allowed' : 'pointer',
                                                    border: disable ? '1px solid #6c757d' : '#F5F5F5',
                                                }} disabled={disable}> Schedule a Call</button>
                                        </div>
                                        <div className="text-center no-desktop show-mobi">
                                            <button type="submit" className="ScheduleCall" onClick={handleSave}
                                                style={{
                                                    backgroundColor: disable ? 'transparent' : '#0F67EA',
                                                    color: disable ? '#6c757d' : 'white',
                                                    cursor: disable ? 'not-allowed' : 'pointer',
                                                    border: disable ? '1px solid #6c757d' : '#F5F5F5',
                                                }} disabled={disable}>Schedule a Call</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSuccessAlert} autoHideDuration={3000} onClose={handleSuccessAlertClose}
                >
                    <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                        Call scheduled successfully!
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openErrorAlert} autoHideDuration={3000} onClose={handleErrorAlertClose}
                >
                    <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                        Failed To scheduled call!
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openNotifyAlert} autoHideDuration={3000} onClose={handleNotifyAlertClose}
                >
                    <Alert severity="primary" variant="filled" sx={{ width: '100%' }}>
                        We have received your information and will get back to you soon!
                    </Alert>
                </Snackbar>
                {/*START :: Numbers that Given proven Results: */}
                <section className="ResultsSection">
                    <div className="container">
                        <div className="ResultsDetails">
                            <p className="MainHeading">Numbers that Given proven Results:&nbsp;</p>
                            <div className="LikeComments no-mobi">
                                <div className="row">
                                    <div className="col-md-2" />
                                    <div className="col-md-2">
                                        <div className="NoOfLikes">
                                            <img src={eye} alt="eye img" />
                                            <p className="views">Total Views</p>
                                            <p className="NoOfViews">10 M +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="NoOfLikes">
                                            <img src={heart} alt="heart img" />
                                            <p className="views">Total Likes</p>
                                            <p className="NoOfViews">2.8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="NoOfLikes">
                                            <img src={message} alt="message img" />
                                            <p className="views">Total Comments</p>
                                            <p className="NoOfViews">8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="NoOfLikes">
                                            <img src={send2} alt="send2 img" />
                                            <p className="views">Total Shares</p>
                                            <p className="NoOfViews">10 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2" />
                                </div>
                                <div className="row mt-20">
                                    <div className="col-md-3">
                                        <div className="NoOfLikes">
                                            <img src={archivetick} alt="archivetick img" />
                                            <p className="views">Total Saves</p>
                                            <p className="NoOfViews">8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="NoOfLikes">
                                            <img src={timer} alt="timer img" />
                                            <p className="views">Total Watch Times(Hrs)</p>
                                            <p className="NoOfViews">136 M Secs</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="NoOfLikes">
                                            <img src={profile2} alt="profile2 img" />
                                            <p className="views">Numbers of Creators</p>
                                            <p className="NoOfViews">500 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="NoOfLikes">
                                            <img src={documenttxt} alt="documenttxt img" />
                                            <p className="views">Numbers of Content</p>
                                            <p className="NoOfViews">500 +</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="LikeComments no-desktop show-mobi">
                                <div className="row">
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={eye} alt="eye img" />
                                            <p className="views">Total Views</p>
                                            <p className="NoOfViews">10 M +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={heart} alt="heart img" />
                                            <p className="views">Total Likes</p>
                                            <p className="NoOfViews">2.8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={message} alt="message img" />
                                            <p className="views">Total Comments</p>
                                            <p className="NoOfViews">8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={timer} alt="timer img" />
                                            <p className="views">Total Watch Times(Hrs)</p>
                                            <p className="NoOfViews">136 M Secs</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={archivetick} alt="archivetick img" />
                                            <p className="views">Total Saves</p>
                                            <p className="NoOfViews">8 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={send2} alt="send2 img" />
                                            <p className="views">Total Shares</p>
                                            <p className="NoOfViews">10 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={profile2} alt="profile2 img" />
                                            <p className="views">Numbers of Creators</p>
                                            <p className="NoOfViews">500 K +</p>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-15mobi">
                                        <div className="NoOfLikes mt15-mobi">
                                            <img src={documenttxt} alt="documenttxt img" />
                                            <p className="views">Numbers of Content</p>
                                            <p className="NoOfViews">500 +</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*START :: Empowering businesses around the world*/}
                <section className="BusinessLogoSection">
                    <div className="container">
                        <div className="SliderSection">
                            <p className="MainHeading">Empowering businesses around the world</p>

                            <div className='ec-business-logo'>
                                <div className='ec-logo-business-mobile-view' >
                                    <div className='ec-logo-images-logo'>
                                        <img src={asset1} alt="asset1 img" />
                                    </div>
                                    <div className='ec-logo-images'>
                                        <img src={asset2} alt="asset2 img" />
                                    </div>
                                    <div className='ec-logo-images'>
                                        <img src={asset3} alt="asset3 img" />
                                    </div>

                                </div>
                                <div className='ec-logo-business-mobile-view' >
                                    <div className='ec-logo-images setu-logo'>
                                        <img src={asset4} alt="asset4 img" />
                                    </div>
                                    <div className='ec-logo-images-logo'>
                                        <img src={asset5} alt="asset5 img" />
                                    </div>
                                    <div className='ec-logo-images'>
                                        <img src={asset6} alt="asset6 img" />
                                    </div>
                                </div>

                                <div className='ec-logo-business-mobile-view'>
                                    <div className='ec-logo-images'>
                                        <img src={asset9} alt="asset9 img" />
                                    </div>

                                    <div className='ec-logo-images ranka-logo'>
                                        <img src={asset10} alt="asset10 img" />
                                    </div>
                                    <div className='ec-logo-images'>
                                        <img src={asset11} alt="asset11 img" />
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="CaseStudiesBtn" onClick={ecReadCaseNav}>Read Case Studies</button>
                            </div>
                        </div>
                    </div>
                </section >
                {/*START :: Find Your Ideal Creator for Your Brand*/}
                <section section className="CreatorFinderSection" >
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgSec">
                                        <img src={eclp1} alt="eclp1 img" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Find Your Ideal Creator for Your Brand
                                        </p>
                                        <p className="content">
                                            Discover the Ideal Creators and Achieve Campaign Success.
                                            Utilize our affordable prices and complete campaign management
                                            to make smart choices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/*START :: Find & Identify Your Ideal Creator Match*/}
                <section section className="CreatorFinderSection no-mobi" >
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Find &amp; Identify Your Ideal Creator Match
                                        </p>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Explore a Range of Options: Demographics, Partnerships,
                                                    Engagement, Content Affinity, Expert Support
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Precision-Target Your Geographic Reach
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Align with Creators Perfectly Suited to Your Brand
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Drive Campaign Success with Data-Backed Decisions
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ImgSec">
                                        <img src={eclp2} alt="eclp2 img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className="CreatorFinderSection no-desktop show-mobi">
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgSec">
                                        <img src={eclp2} alt="eclp2 img" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Find &amp; Identify Your Ideal Creator Match
                                        </p>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Explore a Range of Options: Demographics, Partnerships,
                                                    Engagement, Content Affinity, Expert Support
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Precision-Target Your Geographic Reach
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Align with Creators Perfectly Suited to Your Brand
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Drive Campaign Success with Data-Backed Decisions
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*START :: Creator Recommendations from Past Data*/}
                <section className="CreatorFinderSection">
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgSec">
                                        <img src={eclp3} alt="eclp3 img" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Creator Recommendations from Past Data
                                        </p>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0 col-11 pl-10mobi">
                                                <p className="content">
                                                    Boost your creator selection with data-based
                                                    recommendations.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0 col-11 pl-10mobi">
                                                <p className="content">
                                                    Proactive suggestions based on successful past
                                                    collaborations.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0 col-11 pl-10mobi">
                                                <p className="content">
                                                    Identify creators with attributes similar to proven
                                                    performers.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0 col-11 pl-10mobi">
                                                <p className="content">
                                                    Optimize your influencer strategy with data-driven
                                                    decision-making.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*START :: Reporting & Recording of Every campaign */}
                <section className="CreatorFinderSection no-mobi">
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Reporting &amp; Recording of Every campaign&nbsp;
                                        </p>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Build every campaign based on sentiment analysis and content
                                                    analysis.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Utilize data-driven insights for each content creator.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 pl-0">
                                                <p className="content">
                                                    Optimize campaigns with detailed sentiment and content
                                                    analysis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ImgSec">
                                        <img src={eclp4} alt="eclp4 img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="CreatorFinderSection no-desktop show-mobi mb-5">
                    <div className="container">
                        <div className="FindDetails">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgSec">
                                        <img src={eclp4} alt="eclp4 img" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="ImgInfo">
                                        <p className="MainHeading">
                                            Reporting &amp; Recording of Every campaign&nbsp;
                                        </p>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Build every campaign based on sentiment analysis and content
                                                    analysis.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Utilize data-driven insights for each content creator.&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1 pr-0mobi">
                                                <img src={fullstop} alt="fullstop img" style={{ width: 25, paddingTop: 2 }} />
                                            </div>
                                            <div className="col-md-11 col-11 pl-0mobi">
                                                <p className="content">
                                                    Optimize campaigns with detailed sentiment and content
                                                    analysis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*START :: Footer Section*/}
                <LandingPageFooter />
            </div ></>


    )
}
export default EcLandingPage;
