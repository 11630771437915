import { getConfig } from '../common/env-config';
import { refreshToken } from "./refresh-token-repo";

const { baseUrl } = getConfig();

export const getUserInsights = async (userId, setUserInsights, setLoading, enterpriseClient) => {
    const url = enterpriseClient ? `/ec-profile-details/${userId}` : `/instadetails/${userId}`;
    const response = await fetch(`${baseUrl}${url}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setUserInsights(data);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        console.error("Error occure while fetching user insights");
    }
    if (setLoading != null) {
        setLoading(false);
    }
}

export const getCreatorInsights = async (instaHandle, setUserInsights, setLoading, ) => {
    const response = await fetch(`${baseUrl}/creator/${instaHandle}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setUserInsights(data);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        console.error("Error occure while fetching creator insights");
    }
    if (setLoading != null) {
        setLoading(false);
    }
}

export const reloadInstaInsights = async (username) => {
    const response = await fetch(`${baseUrl}/reload-insta-insights/${username}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });

    if (response.status === 499) {
        refreshToken();
    }
};

export const getUserAssignRole = async (setUserRoles) => {
    try {
        const response = await fetch(`${baseUrl}/user/get-assign-roles`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            setUserRoles(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error("Error occure while fetching user roles");
        }
    } catch (error) {
        console.error("Error occure while fetching user roles");
    }
}
