import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableHead, TextField, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../../css/leads.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CommonPagination from '../../common/common-pagination';
import { StyledTableRow } from '../../../mui/mui-styling';
import TagTableToolbar from './tag-table-toolbar';
import { deleteTag, editTag, getTags } from '../leads-helper';
import { WindowWidthContext } from '../../context/user-details-context';

const headCells = [
  { id: 'tagName', numeric: false, disablePadding: true, label: 'Manage Tag' }
];

function TagsManagement() {
  const windowWidth = useContext(WindowWidthContext);
  const rowsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTags, setTotalTags] = useState();
  const [apiFailed, setApiFailed] = useState(false);
  const [isTagDelete, setIsTagDelete] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagUpdated, setTagUpdated] = useState(null);
  const [goToPage, setGoToPage] = useState(1);
  const [tagDeleted, setTagDeleted] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isEditTag, setIsEditTag] = useState(false);
  const [updatedTag, setUpdatedTag] = useState(null);

  useEffect(() => {
    getTags(setTags, setApiFailed, setLoading, setTotalTags, currentPage, rowsPerPage);
  }, [tagUpdated, tagDeleted, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setGoToPage(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(goToPage === '' ? 1 : goToPage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value.length === 1) {
      setGoToPage(1);
      setCurrentPage(1);
    }
  };

  const handleBlur = () => {
    if (goToPage === '') {
      setGoToPage(1);
      setCurrentPage(1);
    }
  };

  const handleDeleteTag = (selectedTag) => {
    setSelectedTag(selectedTag);
    setIsTagDelete(!isTagDelete);
  }

  const handleEditTag = (selectedTag) => {
    setSelectedTag(selectedTag);
    setIsEditTag(!isEditTag);
  }

  const handleTagChange = (e) => {
    setUpdatedTag(e.target.value);
  }

  const confirmEdit = () => {
    editTag(updatedTag, selectedTag, setTagUpdated, setIsEditTag, setUpdatedTag);
  }

  return (
    <Box sx={{ width: 'fit-content' }}>
      <Paper sx={{ width: 'fit-content', mb: 2, backgroundColor: '#f4f5f8', padding: '0px 20px 20px 20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <TagTableToolbar windowWidth={windowWidth} setCurrentPage={setCurrentPage} setGoToPage={setGoToPage} />
        <TableContainer sx={{ padding: '0px 15px 15px 15px' }}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <>
                    <TableCell key={headCell.id} align='center' sx={{ backgroundColor: 'transparent', fontFamily: 'DM Sans', color: '#7D8FA9', opacity: 1, fontSize: '16px', fontWeight: 600 }}
                      padding={headCell.disablePadding ? '5px' : 'normal'}>
                      {headCell.label}
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ?
                  <TableRow>
                    <TableCell align="center" colSpan={1} style={{ borderBottom: 'none', padding: 0 }}>
                      <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {loading ? <CircularProgress /> : null}
                      </div>
                    </TableCell>
                  </TableRow>
                  :
                  apiFailed
                    ? (
                      <TableRow>
                        <TableCell align="center" colSpan={1} style={{ borderBottom: 'none', padding: 0 }}>
                          <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {apiFailed && !loading ? <div className="nodata-found">No Tags Found</div> : null}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                    : (tags.map((tag) => {
                      return (
                        <StyledTableRow className='top-campaign-row'
                          tabIndex={-1} key={tag} sx={{ cursor: 'pointer' }}
                        >
                          <TableCell sx={{ padding: '20px 10px' }} align="start" >
                            {isEditTag && selectedTag === tag
                              ? <div className='tags-row'>
                                <TextField className='tag-name' id="standard-basic" label={tag} variant="standard" value={updatedTag} onChange={handleTagChange} />
                                <div>
                                  <IconButton
                                    color='secondary'
                                    onClick={() => confirmEdit()}
                                  >
                                    <CheckIcon />
                                  </IconButton>

                                  <IconButton
                                    color='error'
                                    onClick={() => handleDeleteTag(tag)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                              : <div className='tags-row'>
                                <Tooltip title={tag} arrow>
                                  <div className='tag-name'>{tag}</div>
                                </Tooltip>
                                <div>
                                  <IconButton
                                    color='secondary'
                                    onClick={() => handleEditTag(tag)}
                                  >
                                    <EditIcon />
                                  </IconButton>

                                  <IconButton
                                    color='error'
                                    onClick={() => handleDeleteTag(tag)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            }
                          </TableCell>
                        </StyledTableRow>
                      );
                    }))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div className={windowWidth <= 799 ? 'leads-table-mobile' : 'leads-table-footer'}>
          <div className='leads-table-footer'>
            <div>
              Showing {apiFailed || loading ? 0 : ((currentPage - 1) * rowsPerPage) + 1} - {apiFailed || loading ? 0 : Math.min(currentPage * rowsPerPage, totalTags)} of {apiFailed || loading ? 0 : totalTags}
            </div>
            <div className={windowWidth <= 799 ? 'go-to-page-mobile' : 'go-to-page'}>
              Go to page :
              <TextField type="number" id="outlined-size-normal" defaultValue="1" style={{ width: 'fit-content', backgroundColor: '#fff' }}
                variant="outlined" value={goToPage} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}
                onBlur={handleBlur} onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (isNaN(value)) {
                    setGoToPage('');
                  } else if (value < 1 || value > Math.ceil(totalTags / rowsPerPage)) {
                    setGoToPage('');
                  } else {
                    setGoToPage(value);
                  }
                }}
                inputProps={{ min: 1, max: apiFailed || loading ? 1 : Math.ceil(totalTags / rowsPerPage) }} />
            </div>
          </div>
          <div>
            <CommonPagination currentPage={currentPage} totalPages={Math.ceil(totalTags / rowsPerPage)}
              handlePageChange={handlePageChange} />
          </div>
        </div>
        <Dialog open={isTagDelete} onClose={() => setIsTagDelete(!isTagDelete)}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent sx={{ paddingTop: '12px !important' }}>
            <Typography>Are you sure want to delete tag?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsTagDelete(false)}>Cancel</Button>
            <Button onClick={() => { deleteTag(selectedTag, setTagDeleted, setIsTagDelete); }} autoFocus>
              Delete Tag
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
}

export default TagsManagement;
