import React, { useState, useContext } from 'react';
import "../../css/login.css";
import logo from '../../assets/creator-logo.svg'
import signinImg from '../../assets/signin.png';
import LoginPageForm from './login-page-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WindowWidthContext } from "../context/user-details-context";


const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userDetailData, setUserDetailData] = useState(null);
    const windowWidth = useContext(WindowWidthContext);
    const handleUserDetailData = (data) => {
        setUserDetailData(data);
    };
    const isUserDetailDataEmpty = (data) => {
        return !data || Object.values(data).every(
            value =>
                value === '' ||
                value === null ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === 'object' && isUserDetailDataEmpty(value))
        );
    };

    const handleBackClick = () => {
        navigate(-1)
    }
    
    return (
        <div>
            {location.pathname === '/login' && (
                <meta name="viewport" content="width=device-width, initial-scale=0.6" />
            )}
            <div className="login-container">
                <div className="left-login-container mobile">
                    <div className="back-arrow-login-container">
                        <ArrowBackIcon onClick={handleBackClick}></ArrowBackIcon>
                    </div>
                    <div className='left-text-content'>
                        <div className="signintitle">Ditch DM pitches<br></br>Step smartly into Oye Creators</div>
                        <div className="signintitle-content">Get thousands of voices for live marketing magic<br></br>Seize the chance to join India's premier no-commission creator portal</div>
                        <div className="signin-campain-container">
                            <img src={signinImg} alt="" className="signin-img"></img>
                        </div>
                    </div>
                </div>
                <div className="right-login">
                    {windowWidth <= 799 &&
                        <ArrowBackIcon onClick={handleBackClick}></ArrowBackIcon>
                    }
                    <div className="login-right-containers">
                        <div className="right-content">
                            <div className="oyecreators-logo">
                                <img src={logo} alt="logo" className="creator-login-logo" />
                            </div>
                            <h3 className="heading">{userDetailData && !isUserDetailDataEmpty(userDetailData) ? 'Edit Profile' : 'Welcome'}</h3>
                            <div className="input-field">
                                <LoginPageForm onUserDetailDataChange={handleUserDetailData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
