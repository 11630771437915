import { Box } from '@mui/material';
import React from 'react'
import CommonAppbarDrawer from '../common/common-sidebar-navbar';
import { DrawerHeader } from '../../mui/appbar-style-component';
import { Outlet } from 'react-router-dom';

function BillingDashboard() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: '58px', backgroundColor: '#edeff4', minHeight: '100vh' }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    )
}

export default BillingDashboard;