import React from 'react'
import CommonAppbarDrawer from '../common/common-sidebar-navbar';
import { Box } from '@mui/material';
import '../../css/discovery.css'
import { DrawerHeader } from '../../mui/appbar-style-component';

function EarningPage() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CommonAppbarDrawer />
      <Box component="main" sx={{ flexGrow: 1, padding: '58px', backgroundColor: '#edeff4', minHeight: '100vh' }}>
        <DrawerHeader />
        Earning feature coming soon...
      </Box>
    </Box>
  )
}

export default EarningPage;