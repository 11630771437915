import React from "react";
// import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
// import { getAllInvoices } from "../invoice/invoice-repository";
// import InvoicelistTable from "./invoice-table";
// import { useLocation } from 'react-router-dom';

function InvoiceList() {
  // const [invoices, setInvoices] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [totalPages, setTotalPages] = useState(1);
  // const location = useLocation();

  // const getInitialFilter = () => {
  //   const route = location.pathname.split("/").pop();
  //   if (route === "completed") {
  //     return { completed: true, pending: false, draft: false, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else if (route === "pending") {
  //     return { completed: false, pending: true, draft: false, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else if (route === "draft") {
  //     return { completed: false, pending: false, draft: true, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else {
  //     return { completed: false, pending: false, draft: false, pageNo: 1, pageSize: 20, searchString: '' };
  //   }
  // };

  // const [invoiceFilter, setInvoiceFilter] = useState(getInitialFilter);

  // const fetchInvoices = async (filter) => {
  //   try {
  //     setIsLoading(true);
  //     const result = await getAllInvoices(filter, setInvoices);
  //     setInvoices(result.invoices);
  //     setTotalPages(Math.ceil(result.filteredCount / filter.pageSize));
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching invoices:", error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchInvoices(invoiceFilter);
  // }, [invoiceFilter]);

  // useEffect(() => {
  //   const route = location.pathname.split("/").pop();
  //   let newFilter;
  //   if (route === "completed") {
  //     newFilter = { completed: true, pending: false, draft: false, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else if (route === "pending") {
  //     newFilter = { completed: false, pending: true, draft: false, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else if (route === "draft") {
  //     newFilter = { completed: false, pending: false, draft: true, pageNo: 1, pageSize: 20, searchString: '' };
  //   } else {
  //     return;
  //   }
  //   setInvoiceFilter(newFilter);
  // }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* <div className="invoicelist-background">
        <InvoicelistTable
          invoices={invoices}
          isLoading={isLoading}
          totalPages={totalPages}
          invoiceFilter={invoiceFilter}
          setInvoices={setInvoices}
          setTotalPages={setTotalPages}
          setInvoiceFilter={setInvoiceFilter}
        />
      </div> */}
      <div>Billing feature coming soon...</div>
    </Box>
  );
}

export default InvoiceList;
