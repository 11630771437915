import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { updateLeadStatus, updateLeadTag } from '../leads-helper';
import { Autocomplete, Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UpdateStatusDialog({ selected, selectedEdit, leadsTags, leadsStatus, setUpdateSuccess, openBulkUpdateDialog, handleBulkUpdateClose,
    setStatusSuccessAlert, setStatusFailedAlert, setSelected, setIsTagEditing, setTagUpdated, setTagSuccessAlert, setTagFailedAlert
}) {
    const [newStatus, setNewStatus] = useState(null);
    const [addTags, setAddTags] = useState([]);

    const handleAddTagsChange = (_, newValue) => {
        setAddTags(newValue);
    }
    return (
        <Dialog fullWidth maxWidth="xs" open={openBulkUpdateDialog} onClose={handleBulkUpdateClose}>
            <DialogTitle>{selectedEdit}</DialogTitle>
            <DialogContent sx={{ paddingTop: '12px !important' }}>
                {selectedEdit === "Change Status"
                    ? <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={newStatus}
                                label="Status"
                                onChange={(e) => setNewStatus(e.target.value)}
                            >
                                {leadsStatus.filter((leadStatus) => leadStatus.status !== 'Registered')
                                    .map((leadStatus) => (
                                        <MenuItem value={leadStatus}>{leadStatus.status}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    : <Autocomplete
                        multiple
                        freeSolo
                        options={leadsTags}
                        disableCloseOnSelect
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.inputValue)}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} label="Tags" />
                        )}
                        onChange={(event, newValue) => {
                            const updatedTags = newValue.map((option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.inputValue;
                            });
                            handleAddTagsChange(event, updatedTags);
                        }}
                        value={addTags}
                        renderTags={(value, getTagProps) => (
                            <>
                                {value.slice(0, 2).map((option, index) => (
                                    <Chip
                                        key={index}
                                        title={option}
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))}
                                {value.length > 2 && (
                                    <Chip
                                        label={`${value.length - 2}+`}
                                        {...getTagProps({ index: value.length - 1 })}
                                    />
                                )}
                            </>
                        )}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBulkUpdateClose}>Cancel</Button>
                <Button disabled={newStatus === null && addTags.length === 0} onClick={() => {
                    selectedEdit === "Change Status"
                        ? updateLeadStatus(newStatus, selected, null, setUpdateSuccess, setStatusSuccessAlert, setStatusFailedAlert, handleBulkUpdateClose, setSelected)
                        : updateLeadTag(addTags, selected, setIsTagEditing, setTagUpdated, setTagSuccessAlert, setTagFailedAlert, handleBulkUpdateClose, setSelected);
                }}>{selectedEdit === "Change Status" ? "Update" : "Add"}</Button>
            </DialogActions>
        </Dialog >
    )
}

export default UpdateStatusDialog;
