import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ECLeadFilters from './ec-lead-filters';
import { UserDetailsContext } from '../../context/user-details-context';
import EcAssignAgent from './ec-assign-agent';

function ECLeadsToolbar(props) {
    const { loggedUser } = useContext(UserDetailsContext);
    const { windowWidth, setCurrentPage, setGoToPage, filterApply, setFilterApply, setSearchString, setSearchType, setLeadStatus,
        setLeadSource, setLeadType, setProposalSent, setStatusFrom, setLocation, setProbability, setAgent, setSelectedECLead,
        selectedECLeads } = props;

    const navigate = useNavigate();
    const [openFilters, setOpenFilters] = useState(false);
    const [openAssignAgentDialog, setOpenAssignAgentDialog] = useState(false);

    const handleFiltersOpen = () => {
        setOpenFilters(true);
    };

    const createECLeads = () => {
        navigate("/enterprise-client/create-internal-ec-lead", { state: { fromPage: 'Dashboard' } });
    }

    const assignECLeads = () => {
        if (selectedECLeads.length > 0) {
            setOpenAssignAgentDialog(true);
        } else {
            alert('Please select at least one lead to assign an agent.');
        }
    }
    return (
        <Toolbar sx={{ padding: '20px', display: 'flex', alignItems: 'center', flexDirection: windowWidth <= 799 ? 'column' : 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Typography
                    sx={{
                        flex: windowWidth <= 799 ? '1 1 100%' : '1 1 auto', color: '#3b4758', fontFamily: 'DM Sans',
                        fontSize: windowWidth <= 799 ? '24px' : '1.5rem', fontWeight: 600, opacity: 1,
                        paddingBottom: windowWidth <= 799 ? '12px' : '0px'
                    }} >
                    EC Leads
                </Typography>
            </div>

            <div className={windowWidth <= 799 ? 'leads-toolbar-actions' : 'ec-leads-toolbar-actions'}>
                {loggedUser && loggedUser.access.includes('Lead:Assign') &&
                    <div className='ec-lead-top-filter'>
                        <Button variant="contained" sx={{ backgroundColor: '#7b5ede', '&:hover': { backgroundColor: '#5a32e4' } }} onClick={assignECLeads} >
                            Assign Agent
                        </Button>
                    </div>
                }
                {loggedUser && loggedUser.access.includes('Lead:Create') &&
                    <div className='ec-leads-top-filter' style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained" sx={{ backgroundColor: '#e563a4', '&:hover': { backgroundColor: '#e326a4' } }} onClick={() => { createECLeads(); setSelectedECLead([]); }} startIcon={<AddIcon />}>
                            Create Lead
                        </Button>
                    </div>
                }
                {filterApply
                    ? <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFiltersOpen}>
                            <FilterListOffIcon />
                        </IconButton>
                    </Tooltip>
                    : <Tooltip title="Apply Filters">
                        <IconButton onClick={() => { handleFiltersOpen(); setSelectedECLead([]); }}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                }
                <ECLeadFilters setCurrentPage={setCurrentPage} setGoToPage={setGoToPage} openFilters={openFilters}
                    setOpenFilters={setOpenFilters} setFilterApply={setFilterApply}
                    setSearchString={setSearchString} setSearchType={setSearchType} setLeadStatus={setLeadStatus}
                    setLeadSource={setLeadSource} setLeadType={setLeadType} setProposalSent={setProposalSent}
                    setStatusFrom={setStatusFrom} setLocation={setLocation} setProbability={setProbability} setAgent={setAgent} />
                <EcAssignAgent
                    open={openAssignAgentDialog}
                    setOpen={setOpenAssignAgentDialog}
                    selectedECLeads={selectedECLeads}
                />

            </div>
        </Toolbar >
    )
}

ECLeadsToolbar.propTypes = {
    windowWidth: PropTypes.any,
    setCurrentPage: PropTypes.any,
    setGoToPage: PropTypes.any,
    filterApply: PropTypes.any,
    setFilterApply: PropTypes.any,
    setSearchString: PropTypes.any,
    setSearchType: PropTypes.any,
    setLeadStatus: PropTypes.any,
    setLeadSource: PropTypes.any,
    setLeadType: PropTypes.any,
    setProposalSent: PropTypes.any,
    setStatusFrom: PropTypes.any,
    setLocation: PropTypes.any,
    setProbability: PropTypes.any,
    setAgent: PropTypes.any,
    setSelectedECLead: PropTypes.func,
    selectedECLeads: PropTypes.array,
};

export default ECLeadsToolbar;
