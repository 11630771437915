import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function TagTableToolbar() {
    return (
        <>
            <Toolbar sx={{ padding: '20px 20px 20px 20px' }}>
                <Typography sx={{ flex: '1 1 100%', color: '#3b4758', fontFamily: 'DM Sans', fontSize: '23px', fontWeight: 600, opacity: 1 }} >
                    Tags Management
                </Typography>
            </Toolbar >
        </>
    )
}

export default TagTableToolbar;
