import React from 'react'

function PagesPending() {
  return (
    <div>
      Coming soon...
    </div>
  )
}

export default PagesPending
