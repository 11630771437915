import React from 'react';

function ActionIconItem({ title, content }) {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="landing-ask-us-section-item">
            <div className="landing-ask-us-section-item-trigger">
                <h4 className="landing-ask-us-section-heading">{title}</h4>
                <div className="landing-ask-us-section-icon-wrapper">
                    <div className="expand-icon">
                        <img src="https://assets-global.website-files.com/5ef4691542433bca43839ceb/5f1e9d16771fe4ea4c4045b5_expand_more_icon.svg" width="40" alt="" className="landing-ask-us-section-expand-icon-image" onClick={toggleAccordion} style={{ display: isExpanded ? 'none' : 'block' }} />
                    </div>
                    <div className="collapse-icon">
                        <img src="https://assets-global.website-files.com/5ef4691542433bca43839ceb/5f1e9d1637b7ab2877f980f5_expand_less_icon.svg" width="40" alt="" className="landing-ask-us-section-collapse-icon-image" onClick={toggleAccordion} style={{ display: isExpanded ? 'block' : 'none' }} />
                    </div>
                </div>
            </div>
            <div style={{ height: isExpanded ? "auto" : 0, display: isExpanded ? 'block' : 'none' }} className="landing-ask-us-section-item-content">
                <p className="landing-ask-us-section-paragraph-regular landing-ask-us-section-left-align">
                    {content}
                </p>
            </div>
        </div>
    );
}

export default ActionIconItem;