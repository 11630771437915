import React, { useContext } from 'react'
import PlatformGrowthPage from './platform-growth-page';
import LeadsOverview from './leads-overview';
import CreatorProfileInsights from './creator-profile-insights';
import ClientCampaignOverview from './client-campaign-overview';
import { Box } from '@mui/material';
import CommonAppbarDrawer from '../../common/common-sidebar-navbar';
import { DrawerHeader } from '../../../mui/appbar-style-component';
import '../../../css/admin-dashboard.css';
import { WindowWidthContext } from '../../context/user-details-context';

function AdminDashboard() {
    const windowWidth = useContext(WindowWidthContext);
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: '2rem', backgroundColor: '#edeff4', minHeight: '100vh' }}>
                <DrawerHeader />
                <PlatformGrowthPage />
                <div className='admin-dashboard-divider'></div>
                <div className={windowWidth <= 799 ? 'donut-overview-div-mobile donut-overview-div' : 'donut-overview-div'}>
                    <div className={windowWidth <= 799 ? 'leads-overview-mobile' : 'leads-overview'}>
                        <LeadsOverview />
                    </div>
                    <div className={windowWidth <= 799 ? 'creator-profile-insights-mobile' : 'creator-profile-insights'}>
                        <CreatorProfileInsights />
                    </div>
                </div>
                <div className='admin-dashboard-divider'></div>
                <ClientCampaignOverview />
            </Box>
        </Box>
    )
}

export default AdminDashboard;
