import React from 'react'
import ActionIconItem from './action-icon-item';

function LandingAskUsPage() {
    return (
        <div className="landing-page-section-4">
            <div className="landing-page-section-2-container landing-page-section-2-vertical w-container">
                <h2 className="landing-page-section-4-heading-2">Frequently Asked Questions</h2>
                <div className="landing-ask-us-section-wrapper">
                    <ActionIconItem
                        title="Can I get paid collaborations through Oye Creators?"
                        content={
                            <>
                                Yes, you get only paid or barter collaborations through Oye Creators. Say goodbye to freebies
                            </>
                        }
                    />
                    <ActionIconItem
                        title="How much money can a creator earn with one reel?"
                        content={
                            <>
                                A creator can earn a minimum of ₹500 and a maximum of ₹5000 from one reel. Also, the<br></br>
                                pricing will depend on your Creator Scores
                            </>
                        }
                    />
                    <ActionIconItem
                        title="Do you have creators who can do barter collaborations?"
                        content={
                            <>
                                Yes, we have some creators who do barter, but it depends on what they are getting in exchange.<br></br>
                                It is entirely up to them if they want to do barter or not. We don't restrict our creators
                            </>
                        }
                    />
                    <ActionIconItem
                        title="Is there any criterion for subscription or platform fees?"
                        content={
                            <>
                                No, we are a no-commission platform, and as we follow outcome-based pricing,
                                <br></br>we don't have any subscription plans
                            </>
                        }
                    />
                    <ActionIconItem
                        title="I am a fresher with less than 1k followers, can I register?"
                        content={
                            <>
                                Yes, you can. We always welcome freshers and would appreciate it if they want to grow with us
                            </>
                        }
                    />
                    <ActionIconItem
                        title="I have a private account, can I also get projects?"
                        content={
                            <>
                                You can sign up with us, but getting a project is not guaranteed for you because Oye Creators<br></br>
                                post all the reels in collaboration, which in the case of private profiles, is restricted
                            </>
                        }
                    />
                </div>
            </div>
        </div >
    )
}

export default LandingAskUsPage;
