import React, { useCallback, useState, useEffect } from 'react';
import { Box, Card, Dialog, DialogContent, MenuItem, Select, TextField } from '@mui/material';
import InvoiceItem from './invoice-item';
import '../../css/invoice.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { billTextfieldBoxStyle, datepickerBoxStyle, notesTextfieldBoxStyle } from '../../mui/mui-styling';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import NumberInput from '../../components/invoice/custom-input-number';
import dayjs from 'dayjs';
import { getConfig } from '../common/env-config';
import CustomAlert from "../common/alert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { countryDropdownBoxStyle } from './../../mui/mui-styling';
import { getInvoice } from '../../components/invoice/invoice-repository';

const InvoiceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location?.pathname.split('/');
  const startPath = '/' + (pathSegments?.[1] || '');
  const { campaign } = location?.state || {};
  const { baseUrl } = getConfig();
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState('₹');
  const [currencyCode] = useState({
    '₹': 'INR',
    '$': 'USD',
    '£': 'GBP',
    '¥': 'JPY',
    '₿': 'BTC'
  });
  const { city, state } = campaign.location[0];

  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [billTo, setBillTo] = useState(campaign.userFirstname);
  const [billToEmail, setBillToEmail] = useState(campaign.userEmail);
  const [billToLine1, setBillToLine1] = useState(campaign.billingRegisteredAddress);
  const [billToLine2, setBillToLine2] = useState('');

  const [billToCity, setBillToCity] = useState(city);
  const [billToZipcode, setBillToZipcode] = useState(campaign.billingZipcode);
  const [billToState, setBillToState] = useState(state);
  const [billToCountry, setBillToCountry] = useState('IN');

  const [billFrom, setBillFrom] = useState('OyeCreators');
  const [billFromEmail, setBillFromEmail] = useState('Support@ttlmedia.in');
  const [billFromLine1, setBillFromLine1] = useState('Corporate Office: Office No:102,6th Floor,Cornerstone Project, T.P.Scheme No.1, Deccan Gymkhana Erandwane Road, Erandavana');
  const [billFromLine2, setBillFromLine2] = useState('');

  const [billFromCity, setBillFromCity] = useState('Pune');
  const [billFromZipcode, setBillFromZipcode] = useState('411004');
  const [billFromState, setBillFromState] = useState('Maharashtra');
  const [billFromCountry, setBillFromCountry] = useState('IN');

  const [notes, setNotes] = useState('');
  const [total, setTotal] = useState('0.00');
  const [subTotal, setSubTotal] = useState('0.00');
  const [taxRate, setTaxRate] = useState('');
  const [discountRate, setDiscountRate] = useState('');
  const [taxAmount, setTaxAmount] = useState('0.00');
  const [discountAmount, setDiscountAmount] = useState('0.00');
  const [dueDate, setDueDate] = useState(dayjs());
  const [billToEmailError, setBillToEmailError] = useState(false);
  const [billFromEmailError, setBillFromEmailError] = useState(false);
  const [reviewClicked, setReviewClicked] = useState(false);
  const [invoiceSavedAlertOpen, setInvoiceSavedAlertOpen] = useState(false);
  const [invoiceSaveErrorAlertOpen, setInvoiceSaveErrorAlertOpen] = useState(false);
  const [lineItems, setLineItems] = useState([{ name: '', description: '', price: '0.00', quantity: 0 }]);
  const [billToCityLength, setBillToCityLength] = useState(0);
  const [billToCityError, setBillToCityError] = useState(false);
  const [billFromCityLength, setBillFromCityLength] = useState(0);
  const [billFromCityError, setBillFromCityError] = useState(false);
  const [billToStateLength, setBillToStateLength] = useState(0);
  const [billToStateError, setBillToStateError] = useState(false);
  const [billFromStateLength, setBillFromStateLength] = useState(0);
  const [billFromStateError, setBillFromStateError] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);

  const isFormValid = () => {
    if (reviewClicked) {
      return (
        billTo !== '' &&
        billToEmail !== '' &&
        billToLine1 !== '' &&

        billToCity !== '' &&
        billFromState !== '' &&
        billFromState !== '' &&
        billFromZipcode !== '' &&

        billFrom !== '' &&
        billFromEmail !== '' &&
        billFromLine1 !== '' &&

        billFromCity !== '' &&
        billFromState !== '' &&
        billFromCountry !== '' &&
        billFromZipcode !== '' &&

        notes !== '' &&
        dueDate !== ''
      );
    } else {
      return true;
    }
  };


  const handleReviewInvoice = () => {
    setReviewClicked(true);
    if (isFormValid()) {
      openDialog();
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const openDialog = () => {
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };
  const handleChange = (event) => {
    const selectedToCurrency = event.target.value;
    setCurrency(selectedToCurrency);
  };
  const handleToCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setBillToCountry(selectedCountry);
  };

  const handleFromCountryChange = (event) => {
    const selectedFromCountry = event.target.value;
    setBillFromCountry(selectedFromCountry);
  };


  const handleChangeDateOfIssue = useCallback((date) => {
    if (date) {
      setDueDate(date);
    }
  }, []);
  function GenerateInvoice() {
    html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792]
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice-001.pdf');
    });
  }

  const handleItemListChange = useCallback((newItemList) => {
    setLineItems(newItemList);
  }, []);

  const handleInvoiceNumberChange = (value) => {
    setInvoiceNumber(value);
  };

  const handleTaxRateChange = (value) => {
    setTaxRate(value);
  };

  const handleDiscountRateChange = (value) => {
    setDiscountRate(value);
  };

  const saveInvoice = async (invoiceStatus) => {
    try {
      const itemsData = lineItems.map(item => ({
        name: item.name,
        description: item.description,
        amount: item.price * 100,
        quantity: item.quantity,
        currency: currencyCode[currency]
      }));
      const addressTo = {
        line1: billToLine1,
        city: billToCity,
        zipcode: billToZipcode,
        state: billToState,
        country: billToCountry,
        line2: billToLine2

      };
      const addressFrom = {
        line1: billFromLine1,
        city: billFromCity,
        zipcode: billFromZipcode,
        state: billFromState,
        country: billFromCountry,
        line2: billFromLine2

      };

      const response = await fetch(`${baseUrl}/admin/save-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify({
          billTo,
          billToEmail,
          billToAddress: addressTo,
          billFrom,
          billFromEmail,
          billFromAddress: addressFrom,
          notes,
          total: total * 100,
          subTotal,
          taxRate,
          discountRate,
          taxAmount,
          discountAmount,
          lineItems: itemsData,
          dueDate: dueDate.toISOString(),
          currentDate: new Date().toISOString(),
          invoiceNumber,
          currency: currencyCode[currency],
          invoiceStatus,
          campaignId: campaign.campaignId
        })
      });

      if (response.ok) {
        const successMessage = invoiceStatus === 'Draft'
          ? 'Invoice saved as draft successfully'
          : 'Invoice submitted to razorpay successfully';
        setInvoiceSavedAlertOpen(successMessage);
      } else {
        console.error('Failed to save invoice as draft');
        setInvoiceSaveErrorAlertOpen(true);
      }
    } catch (error) {
      console.error('Error while saving invoice:', error);
      setInvoiceSaveErrorAlertOpen(true);
    }
  };

  const generateRazorpayInvoice = async (invoiceId, invoiceStatus) => {
    try {
      const response = await fetch(`${baseUrl}/admin/razorpay-invoice?invoiceId=${invoiceId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify({ invoiceStatus })
      });
      if (response.ok) {
        const successMessage = invoiceStatus === 'Pending'
          ? 'Invoice submitted to razorpay successfully'
          : 'Invoice saved as draft successfully';
        setInvoiceSavedAlertOpen(successMessage);
      } else {
        console.error('Failed to submit invoice to razorpay');
        setInvoiceSaveErrorAlertOpen(true);
      }

    } catch (error) {
      console.error('Error generating razorpay invoice:', error);
      setInvoiceSaveErrorAlertOpen("'Error generating razorpay invoice");
    }
  };


  const handleSubmitInvoice = async () => {
    try {
      if (campaign.invoiceStatus === 'Draft') {
        await generateRazorpayInvoice(campaign.invoiceId, 'Pending');
      } else {
        await saveInvoice('Pending');
      }
    } catch (error) {
      console.error('Error submitting invoice:', error);
      setInvoiceSaveErrorAlertOpen(true);
    }

  };
  // city
  const handleToCityChange = (event) => {
    const toCityValue = event.target.value;
    setBillToCity(toCityValue);
    setBillToCityLength(toCityValue.length);
    if (toCityValue.length > 32) {
      setBillToCityError(true);
    } else {
      setBillToCityError(false);
    }
  };

  const handleFromCityChange = (event) => {
    const fromCityValue = event.target.value;
    setBillFromCity(fromCityValue);
    setBillFromCityLength(fromCityValue.length);
    if (fromCityValue.length > 32) {
      setBillFromCityError(true);
    } else {
      setBillFromCityError(false);
    }
  };
  // state
  const handleToStateChange = (event) => {
    const toStateValue = event.target.value;
    setBillToState(toStateValue);
    setBillToStateLength(toStateValue.length);
    if (toStateValue.length > 64) {
      setBillToStateError(true);
    } else {
      setBillToStateError(false);
    }
  };
  const handleFromStateChange = (event) => {
    const fromStateValue = event.target.value;
    setBillFromState(fromStateValue);
    setBillFromStateLength(fromStateValue.length);
    if (fromStateValue.length > 64) {
      setBillFromStateError(true);
    } else {
      setBillFromStateError(false);
    }
  };
  useEffect(() => {
    let totalSubtotal = 0;
    lineItems.forEach(item => {
      const subtotal = item.quantity * parseFloat(item.price);
      totalSubtotal += subtotal;
    });
    setSubTotal(totalSubtotal.toFixed(2));
  }, [lineItems]);

  useEffect(() => {
    const discountRateValue = parseFloat(discountRate) || 0;
    const taxRateValue = parseFloat(taxRate) || 0;

    const discountAmountValue = parseFloat((subTotal * discountRateValue) / 100).toFixed(2);
    setDiscountAmount(discountAmountValue);

    const taxAmountValue = parseFloat(((subTotal - discountAmountValue) * taxRateValue) / 100).toFixed(2);
    setTaxAmount(taxAmountValue);

    const totalValue = parseFloat((subTotal - discountAmountValue + taxAmountValue)).toFixed(2);
    setTotal(totalValue);
  }, [subTotal, discountRate, taxRate]);

  useEffect(() => {
    if (campaign && campaign.invoiceId) {
      getInvoice(campaign.invoiceId, setInvoiceData);
    }
  }, [campaign]);


  useEffect(() => {
    if (invoiceData) {
      setInvoiceNumber(invoiceData.invoiceNumber);
      setBillTo(invoiceData.billTo);
      setBillToEmail(invoiceData.billToEmail);
      setBillToLine1(invoiceData.billToAddress.line1);
      setBillToLine2(invoiceData.billToAddress.line2);
      setBillToCity(invoiceData.billToAddress.city);
      setBillToZipcode(invoiceData.billToAddress.zipcode);
      setBillToState(invoiceData.billToAddress.state);
      setBillToCountry(invoiceData.billToAddress.country);
      setBillFrom(invoiceData.billFrom);
      setBillFromEmail(invoiceData.billFromEmail);
      setBillFromLine1(invoiceData.billFromAddress.line1);
      setBillFromLine2(invoiceData.billFromAddress.line2);
      setBillFromCity(invoiceData.billFromAddress.city);
      setBillFromZipcode(invoiceData.billFromAddress.zipcode);
      setBillFromState(invoiceData.billFromAddress.state);
      setBillFromCountry(invoiceData.billFromAddress.country);
      setNotes(invoiceData.notes);
      setTotal((invoiceData.total / 100).toFixed(2));
      setSubTotal(invoiceData.subTotal.toFixed(2));
      setTaxRate(invoiceData.taxRate);
      setDiscountRate(invoiceData.discountRate);
      setTaxAmount(invoiceData.taxAmount.toFixed(2));
      setDiscountAmount(invoiceData.discountAmount.toFixed(2));
      setDueDate(invoiceData.dueDate ? dayjs(invoiceData.dueDate) : null);
      setLineItems(invoiceData.lineItems.map(item => ({
        name: item.name,
        description: item.description,
        price: (item.amount / 100).toFixed(2),
        quantity: item.quantity
      })) || [{ name: '', description: '', price: '0.00', quantity: 0 }]);

    }

  }, [invoiceData]);

  return (
    <Box component="main" sx={{ width: "100%" }}>
      <div className="main-content">
        <div className="campaign-details">
          <div className="campaign-details-head">
            <ArrowBackIcon className="arrow-back" onClick={() => navigate(`${startPath}/completed`)}></ArrowBackIcon>
            <h1 className="campaign-header-name">{campaign.campaignName}</h1>
          </div>

          <div className="campaign-list-hr"></div>

          <div className='invoice-screen' style={{ backgroundColor: '#edeff4' }}>
            <form className='align-form' onSubmit={(e) => { e.preventDefault(); }}>
              <Card className="align-card">
                <div className="align-row">
                  <div className="align-column">
                    <div className="mb-2">
                      <span className="text-bold">Current&nbsp;Date:&nbsp;</span>
                      <span className="current-date">{new Date().toLocaleDateString()}</span>
                    </div>
                    <div className="duedate-container">
                      <div className="text-bold">Due&nbsp;Date:</div>
                      <Box sx={datepickerBoxStyle}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}
                        >
                          <DatePicker
                            disablePast={true}
                            format="DD/MM/YYYY"
                            value={dueDate}
                            onChange={handleChangeDateOfIssue}
                            renderInput={(params) => (
                              <TextField
                                {...params}

                                value={dueDate ? dayjs(dueDate).format('DD/MM/YYYY') : ''}
                                required
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </div>
                  </div>
                  <div className="invoice-number-container">
                    <span className="text-bold">Invoice&nbsp;Number:&nbsp;</span>
                    <NumberInput initialValue={invoiceNumber} onChange={handleInvoiceNumberChange} />
                  </div>
                </div>
                <hr className="my-4" />
                <div className="textfield-container">
                  <div className='bill-textfields'>
                    <div className="text-bold">Bill to:</div>
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Who is this invoice to?"} value={billTo}
                      onChange={(event) => setBillTo(event.target.value)} required />
                    <TextField
                      sx={billTextfieldBoxStyle}

                      placeholder="Email address"
                      value={billToEmail}
                      onChange={(event) => {
                        setBillToEmail(event.target.value);
                        setBillToEmailError(true);
                      }}
                      onBlur={() => {
                        setBillToEmailError(true);
                      }}
                      required
                      error={
                        billToEmailError && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(billToEmail)
                      }
                      helperText={
                        billToEmailError &&
                        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(billToEmail) &&
                        "Please provide a valid email address"
                      }
                    />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Address line 1"} value={billToLine1}
                      onChange={(event) => setBillToLine1(event.target.value)} required />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Address line 2(Optional)"} value={billToLine2}
                      onChange={(event) => setBillToLine2(event.target.value)} />
                    <TextField
                      sx={billTextfieldBoxStyle}
                      placeholder={"City"}
                      value={billToCity}
                      onChange={handleToCityChange}
                      required
                    />{billToCityError && billToCityLength > 32 && (
                      <div className="error-message">City should be at most 32 characters</div>
                    )}
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Zipcode"} value={billToZipcode}
                      onChange={(event) => setBillToZipcode(event.target.value)} required />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"State"} value={billToState}
                      onChange={handleToStateChange} required />
                    {billToStateError && billToStateLength > 64 && (
                      <div className="error-message">State should be at most 64 characters</div>
                    )}
                    <Select
                      value={billToCountry}
                      onChange={handleToCountryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Change Country' }}
                      sx={{ ...countryDropdownBoxStyle }}
                      variant="outlined"
                    >
                      <MenuItem disabled value="">
                        <em sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: 'red' }}>Country</em>
                      </MenuItem>
                      <MenuItem value="IN">India</MenuItem>
                    </Select>
                  </div>
                  <div className='bill-textfields'>
                    <div className="text-bold">Bill from:</div>
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Who is this invoice from?"} value={billFrom}
                      onChange={(event) => setBillFrom(event.target.value)} required />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Email address"} value={billFromEmail}
                      onChange={(event) => {
                        setBillFromEmail(event.target.value);
                        setBillFromEmailError(true);
                      }}
                      onBlur={() => {
                        setBillFromEmailError(true);
                      }}
                      required
                      error={
                        billFromEmailError && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(billFromEmail)
                      }
                      helperText={
                        billFromEmailError &&
                        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(billFromEmail) &&
                        "Please provide a valid email address"
                      } />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Address line 1"} value={billFromLine1}
                      onChange={(event) => setBillFromLine1(event.target.value)} required />
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Address line 2(Optional)"} value={billFromLine2}
                      onChange={(event) => setBillFromLine2(event.target.value)} />
                    <TextField
                      sx={billTextfieldBoxStyle}
                      placeholder={"City"}
                      value={billFromCity}
                      onChange={handleFromCityChange}
                      required
                    />{billFromCityError && billFromCityLength > 32 && (
                      <div className="error-message">City should be at most 32 characters</div>
                    )}
                    <TextField sx={billTextfieldBoxStyle} placeholder={"Zipcode"} value={billFromZipcode}
                      onChange={(event) => setBillFromZipcode(event.target.value)} required />

                    <TextField sx={billTextfieldBoxStyle} placeholder={"State"} value={billFromState}
                      onChange={handleFromStateChange} required />
                    {billFromStateError && billFromStateLength > 64 && (
                      <div className="error-message">State should be at most 64 characters</div>
                    )}
                    <Select
                      value={billFromCountry}
                      onChange={handleFromCountryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Change Country' }}
                      sx={{ ...countryDropdownBoxStyle }}
                      variant="outlined"
                    >
                      <MenuItem disabled value="">
                        <em sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: 'red' }}>Country</em>
                      </MenuItem>
                      <MenuItem value="IN">India</MenuItem>
                    </Select>
                  </div>
                </div>
                <hr className="my-4" />
                <InvoiceItem
                  items={lineItems}
                  onItemListChange={handleItemListChange}
                />
                <div className="total-container">
                  <div className='total-container-text'>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="text-bold margin-gap">Subtotal:
                      </span>

                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="text-bold margin-gap">Discount:</span>

                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="text-bold margin-gap">Tax:
                      </span>
                    </div>
                    <div>  <hr className="my-4" /></div>
                    <div className="d-flex flex-row align-items-start justify-content-between" style={{
                      fontSize: '1.125rem'
                    }}>
                      <span className="text-bold margin-gap">Total:
                      </span>
                    </div>
                  </div>
                  <div className='tag'>
                    <div className="span-align">{currency}
                      {subTotal}</div>
                    <div className="span-align">({discountRate || 0}%) {currency}
                      {discountAmount || 0}</div>
                    <div className="span-align">({taxRate || 0}%) {currency}
                      {taxAmount || 0}</div>
                    <div className="span-align-total">{currency}
                      {total || 0}</div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="text-bold">Notes:</div>
                <TextField sx={notesTextfieldBoxStyle} className="notes-textfield" placeholder="Thanks for your business!" value={notes}
                  onChange={(event) => setNotes(event.target.value)} required />
              </Card>
              <div >
                <div className="sticky-top">

                  <div className="mb-3">
                    <div className="text-bold">Currency:</div>
                    <Select
                      value={currency}
                      onChange={handleChange}
                      variant="outlined"
                      className="btn btn-light my-1 currency-dropdown"
                      aria-label="Change Currency"
                    >
                      <MenuItem value="₹">INR (Indian Rupee)</MenuItem>
                      {/* <MenuItem value="$">USD (United States Dollar)</MenuItem>
                      <MenuItem value="£">GBP (British Pound Sterling)</MenuItem>
                      <MenuItem value="¥">JPY (Japanese Yen)</MenuItem>
                      <MenuItem value="₿">BTC (Bitcoin)</MenuItem> */}
                    </Select>
                  </div>
                  <div className='tax-container'>
                    <div className="my-3">
                      <div className="text-bold">Tax rate:</div>
                      <div className='rate-container'>
                        <NumberInput initialValue={0.00.toFixed(2)} onChange={handleTaxRateChange} />
                        <div className='percentage'>%</div>
                      </div>

                    </div>
                    <div className="my-3">
                      <div className="text-bold">Discount rate:</div>
                      <div className='rate-container'>
                        <NumberInput initialValue={0.00.toFixed(2)} onChange={handleDiscountRateChange} />
                        <div className='percentage'>%</div>
                      </div>
                    </div>
                  </div>
                  <div> <hr className="my-4" /></div>
                  {campaign.invoiceStatus !== 'Completed' && (
                    <><button type="button" onClick={() => saveInvoice('Draft')} className="send-invoice-btn common-responsive-btn">
                      Save as Draft</button>
                      <button onClick={handleSubmitInvoice} className="send-invoice-btn common-responsive-btn">
                        Submit</button></>
                  )
                  }
                  <button className="review-invoice-btn common-responsive-btn" onClick={handleReviewInvoice}>
                    Review Invoice
                  </button>
                </div>
              </div>
            </form>
            <Dialog className='dialog-container' open={isOpen} onClose={closeDialog} maxWidth='xl' >
              <DialogContent className='dialog-container'>
                <div className="modal-content">
                  <div id="invoiceCapture">
                    <div className="review-heading">
                      <div className="">
                        <div className="text-bold-size">{billFrom}</div>
                        <div className="text-size">Invoice #: {invoiceNumber}</div>
                      </div>
                      <div className="">
                        <div className="text-bold-size">Amount&nbsp;Due:</div>

                        <div className="text-size">{currency} {total}</div>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="bill-container">
                        <div className="col-md-4">
                          <div className="fw-bold">Billed to:</div>
                          <div className='overflow-wrap'> {billTo}</div>
                          <div className='overflow-wrap'> {billToLine1}</div>
                          <div className='overflow-wrap'>{billToCity} </div>
                          <div className='overflow-wrap'>{billToState}</div>
                          <div className='overflow-wrap'>{billToCountry}</div>
                          <div className='overflow-wrap'>{billToZipcode}</div>
                          <div className='overflow-wrap'>{billToEmail}</div>
                          <div className='overflow-wrap'> {billToLine2}</div>

                        </div>
                        <div className="col-md-4">
                          <div className="fw-bold">Billed From:</div>
                          <div className='overflow-wrap'>{billFrom}</div>
                          <div className='overflow-wrap'> {billFromLine1}</div>
                          <div className='overflow-wrap'>{billFromCity} </div>
                          <div className='overflow-wrap'>{billFromState}</div>
                          <div className='overflow-wrap'>{billFromCountry}</div>
                          <div className='overflow-wrap'>{billFromZipcode}</div>
                          <div className='overflow-wrap'>{billFromEmail}</div>
                          <div className='overflow-wrap'>{billFromLine2}</div>
                        </div>
                        <div className="col-md-4">
                          <div className="fw-bold mt-2">Date Of Issue:</div>
                          <div className='overflow-wrap'>{dueDate && dayjs(dueDate).format("DD MM, YYYY")}</div>
                        </div>
                      </div>

                      <table className="custom-width-table">
                        <thead style={{ borderBottom: '1px solid #ccc' }}>
                          <tr>
                            <th style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', textAlign: 'start', padding: '8px' }}>QTY</th>
                            <th style={{ width: '50%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', textAlign: 'start', padding: '8px' }}>DESCRIPTION</th>
                            <th style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', textAlign: 'start', padding: '8px' }}>PRICE</th>
                            <th style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', textAlign: 'start', padding: '8px' }}>AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lineItems.map(item => (
                            <tr key={item.id}>
                              <td style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: '1px solid #ccc', textAlign: 'start', padding: '8px' }}>{item.quantity}</td>
                              <td style={{ width: '50%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: '1px solid #ccc', textAlign: 'start', padding: '8px' }}>{item.name} - {item.description}</td>
                              <td style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: '1px solid #ccc', textAlign: 'start', padding: '8px' }}>{currency} {item.price}</td>
                              <td style={{ width: '10%', borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: '1px solid #ccc', textAlign: 'start', padding: '8px' }}>{currency} {(item.quantity * item.price).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr className="text-end">
                            <td>
                            </td>
                            <td className="fw-bold" >SUBTOTAL</td>
                            <td className="text-end">{currency} {subTotal}</td>
                          </tr>
                          <tr className="text-end"><td>
                          </td>
                            <td className="fw-bold" >TAX</td>
                            <td className="text-end" >{currency} {taxAmount}</td>
                          </tr>
                          <tr className="text-end">
                            <td>
                            </td>
                            <td className="fw-bold" >DISCOUNT</td>
                            <td className="text-end" >{currency} {discountAmount}</td>
                          </tr>
                          <tr className="text-end">
                            <td>
                            </td>
                            <td className="fw-bold" >TOTAL</td>
                            <td className="text-end" >{currency} {total}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="bg-light py-3 px-4 rounded notes-review">{notes}</div>
                    </div>
                  </div>
                  <div className="sub-button-container">
                    <div className="col-md-6">
                      <button onClick={GenerateInvoice} className="download-invoice-btn">
                        Download Copy</button>
                    </div>

                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <div className="alert-msg">

              <CustomAlert
                open={invoiceSavedAlertOpen}
                severity="success"
                message={invoiceSavedAlertOpen}
                onClose={() => {
                  setInvoiceSavedAlertOpen(false);
                  navigate(`${startPath}/completed`);
                }}
              />
              <CustomAlert
                open={invoiceSaveErrorAlertOpen}
                severity="error"
                message={invoiceSavedAlertOpen}
                onClose={() => {
                  setInvoiceSaveErrorAlertOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default InvoiceForm;