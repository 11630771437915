import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { autocompleteService, GOOGLE_MAPS_API_KEY, loadScript } from '../../common/googlemap-config';

export default function ECLeadLocation({ handleLeadChange, leadLocationDescription }) {
    const [leadLocation, setLeadLocation] = useState(null);
    const [inputLocationValue, setInputLocationValue] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);
    const loaded = useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                const restrictedRequest = {
                    ...request,
                    componentRestrictions: { country: 'in' },
                };
                autocompleteService.current.getPlacePredictions(restrictedRequest, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputLocationValue === '') {
            setLocationOptions(leadLocation ? [leadLocation] : []);
            return undefined;
        }

        fetch({ input: inputLocationValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (leadLocation) {
                    newOptions = [leadLocation];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setLocationOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [leadLocation, inputLocationValue, fetch]);

    return (
        <Autocomplete name="leadLocation" id="google-map-demo"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : leadLocationDescription ? leadLocationDescription : option.description
            }
            filterOptions={(x) => x} options={locationOptions} autoComplete includeInputInList
            filterSelectedOptions value={leadLocation ? leadLocation : leadLocationDescription}
            noOptionsText="No locations"
            onChange={(event, newValue) => {
                setLocationOptions(newValue ? [newValue, ...locationOptions] : locationOptions);
                setLeadLocation(newValue);
                handleLeadChange('placeId', newValue && newValue.place_id);
                handleLeadChange('leadLocation', newValue && newValue.description);
            }}
            onInputChange={(event, newInputValue) => {
                setInputLocationValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label={<span>Location<span style={{ color: 'red' }}>*</span></span>} />}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
