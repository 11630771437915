import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function LoginCreatorComponent({
  handleChange, enterpriseClient, instaHandle, managerName, managerEmail, managerPhoneNumber, managerId,
  instaHandleError, instaHandleErrorMsg, setInstaHandleError, setInstaHandleErrorMsg,
}) {
  const isManagerDetails = managerId !== null && managerId !== "" ? managerId !== 0 : false;
  const [showManagerDetails, setShowManagerDetails] = useState(
    isManagerDetails ? true : false
  );

  const validateInstaHandle = (value) => {
    const validCharacters = /^[a-zA-Z0-9._]*$/;
    if (validCharacters.test(value)) {
      setInstaHandleError(false);
      setInstaHandleErrorMsg("");
      return true;
    } else {
      setInstaHandleError(true);
      setInstaHandleErrorMsg(
        "Only letters, digits, periods, and underscores are allowed"
      );
      return false;
    }
  };

  const handleInstaHandleChange = (e) => {
    const value = e.target.value;
    if (validateInstaHandle(value)) {
      handleChange('instaHandle', value)
    }
  };

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.which);
    const validCharacters = /^[a-zA-Z0-9._]*$/;
    if (!validCharacters.test(char)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (instaHandle) {
      validateInstaHandle(instaHandle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="instaHandle info">
        <TextField
          className="custom-textfield"
          id="outlined-basic"
          label="Insta Handle"
          variant="outlined"
          value={instaHandle}
          required={instaHandleError}
          onChange={handleInstaHandleChange}
          onKeyPress={handleKeyPress}
          style={{ width: 378 }}
          error={instaHandleError}
          helperText={instaHandleErrorMsg}
        />
        <div className="infoIcon">
          <Tooltip title="Please enter the Instagram handle of the creator/business">
            <IconButton onClick={() => { }}>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {!enterpriseClient &&
        <div className="manager-details">
          <Tooltip title="Add Manager Details">
            <Button
              variant="contained"
              sx={{
                borderRadius: "100px",
                backgroundColor: "#e563a4",
                "&:hover": {
                  backgroundColor: "#e326a4",
                },
              }}
              onClick={() => {
                setShowManagerDetails(!showManagerDetails);
              }}
              startIcon={<AddIcon />}
            >
              Manager Details
            </Button>
          </Tooltip>
        </div>
      }
      {showManagerDetails && (
        <>
          <div className="custom-manager">
            {" "}
            Manager Details
            <div className="infoIcon-manager">
              <Tooltip title="Manager Details info">
                {" "}
                <IconButton onClick={() => { }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <TextField
            className="custom-textfield"
            id="outlined-basic"
            label="Name (Optional)"
            variant="outlined"
            value={managerName}
            style={{ width: 378 }}
            onChange={(e) => {
              handleChange('manager.managerName', e.target.value);
            }}
          />
          <TextField
            className="custom-textfield"
            id="outlined-basic"
            label="Phone Number (Optional)"
            variant="outlined"
            value={managerPhoneNumber}
            style={{ width: 378 }}
            onChange={(e) => {
              const input = e.target.value;
              const numericInput = input.replace(/\D/g, "");
              const trimmedInput = numericInput.slice(0, 10);
              handleChange('manager.managerPhoneNumber', trimmedInput);
            }}
          />
          <TextField
            className="custom-textfield"
            id="outlined-basic"
            label="Email (Optional)"
            variant="outlined"
            value={managerEmail}
            style={{ width: 378 }}
            onChange={(e) => {
              handleChange('manager.managerEmail', e.target.value);
            }}
            error={
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                managerEmail
              ) && managerEmail !== ""
            }
            helperText={
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                managerEmail
              ) && managerEmail !== ""
                ? "Please enter a valid email address"
                : ""
            }
          />
        </>
      )}
    </>
  );
}
