import React, { useEffect, useState, useCallback, useContext } from "react";
import "../../../css/user-list.css";
import { Box, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, Typography, TableContainer, TextField, FormControl, Select, MenuItem } from "@mui/material";
import { refreshToken } from "../../common/refresh-token-repo";
import { getConfig } from "../../common/env-config";
import { StyledTableRow, TableRowStyledBox, TableCellStyledBox, TableRowPopupStyledBox } from "../../../mui/mui-styling";
import { WindowWidthContext } from "../../context/user-details-context";
import CommonPagination from "../../common/common-pagination";

function EcAffiliateMarketing() {
  const { baseUrl } = getConfig();
  const [affiliateData, setAffiliateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [affiliateUsers, setAffiliateUsers] = useState([]);
  const [page, setPage] = useState(1);
  const windowWidth = useContext(WindowWidthContext);
  const [totalCount, setTotalCount] = useState();
  const [totalRegisteredAffiliates, setTotalRegisteredAffiliates] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [registeredRecordsPerPage] = useState(10);

  const [goToPage, setGoToPage] = useState(1);
  const fetchAffiliateData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/admin/get-external-affiliates-list?pageNo=${currentPage}&pageSize=${recordsPerPage}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (response.ok) {
        setAffiliateData(data.affiliates || []);
        setTotalCount(data.totalCount || 0);

      } else if (response.status === 499) {
        refreshToken();
      } else {
        console.error("Error fetching external affiliate data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching external affiliate data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [baseUrl, currentPage, recordsPerPage]);

  const fetchAffiliateUsers = useCallback(
    async (externalAffiliate) => {
      setIsFetchingUsers(true);
      try {
        const response = await fetch(`${baseUrl}/admin/external-affiliates-registered-leads?pageNo=${page}&pageSize=${registeredRecordsPerPage}&source=${externalAffiliate}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          }
        });

        const data = await response.json();
        if (response.ok) {
          setAffiliateUsers(data.registeredLeads || []);
          setTotalRegisteredAffiliates(data.totalRegistration || 0);
          return data.registeredLeads || [];
        } else if (response.status === 499) {
          refreshToken();
        } else {
          console.error("Error fetching external affiliate users:", data.message);
        }
      } catch (error) {
        console.error("Error fetching external affiliate users:", error);
      } finally {
        setIsFetchingUsers(false);
      }
    },
    [baseUrl, page, registeredRecordsPerPage]
  );


  useEffect(() => {
    fetchAffiliateData();
  }, [fetchAffiliateData]);

  useEffect(() => {
    if (openPopup && selectedAffiliate) {
      const fetchUsers = async () => {
        const users = await fetchAffiliateUsers(selectedAffiliate);
        if (Array.isArray(users)) {
          setAffiliateUsers(users);
        } else {
          console.error("Expected an array for affiliate users, but got:", users);
          setAffiliateUsers([]);
        }
      };
      fetchUsers();
    }
  }, [openPopup, selectedAffiliate, fetchAffiliateUsers]);


  const handleAffiliateClick = (externalAffiliate) => {
    setSelectedAffiliate(externalAffiliate);
    setOpenPopup(true);
    setPage(1);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleRowPerPageChange = (value) => {
    setRecordsPerPage(value);
    setGoToPage(1);
    setCurrentPage(1);
  }
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setGoToPage(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        setCurrentPage(goToPage === '' ? 1 : goToPage);
    }
};

const handleKeyDown = (e) => {
  if (e.key === 'Backspace' && e.target.value.length === 1) {
      setGoToPage(1);
      setCurrentPage(1);
  }
};

const handleBlur = () => {
  if (goToPage === '') {
      setGoToPage(1);
      setCurrentPage(1);
  }
};
  const handleRegisterdPageChange = (event, value) => {
    setPage(value);
  };
  return (
    <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ width: "100%" }}>
          <div className="userlist-content">
            <div className="userlist-page">
              <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
                <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: '20px 20px 20px 20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '4rem' }}>
                  <Typography sx={{
                    flex: '1 1 100%',
                    color: '#3b4758',
                    fontFamily: 'DM Sans',
                    fontSize: '24px',
                    fontWeight: 600,
                    opacity: 1,
                    paddingBottom: '12px'
                  }}>External Affiliate Marketing </Typography>
                  <TableContainer sx={{ padding: '0px 15px 25px 15px' }}>
                    <Table >
                      <TableHead>
                        <TableRowStyledBox>
                          <TableCell>Sl.No</TableCell>
                          <TableCell>Affiliate Code</TableCell>
                          <TableCell>No Of Registration</TableCell>
                        </TableRowStyledBox>
                      </TableHead>
                      {isLoading ? (
                        <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                          <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {isLoading ? <CircularProgress /> : null}
                          </div>
                        </TableCell>
                      ) : affiliateData.length === 0 ? (
                        <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                          <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="no-data">
                              "No Affiliate's Found"
                            </div>
                          </div>
                        </TableCell>
                      ) : (
                        <TableBody>
                          {affiliateData.map((externalAffiliate, index) => (
                            <StyledTableRow className='top-campaign-row'
                              tabIndex={-1} key={externalAffiliate.index} sx={{ cursor: 'pointer' }}

                              onClick={() =>
                                handleAffiliateClick(externalAffiliate.source)
                              }
                            >
                              <TableCell sx={{ ...TableCellStyledBox }}>{index + 1}</TableCell>
                              <TableCell sx={{ ...TableCellStyledBox }}
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                {externalAffiliate.source}
                              </TableCell>
                              <TableCell sx={{ ...TableCellStyledBox }}>{externalAffiliate.registrationCount}</TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <div className={windowWidth <= 799 ? 'userlist-table-mobile' : 'userlist-table-footer'}>
                    <div className='userlist-table-footer'>
                      <div>
                        Showing {isLoading ? 0 : ((currentPage - 1) * recordsPerPage) + 1} - {isLoading ? 0 : Math.min(currentPage * recordsPerPage, totalCount)} of {isLoading ? 0 : totalCount}
                      </div>
                       <div className={windowWidth <= 799 ? 'go-to-page-mobile' : 'go-to-page'}>
                                Go to page :
                                <TextField type="number" id="outlined-size-normal" defaultValue="1" style={{ width: 'fit-content', backgroundColor: '#fff' }}
                                    variant="outlined" value={goToPage} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}
                                    onBlur={handleBlur} onChange={(e) => {
                                        let value = parseInt(e.target.value, 10);
                                        if (isNaN(value)) {
                                            setGoToPage('');
                                        } else if (value < 1 || value > Math.ceil(totalCount / recordsPerPage)) {
                                            setGoToPage('');
                                        } else {
                                            setGoToPage(value);
                                        }
                                    }}
                                    inputProps={{ min: 1, max: isLoading ? 1 : Math.ceil(totalCount / recordsPerPage) }} />
                            </div>
                    </div>
                    <div className='userlist-table-footer'>
                      <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', columnGap: '13px' }}>
                        <div>Records Per page :</div>
                        <FormControl>
                          <Select sx={{ backgroundColor: '#fff', width: 'fit-content' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={recordsPerPage}
                            onChange={(e) => handleRowPerPageChange(e.target.value)}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <CommonPagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(totalCount / recordsPerPage)}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </Paper>
              </Box>

            </div>
          </div>
        </Box>
      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>External users registered with {selectedAffiliate}</DialogTitle>
        <DialogContent>
          {isFetchingUsers ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <CircularProgress />
            </div>
          ) : (
            <Table>
              <TableHead>
                <TableRowPopupStyledBox>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                </TableRowPopupStyledBox>
              </TableHead>
              <TableBody>
                {affiliateUsers.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.contactNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <CommonPagination
              currentPage={page}
              totalPages={Math.ceil(totalRegisteredAffiliates / registeredRecordsPerPage)}
              handlePageChange={handleRegisterdPageChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EcAffiliateMarketing;
