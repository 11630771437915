import React, { useContext, useEffect, useState } from 'react'
import InstaIcon from '../../assets/insta-icon.png';
import ProgressBarIcon from '../../assets/progress-bar.png';
import ScoreIcon from '../../assets/scrore-icon.png';
import PaymentIcon from '../../assets/payment-icon.png';
import { getUserInsights } from '../common/user-details-repo';
import { UserDetailsContext, WindowWidthContext } from '../context/user-details-context';
import { getConfig } from '../common/env-config';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function CreatorInfo({ isReloadDetails }) {
    const windowWidth = useContext(WindowWidthContext);
    const { baseUrl } = getConfig();
    const { loggedUser } = useContext(UserDetailsContext);
    const [userInsights, setUserInsights] = useState({
        userName: '', fullName: '', followersCount: '', mediaCount: '', engagement: '',
        engagementRate: '', category: '', profilePictureId: '', bio: '', location: '',
        avgLike: '', avgComment: '', creatorRates: '', creatorScore: ''
    });
    const [profilePictureUrl, setProfilePictureUrl] = useState('');

    useEffect(() => {
        if (userInsights.profilePictureId) {
            const timestamp = new Date().getTime();
            const downloadUrl = `${baseUrl}/download-insta-profile/${userInsights.profilePictureId}?t=${timestamp}`;
            setProfilePictureUrl(downloadUrl);
        }
    }, [isReloadDetails, userInsights.profilePictureId, baseUrl]);

    useEffect(() => {
        if (loggedUser && !loggedUser.isInstaAccountPrivate && loggedUser.instaInsight != null) {
            getUserInsights(loggedUser.userId, setUserInsights, null, false);
        }
    }, [loggedUser, isReloadDetails]);

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            const roundedNumber = Math.round(num * 100) / 100;
            if (Number.isInteger(roundedNumber)) {
                return roundedNumber.toString();
            } else {
                return roundedNumber.toFixed(2);
            }
        }
    };
    return (
        <div className={windowWidth <= 799 ? 'creator-info-card creator-info-card-mobile' : 'creator-info-card'}>
            <div className={windowWidth <= 799 ? 'profile-info-mobile' : 'profile-info'}>
                <div className="user-profile-div">
                    <div className={windowWidth <= 799 ? 'user-profile-pic user-profile-pic-mobile' : 'user-profile-pic'} style={{
                        backgroundImage: `url(${userInsights.profilePictureId
                            ? `${profilePictureUrl}`
                            : <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="_verified_dbbth_91">
                                <path d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z" fill="#4AABED">
                                </path>
                            </svg>
                            })`
                    }}></div>
                </div>
                <div className='insta-profile-name'>
                    <Tooltip title={!userInsights.fullName
                        ? loggedUser
                            ? loggedUser.firstName + " " + loggedUser.lastName
                            : 'NA'
                        : userInsights.fullName}>
                        <div className='dashboard-username'>{!userInsights.fullName
                            ? loggedUser
                                ? loggedUser.firstName + " " + loggedUser.lastName
                                : 'NA'
                            : userInsights.fullName}</div>
                    </Tooltip>
                    <Tooltip title={!userInsights.userName
                        ? loggedUser
                            ? loggedUser.instaHandle
                            : 'NA'
                        : userInsights.userName}>
                        <div className='dashboard-instahandle'>@{!userInsights.userName
                            ? loggedUser
                                ? loggedUser.instaHandle
                                : 'NA'
                            : userInsights.userName}</div>
                    </Tooltip>
                </div>
            </div>

            <div className={windowWidth <= 799 ? '' : 'connected-accounts'}>
                <div className='connect-accounts-text'>Connected Accounts</div>
                <div className='followers-div'>
                    <img className='dashboard-insta-icon' src={InstaIcon} alt='Insta Icon' />
                    <div>
                        <div className='engagement-count-text'>{!userInsights.followersCount ? 'NA' : formatNumber(userInsights.followersCount)}</div>
                        <div className='engagement-text'>Followers</div>
                    </div>
                </div>
            </div>
            {windowWidth <= 799
                ? <>
                    <div className='insta-info-mobile'>
                        <div className='insta-info-mobile-inner-div'>
                            <div className='engagement-rate-text'>
                                <div>Engagement Rate</div>
                                <Tooltip title='Engagement Rate is the ratio of likes divided by followers.'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='engagement-count-text'>{!userInsights.engagementRate ? 'NA' : `${parseFloat(userInsights.engagementRate).toFixed(2)}%`}</div>
                        </div>
                        <div className='insta-info-mobile-inner-div'>
                            <div className='engagement-rate-text'>
                                <div>Creator Score</div>
                                <Tooltip title='Creator Score'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='engagement-count-text'>{!userInsights.creatorScore === null ? 'Coming soon' : 'Coming soon'}</div>
                        </div>
                    </div>
                    <div className='insta-info-mobile'>
                        <div className='insta-info-mobile-inner-div'>
                            <div className='engagement-rate-text'>
                                <div>Engagement</div>
                                <Tooltip title='Engagement indicates the average number of engagements on each post.'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='engagement-count-text'>{!userInsights.engagement ? 'NA' : formatNumber(userInsights.engagement)}</div>
                        </div>
                        <div className='insta-info-mobile-inner-div'>
                            <div className='engagement-rate-text'>
                                <div>Payout Per Content</div>
                                <Tooltip title='Payout Per Content'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='engagement-count-text'>{!userInsights.creatorRates ? 'Ask for price' : 'Ask for price'}</div>
                        </div>
                    </div>
                </>
                : <div className='insta-info-cantainer'>
                    <div className='insta-info-div'>
                        <div className='connected-accounts engagement-rate'>
                            <div className='engagement-rate-text'>
                                <div>Engagement Rate</div>
                                <Tooltip title='Engagement Rate is the ratio of likes divided by followers.'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='followers-div'>
                                <img className='progress-bar-icon' src={ProgressBarIcon} alt='Progress Bar Icon' />
                                <div>
                                    <div className='engagement-count-text'>{!userInsights.engagementRate ? 'NA' : `${parseFloat(userInsights.engagementRate).toFixed(2)}%`}</div>
                                    <div className='engagement-text'>Current Engagement Rate</div>
                                </div>
                            </div>
                        </div>

                        <div className='connected-accounts engagement-rate'>
                            <div className='engagement-rate-text'>
                                <div>Creator Score</div>
                                <Tooltip title='Creator Score'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='followers-div'>
                                <img className='progress-bar-icon' src={ScoreIcon} alt='Score Icon' />
                                <div>
                                    <div className='engagement-count-text'>
                                        {!userInsights.creatorScore === null ? 'Coming soon' : 'Coming soon'}
                                    </div>
                                    <div className='engagement-text'>Based on Engagement</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='insta-info-div engagement'>
                        <div className='connected-accounts engagement-rate'>
                            <div className='engagement-rate-text'>
                                <div>Engagement</div>
                                <Tooltip title='Engagement indicates the average number of engagements on each post.'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='followers-div'>
                                <img className='progress-bar-icon' src={ProgressBarIcon} alt='Progress Bar Icon' />
                                <div>
                                    <div className='engagement-count-text'>{!userInsights.engagement ? 'NA' : formatNumber(userInsights.engagement)}</div>
                                    <div className='engagement-text'>Current Engagement</div>
                                </div>
                            </div>
                        </div>

                        <div className='connected-accounts engagement-rate'>
                            <div className='engagement-rate-text'>
                                <div>Payout Per Content</div>
                                <Tooltip title='Payout Per Content'>
                                    <InfoOutlinedIcon sx={{ m: 1 }} />
                                </Tooltip>
                            </div>
                            <div className='followers-div'>
                                <img className='progress-bar-icon' src={PaymentIcon} alt='Payment Icon' />
                                <div>
                                    <div className='engagement-count-text'>{!userInsights.creatorRates ? 'Ask for price' : 'Ask for price'}</div>
                                    <div className='engagement-text'>Payout as per creator score</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreatorInfo;
