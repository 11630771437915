import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';
const { baseUrl } = getConfig();

export const getInvoice = async (invoiceId, setInvoice) => {
  try {
    const response = await fetch(`${baseUrl}/admin/get-invoice?invoiceId=${invoiceId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    const invoiceData = await response.json();
    if (response.ok) {
      setInvoice(invoiceData);
      return invoiceData;

    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error('Error fetching invoice:', invoiceData.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching invoice:', error);
  }
};

export const getAllInvoices = async (invoiceFilter, setInvoices) => {
  try {
    const response = await fetch(`${baseUrl}/admin/get-invoice-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify(invoiceFilter)
    });

    const invoiceData = await response.json();
    if (response.ok) {
      setInvoices(invoiceData.invoices || []);
      return {
        invoices: invoiceData.invoices || [],
        totalCount: invoiceData.totalCount || 0,
        filteredCount: invoiceData.filteredCount || 0,
        draftCount: invoiceData.draftCount || 0,
        pendingCount: invoiceData.pendingCount || 0,
        completedCount: invoiceData.completedCount || 0
      };
    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error('Error fetching invoices:', invoiceData.message);
      setInvoices([]);
    }
  } catch (error) {
    console.error('Error fetching invoices:', error);
    setInvoices([]);
  }
};

