import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export default function DialogBox({ userDetails, openAlert, setOpenAlert, enterpriseClient, campaignId, forEC }) {
    const navigate = useNavigate();

    const handleAlertClose = () => {
        setOpenAlert(false);
    };

    const handleAlertContinue = async (e) => {
        e.preventDefault();
        if (enterpriseClient && !forEC) {
            navigate('/enterprise-client/registration', { state: { userDetails, enterpriseClient: true, adminUpdate: true, billingDetails: true } });
        } else {
            navigate('/login', { state: { userDetails, campaignId } });
        }
    }
    return (
        <Dialog
            open={openAlert}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Warning"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {enterpriseClient ? 'Additional billing details is mandatory for campaign creation, Please enter billing details to create campaign'
                        : 'Your insta handle is private, please update insta handle'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAlertClose}>Cancel</Button>
                {!forEC &&
                    <Button onClick={(e) => { handleAlertContinue(e); }} autoFocus>
                        Continue
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
