import React, { useContext } from 'react';
import { Box } from '@mui/material';
import CommonAppbarDrawer from '../../common/common-sidebar-navbar';
import { DrawerHeader } from '../../../mui/appbar-style-component';
import TagsManagement from './tags-management';
import { WindowWidthContext } from '../../context/user-details-context';

function TagsDashboard() {
    const windowWidth = useContext(WindowWidthContext);
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: windowWidth <= 799 ? '5rem 18px 0 0' : '7rem', backgroundColor: '#edeff4', display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
                <DrawerHeader />
                <TagsManagement />
            </Box >
        </Box >
    )
}

export default TagsDashboard;
