import { createTheme } from "@mui/material";

export const appBarTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff'
                }
            }
        }
    }
});

export const paginationTheme = createTheme({
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                selected: {
                    backgroundColor: '#947ebb !important',
                    '&:hover': {
                        backgroundColor: '#947ebb'
                    }
                }
            }
        },
    }
});

export const creatorsTableTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#7d8fa9 !important'
                },
                body: {
                    padding: 0
                }
            }
        }
    }
});
