import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';

const { baseUrl } = getConfig();

export const fetchUserList = async (setIsLoading, setApiFailed, currentPage, recordsPerPage, setUserList, setTotalCount, adminFilters,
    searchString, privateInstaAccounts, incompleteRegistration
) => {
    setIsLoading(true);
    setApiFailed(false);
    try {
        let requestBody = {
            pageNo: currentPage,
            pageSize: recordsPerPage,
        };

        if (privateInstaAccounts != null) {
            requestBody.privateInstaAccounts = privateInstaAccounts;
        }

        if (incompleteRegistration != null) {
            requestBody.incompleteRegistration = incompleteRegistration;
        }

        if (searchString != null && searchString !== "") {
            requestBody.searchString = searchString;
        }

        
        if (adminFilters.state.length > 0) {
            requestBody.state = adminFilters.state;
        }
        if (adminFilters.city.length > 0) {
            requestBody.city = adminFilters.city;
        }
        if (adminFilters.area.length > 0) {
            requestBody.area = adminFilters.area;
        }

        if (adminFilters.areaOfInterests.length > 0) {
            requestBody.areaOfInterests = adminFilters.areaOfInterests;
        }

        if (adminFilters.followersFrom != null && adminFilters.followersFrom !== "") {
            requestBody.followersFrom = adminFilters.followersFrom;
        }

        if (adminFilters.followersTo != null && adminFilters.followersTo !== "") {
            requestBody.followersTo = adminFilters.followersTo;
        }

        if (adminFilters.fromDate != null && adminFilters.fromDate !== "") {
            requestBody.fromDate = adminFilters.fromDate;
        }

        if (adminFilters.toDate != null && adminFilters.toDate !== "") {
            requestBody.toDate = adminFilters.toDate;
        }

        const response = await fetch(`${baseUrl}/admin/get-users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (response.ok) {
            if (data.message === "No filtered data found.") {
                setUserList([]);
                setTotalCount(0);
            } else {
                setUserList(data.users);
                setTotalCount(data.totalCount);
            }
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error("Error fetching user list:", data.message);
        }
    } catch (error) {
        console.error("Error fetching user list:", error);
    } finally {
        setIsLoading(false);
        setApiFailed(true);
    }
};

export const fetchEnterpriseClients = async (setIsLoading, setApiFailed, currentPage, recordsPerPage, setUserList, setTotalCount, adminFilters,
    searchString, privateInstaAccounts, incompleteRegistration) => {
    setIsLoading(true);
    setApiFailed(false);
    try {
        let requestBody = {
            pageNo: currentPage,
            pageSize: recordsPerPage,
        };

        if (privateInstaAccounts != null) {
            requestBody.privateInstaAccounts = privateInstaAccounts;
        }

        if (incompleteRegistration != null) {
            requestBody.incompleteRegistration = incompleteRegistration;
        }

        if (searchString != null && searchString !== "") {
            requestBody.searchString = searchString;
        }

        if (adminFilters.state.length > 0) {
            requestBody.state = adminFilters.state;
        }

        if (adminFilters.city.length > 0) {
            requestBody.city = adminFilters.city;
        }

        if (adminFilters.area.length > 0) {
            requestBody.area = adminFilters.area;
        }
        
        if (adminFilters.areaOfInterests.length > 0) {
            requestBody.areaOfInterests = adminFilters.areaOfInterests;
        }

        if (adminFilters.followersFrom != null && adminFilters.followersFrom !== "") {
            requestBody.followersFrom = adminFilters.followersFrom;
        }

        if (adminFilters.followersTo != null && adminFilters.followersTo !== "") {
            requestBody.followersTo = adminFilters.followersTo;
        }

        if (adminFilters.fromDate != null && adminFilters.fromDate !== "") {
            requestBody.fromDate = adminFilters.fromDate;
        }

        if (adminFilters.toDate != null && adminFilters.toDate !== "") {
            requestBody.toDate = adminFilters.toDate;
        }

        const response = await fetch(
            `${baseUrl}/admin/get-enterprise-clients`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify(requestBody),

            });

        const data = await response.json();
        if (response.ok) {
            if (data.message === "No filtered data found.") {
                setUserList([]);
                setTotalCount(0);
            } else {
                setUserList(data.users||[]);
                setTotalCount(data.totalCount||0);
            }
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error(
                "Error fetching enterprise client:",
                response.message
            );
        }
    } catch (error) {
        console.error("Error fetching enterprise client:", error);
    } finally {
        setIsLoading(false);
    }
};

export const downloadUserDetailsAsCSV = async (setCsvLoading, adminFilters, searchString, privateInstaAccounts, incompleteRegistration) => {
    setCsvLoading(true);
    try {
        let requestBody = {};

        if (privateInstaAccounts != null) {
            requestBody.privateInstaAccounts = privateInstaAccounts;
        }

        if (incompleteRegistration != null) {
            requestBody.incompleteRegistration = incompleteRegistration;
        }

        if (searchString != null && searchString !== "") {
            requestBody.searchString = searchString;
        }


        if (adminFilters.state.length > 0) {
            requestBody.state = adminFilters.state;
        }
        if (adminFilters.city.length > 0) {
            requestBody.city = adminFilters.city;
        }
        if (adminFilters.area.length > 0) {
            requestBody.area = adminFilters.area;
        }

        if (adminFilters.areaOfInterests.length > 0) {
            requestBody.areaOfInterests = adminFilters.areaOfInterests;
        }

        if (adminFilters.followersFrom != null && adminFilters.followersFrom !== "") {
            requestBody.followersFrom = adminFilters.followersFrom;
        }

        if (adminFilters.followersTo != null && adminFilters.followersTo !== "") {
            requestBody.followersTo = adminFilters.followersTo;
        }

        if (adminFilters.fromDate != null && adminFilters.fromDate !== "") {
            requestBody.fromDate = adminFilters.fromDate;
        }

        if (adminFilters.toDate != null && adminFilters.toDate !== "") {
            requestBody.toDate = adminFilters.toDate;
        }

        const response = await fetch(`${baseUrl}/admin/users/download`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "userdetails.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading user details as CSV:', error);
    } finally {
        setCsvLoading(false);
    }
};
