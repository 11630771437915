import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import "../../../css/landing-page.css";

function PrivacyPolicy() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ p: 6 }}>
        <h1 className='policy-header'>Oye Creators Privacy and Cookies Policy</h1>
        <Typography>
          This Privacy and Cookies Policy ("Policy") outlines Oye Creators', a property of TTL MEDIA PVT. LTD., commitment to safeguarding your personal data and respecting your privacy. This Policy applies to our customers, subscribers, website visitors, and applicants (hereafter referred to as "you", "your").
        </Typography>

        <h2 className='policy-header'>Who We Are</h2>
        <Typography>
          Oye Creators, a digital platform by TTL MEDIA PVT. LTD., provides various services aimed at enhancing digital content creation and distribution. Our registered office is in 601/603, Plot, No-32/28, Corner Stone Premises, 6th Floor, Office, Prabhat Rd Pune, Deccan Gymkhana, Ghymkhana, Maharashtra 411004, India. For the purpose of this Policy and in line with the Indian Personal Data Protection Bill (PDPB), we act as the data controller of your personal data.
        </Typography>

        <h2 className='policy-header'>Personal Data We Collect</h2>
        <Typography>
          We collect personal data to provide and optimize our services, ensure website functionality, comply with legal obligations, and for marketing. The data we collect includes:
          <br />
          <ul>
            <li>
              <strong className='policy-header'>Account Data: </strong>
              <span>Includes name, email address, company details, and service usage information.</span>
            </li>
            <li>
              <strong className='policy-header'>Activity Data: </strong>
              <span>Usage details such as website navigation paths, interaction data, and service preferences.</span>
            </li>
            <li>
              <strong className='policy-header'>Communication Data: </strong>
              <span>Information from emails and other communications with us.</span>
            </li>
            <li>
              <strong className='policy-header'>Cookie Data: </strong>
              <span>Data from cookies and similar technologies on our devices.</span>
            </li>
          </ul>
          <em><strong className='policy-header'>Note:</strong></em> Provision of personal data is voluntary, but required for specific services.
        </Typography>

        <h2 className='policy-header'>Use of Cookies</h2>
        <Typography>
          Our website uses cookies to improve user experience and service functionality. You have control over cookie settings through your browser, but restricting them may affect service use.
        </Typography>

        <h2 className='policy-header'>How We Use Your Personal Data</h2>
        <Typography>
          Your data is used strictly for delivering and improving our services, customer support, complying with legal requirements, and for marketing purposes, based on your consent or our legitimate business interests.
        </Typography>

        <h2 className='policy-header'>Data Sharing and Transfer</h2>
        <Typography>
          We share your data with third-party service providers for service optimization, legal compliance, or when you have given explicit consent. We ensure these providers adhere to strict data protection standards.
        </Typography>

        <h2 className='policy-header'>Data Security and Retention</h2>
        <Typography>
          We employ technical and organizational measures to protect your data against unauthorized access, loss, or misuse. Data is retained as necessary for service provision, legal compliance, or as specified in our data retention policy.
        </Typography>

        <h2 className='policy-header'>Your Rights</h2>
        <Typography>
          Under the PDPB, you have rights to access, correct, delete, or restrict processing of your data. You can withdraw consent at any time for future processing.
        </Typography>

        <h2 className='policy-header'>Changes to This Policy</h2>
        <Typography>
          We may update this Policy periodically. We encourage you to review this page regularly for any changes.
        </Typography>

        <h2 className='policy-header'>Contacting Us</h2>
        <Typography>
          For any questions regarding this Policy or your personal data, please contact us at <a href=" mailto:Support@ttlmedia.in " >support@ttlmedia.in</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
