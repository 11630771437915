import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import '../../css/profile-popup.css'
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import PhotoIcon from '@mui/icons-material/Photo';
import { getConfig } from '../common/env-config';
import { getCreatorInsights, getUserInsights } from '../common/user-details-repo';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

function ProfileDialog(props) {
    const [userInsights, setUserInsights] = useState({
        userName: '', fullName: '', followersCount: '', mediaCount: '', engagement: '',
        engagementRate: '', category: '', profilePictureId: '', bio: '', location: '',
        avgLike: '', avgComment: ''
    });
    const [loading, setLoading] = useState(true);
    const [scroll] = useState('paper');
    const { baseUrl } = getConfig();
    const location = useLocation();

    const enterpriseClient = location.state?.enterpriseClient;

    const handleClose = () => {
        props.onClose();
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            const roundedNumber = Math.round(num * 100) / 100;
            if (Number.isInteger(roundedNumber)) {
                return roundedNumber.toString();
            } else {
                return roundedNumber.toFixed(2);
            }
        }
    };
    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.openProfile) {
                    const { current: descriptionElement } = descriptionElementRef;
                    if (descriptionElement !== null) {
                        descriptionElement.focus();
                    }
                    if (props.isDiscovery) {
                        getCreatorInsights(props.userId, setUserInsights, setLoading);
                    } else {
                        getUserInsights(props.userId, setUserInsights, setLoading, enterpriseClient);
                    }
                }
            } catch (error) {
                console.error("Error occurred while fetching user insights:", error);
            }
        };

        fetchData();
    }, [props.openProfile, props.userId, enterpriseClient, props.isDiscovery]);

    return (
        <React.Fragment>
            <Dialog
                open={props.openProfile}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{ sx: { width: '100%', position: 'absolute', right: 0, height: "100%", margin: 0, maxHeight: 1, borderRadius: 0 } }}
            >
                <DialogContent>
                    {loading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 9999
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    <div className="profile-container">
                        <div className='closeicon'>
                            <div className='close' onClick={handleClose}>
                                <CloseIcon />
                            </div>
                        </div>
                        <div>
                            {userInsights.followersCount === '' && !loading
                                ? <Alert severity="info">
                                    This account is private, so we don't have enough information.
                                </Alert>
                                : null
                            }
                        </div>
                        <div className="profile-popup">
                            <div className="_imgBlock_1ig5e_57">
                                <div className="_img_19x11_1 user-profile" style={{
                                    backgroundImage: `url(${userInsights.profilePictureId
                                        ? `${baseUrl}/download-insta-profile/${userInsights.profilePictureId}`
                                        : <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="_verified_dbbth_91">
                                            <path d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z" fill="#4AABED">
                                            </path>
                                        </svg>
                                        })`
                                }}
                                >
                                </div>
                            </div>
                            <div>
                                <div className="user-fullname">{userInsights.instaFullName}</div>
                                <a className="insta-handle" href={`https://www.instagram.com/${userInsights.instaHandle !== '' ? userInsights.instaHandle : props.instaHandle}`} rel="noopener noreferrer" target="_blank" >
                                    <div>@{userInsights.instaHandle !== '' ? userInsights.instaHandle : props.instaHandle}</div></a>

                                <div className="insta-icon">
                                    <a className="insta-icon-color" rel="noopener noreferrer" target="_blank" href={`https://www.instagram.com/${userInsights.instaHandle !== '' ? userInsights.instaHandle : props.instaHandle}`} >
                                        <div className='insta-icon-color'> <InstagramIcon /></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="followers-border followers-margin-top">
                            <div className="followers-grid">
                                <div>
                                    <div className="grid-content">Followers</div>
                                    <div className="profile-count">{userInsights.followersCount !== '' ? formatNumber(userInsights.followersCount) : '-'}</div>
                                </div>
                                <div>
                                    <div className="grid-content"> ER%
                                        <div className="info-icon-badge">i
                                            <div className="tooltip-badge">Indicates how engaged the creator's audience is. Engagement Rate is the ratio of likes divided by followers</div>
                                        </div>
                                    </div>
                                    <div className="profile-count">{userInsights.engagementRate !== '' ? parseFloat(userInsights.engagementRate).toFixed(2) : 0}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="Creator-performance">Creator performance</div>
                        <div className="_dynamicGrid_l3dni_44">
                            <div className="_blockBase_1d803_5 _block_nmn03_2">
                                <div className="media-icon-color">
                                    <FavoriteIcon />
                                </div>
                                <div className="_value_nmn03_16"> {userInsights.avgLike !== '' ? formatNumber(userInsights.avgLike) : 0} </div>
                                <div className="_title_nmn03_5 _row_nmn03_12">
                                    Avg. Likes
                                    <div className="_root_1kdtt_1 _tooltip_l3dni_36 has-tooltip" data-original-title="null">
                                        <div className="info-icon-badge">i
                                            <div className="tooltip-badge">Indicates how engaged the creator's audience is. Engagement Rate is the ratio of likes divided by latest post</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="_blockBase_1d803_5 _block_nmn03_2">
                                <div className='media-icon-color'>
                                    <CommentIcon />
                                </div>
                                <div className="_value_nmn03_16"> {userInsights.avgComment !== '' ? formatNumber(userInsights.avgComment) : 0} </div>
                                <div className="_title_nmn03_5 _row_nmn03_12"> Avg. Comments
                                    <div className="_root_1kdtt_1 _tooltip_l3dni_36 has-tooltip" data-original-title="null">
                                        <div className="info-icon-badge">i
                                            <div className="tooltip-badge">Indicates how engaged the creator's audience is. Engagement Rate is the ratio of comments divided by latest post</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="_blockBase_1d803_5 _block_nmn03_2">
                                <div className='media-icon-color'>
                                    <PhotoIcon />
                                </div>
                                <div className="_value_nmn03_16"> {userInsights.mediaCount !== '' ? formatNumber(userInsights.mediaCount) : 0} </div>
                                <div className="_title_nmn03_5 _row_nmn03_12"> Total posts
                                    <div className="_root_1kdtt_1 _tooltip_l3dni_36 has-tooltip" data-original-title="null">
                                        <div className="info-icon-badge">i
                                            <div className="tooltip-badge">The total number of posts an creator has published.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {enterpriseClient &&
                            <>
                                <div className="Creator-performance">Business Details</div><div className="_blockBase_1d803_5 _block_mbftb_1 _boxPadding_l3dni_201">
                                    <div className='business-details-content'>
                                        <div className="profile-title">Business Name : </div>
                                        <div style={{ width: '40ch' }} > {userInsights.bussinessName} </div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title">Location: </div>
                                        <div style={{ width: '35ch' }} >{userInsights.billingLocation}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Legal Business Name :</div>
                                        <div style={{ width: '30ch' }}>{userInsights.legalBusinessName}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Pan Number :</div>
                                        <div style={{ width: '40ch' }}>{userInsights.panNumber}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Gstin :</div>
                                        <div style={{ width: '40ch' }} >{userInsights.gstin}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Registered Billing Address :</div>
                                        <div style={{ width: '30ch' }}>{userInsights.registeredBillingAddress}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Billing Email Address :</div>
                                        <div style={{ width: '30ch' }} >{userInsights.billingEmailAddress}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Additional Remarks :</div>
                                        <div style={{ width: '35ch' }}>{userInsights.additionalRemarks}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Zip Code :</div>
                                        <div style={{ width: '40ch' }}>{userInsights.zipCode}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Salesperson Name :</div>
                                        <div style={{ width: '30ch' }}>{userInsights.salespersonName}</div>
                                    </div>
                                    <div className='business-details-content'>
                                        <div className="profile-title"> Country : </div>
                                        <div style={{ width: '40ch' }}>{userInsights.country}</div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="_blockBase_1d803_5 _block_mbftb_1 _boxPadding_l3dni_201">
                            <div className="profile-title"> Bio </div>
                            <div >{userInsights.bio}</div>
                        </div>
                        <div className="_blockBase_1d803_5 _block_1xotd_1 _boxPadding_l3dni_201">
                            <div className="_rowFlex_1d803_1">
                                Category
                                <div className="_root_1kdtt_1 _tooltip_l3dni_36 has-tooltip" data-original-title="null">
                                    <div className="info-icon-badge">i
                                        <div className="tooltip-badge">Creator category</div>
                                    </div>
                                </div>
                            </div>
                            <div className="_row_1xotd_10">
                                <div title="Friends, Family &amp; Relationships" className="_labelText_1xotd_24"> {userInsights.category} </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </React.Fragment >
    );
}

export default ProfileDialog;
