import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import PropTypes from 'prop-types';
import { alertMessageStyle } from '../../mui/mui-styling';

function Alert ({ open, severity, message, onClose }) {
  return (
    <Snackbar
      sx={alertMessageStyle}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent
        sx={{
          backgroundColor: severity === 'error' ? '#FFCDD2' : '#C8E6C9',
          color: '#333',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '15em'
        }}
        message={message}
      />
    </Snackbar>
  );
}

Alert.propTypes = {
  open: PropTypes.any,
  severity: PropTypes.any,
  message: PropTypes.any,
  onClose: PropTypes.any
};

export default Alert;
