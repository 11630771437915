import React, { useEffect, useState, useContext, useCallback } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import '../../../css/package-offer.css';
import { getPackages } from '../ec-leads-helper';
import CloseIcon from '@mui/icons-material/Close';
import { WindowWidthContext } from "../../context/user-details-context";

export const PackageOfferDialog = ({ fromPage, open, onClose, handleLeadChange, packageOfferDetails }) => {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [packages, setPackages] = useState([]);
    const [fieldValueChanged, setFieldValueChange] = useState(false);
    const windowWidth = useContext(WindowWidthContext);

    const mergePackages = useCallback((fetchedPackages) => {
        setPackages(fetchedPackages.map(individualPackage => {
            if (packageOfferDetails && individualPackage.packageId === packageOfferDetails.packageId) {
                return {
                    ...individualPackage,
                    selected: true,
                    productTypes: individualPackage.productTypes.map(individualProductType => {
                        if (individualProductType.productTypeId === packageOfferDetails.productTypes[0].productTypeId) {
                            return {
                                ...individualProductType,
                                selected: true,
                                products: individualProductType.products.map(individualProduct => {
                                    if (individualProduct.productId === packageOfferDetails.productTypes[0].products[0].productId) {
                                        return {
                                            ...individualProduct,
                                            selected: true,
                                            priceOffered: packageOfferDetails.productTypes[0].products[0].priceOffered
                                        }
                                    } else {
                                        return {
                                            ...individualProduct,
                                            selected: false,
                                            priceOffered: null
                                        }
                                    }
                                })
                            }
                        } else {
                            return individualProductType;
                        }
                    })
                }
            } else {
                return individualPackage;
            }
        }));
    }, [packageOfferDetails]);

    useEffect(() => {
        if (open) {
            getPackages(mergePackages);
        }
    }, [open, mergePackages]);

    const atleastOnePackageSelected = () => packages.find(item => item.selected) !== undefined;

    const atleastOneProductTypeSelected = () => packages.find(item => item.selected).productTypes.find(item => item.selected) !== undefined;

    const oneProductSelectedPerSelectedProductType = () => packages.find(item => item.selected).productTypes.filter(item => item.selected).every(item => item.products.find(item => item.selected) !== undefined)

    const everySelectedProductHasCorrectOfferedPrice = () => packages.find(item => item.selected).productTypes.filter(item => item.selected).every(item => item.products.find(item => item.priceOffered !== null && item.priceOffered !== undefined && item.price > item.priceOffered) !== undefined)

    const isPackageComplete = () => {
        return atleastOnePackageSelected() && atleastOneProductTypeSelected()
            && oneProductSelectedPerSelectedProductType()
            && everySelectedProductHasCorrectOfferedPrice();
    };

    const handlePackageOfferChange = (fieldName, value) => {
        setFieldValueChange(true);

        setPackages(packages.map(individualPackage => {
            if (fieldName === 'packageId' && individualPackage.packageId === value) {
                return {
                    ...individualPackage,
                    selected: true
                }
            } else {
                return {
                    ...individualPackage,
                    selected: false
                }
            }
        }));

        setIsFormDirty(true);
    };

    const handleProductTypeChange = (productTypeId, productId, priceOffered) => {
        setFieldValueChange(true);
        setPackages(packages.map(individualPackage => {
            if (individualPackage.selected) {
                return {
                    ...individualPackage,
                    productTypes: individualPackage.productTypes.map(individualProductType => {
                        if (individualProductType.productTypeId === productTypeId) {
                            return {
                                ...individualProductType,
                                selected: true,
                                products: individualProductType.products.map(individualProduct => {
                                    if (individualProduct.productId === productId) {
                                        return {
                                            ...individualProduct,
                                            selected: true,
                                            priceOffered: priceOffered
                                        }
                                    } else {
                                        return {
                                            ...individualProduct,
                                            selected: false,
                                            priceOffered: null
                                        }
                                    }
                                })
                            }
                        } else {
                            return individualProductType;
                        }
                    })
                }
            } else {
                return individualPackage;
            }
        }));
    };

    const handleClear = () => {
        handleLeadChange('selectedPackage', {});
    };

    const handleSaveOffer = () => {
        let selectedPackage = packages.find(item => item.selected);
        selectedPackage = {
            ...selectedPackage,
            productTypes: selectedPackage.productTypes.filter(item => item.selected)
        };

        selectedPackage.productTypes = selectedPackage.productTypes.map(item => {
            return {
                ...item,
                products: item.products.filter(item => item.selected)
            }
        });

        handleLeadChange('selectedPackage', selectedPackage);
        setFieldValueChange(false)
        onClose();
    };

    useEffect(() => {
        if (isFormDirty) {
            const handleBeforeUnload = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    e.returnValue = message;
                    return message;
                }
            };

            const handleNavClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                        if (packageOfferDetails == null || packageOfferDetails.packageId == null) {
                            handleClear();
                        }
                        //setPrevValue(false);
                    }
                }
            };

            const handleClearClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to clear?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            const handlePopState = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    const userConfirmed = window.confirm(message);
                    if (!userConfirmed) {
                        window.history.pushState(null, '', window.location.pathname);
                    } else {
                        setIsFormDirty(false);
                        if (packageOfferDetails !== null && packageOfferDetails.packageId !== null) {
                            handleClear();
                        }
                        //setPrevValue(false);
                    }
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick);
            });

            const clearClick = document.querySelectorAll('.clear-button');
            clearClick.forEach((link) => {
                link.addEventListener('click', handleClearClick);
            });

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
                navLinks.forEach((link) => {
                    link.removeEventListener('click', handleNavClick);
                });
                clearClick.forEach((link) => {
                    link.removeEventListener('click', handleClearClick);
                });
            };
        }
        // eslint-disable-next-line
    }, [isFormDirty]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{packageOfferDetails && packageOfferDetails.packageId !== null
                ? 'Edit Package Offer' : 'Package Offer'}</DialogTitle>
            <IconButton aria-label="close" onClick={onClose} className="nav-link"
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: '16px 24px' }}>
                <Typography gutterBottom sx={{ paddingBottom: '2px', fontFamily: 'DM Sans', fontSize: '1rem', color: '#000000', fontWeight: 500 }}>Package Details</Typography>
                <div className='package-row'>
                    <div className='package-col'>
                        <FormControl sx={{ width: windowWidth <= 799 ? '100%' : '550px' }}>
                            <InputLabel id="package-type-select-label">Package Type</InputLabel>
                            <Select labelId="package-type-select-label" id="package-type-select"
                                label="Package Type"
                                value={packages.find(individualPackage => individualPackage.selected) && packages.find(individualPackage => individualPackage.selected).packageId}
                                onChange={(e) => handlePackageOfferChange('packageId', e.target.value)}
                            >
                                {packages.map((pkg) => (
                                    <MenuItem key={pkg.packageId} value={pkg.packageId}>{pkg.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {packages.find(individualPackage => individualPackage.selected) && packages.find(individualPackage => individualPackage.selected).productTypes && packages.find(individualPackage => individualPackage.selected).productTypes.map((productType) => (
                        <div key={productType.productTypeId} className="products">
                            <div>{productType.name}</div>
                            <div className="product-section">
                                <FormControl style={{ width: '382px', marginBottom: '12px' }} >
                                    <InputLabel id={`product-select-label-${productType.productTypeId}`}>Product</InputLabel>
                                    <Select labelId={`product-select-label-${productType.productTypeId}`}
                                        id={`product-select-${productType.productTypeId}`}
                                        label="Product" name="product"
                                        value={productType.products.find(item => item.selected) ? productType.products.find(item => item.selected).productId : 'PleaseSelect'}
                                        onChange={(e) => handleProductTypeChange(productType.productTypeId, e.target.value, null)}
                                    >
                                        <MenuItem value='PleaseSelect'>Please Select</MenuItem>
                                        {productType.products.map((product) => (
                                            <MenuItem key={product.productId} value={product.productId}>{product.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    id={`outlined-required-price-${productType.productTypeId}`}
                                    label={productType.selected && productType.products.find(item => item.selected)?.price ? "" : <span>Price</span>}
                                    name="price"
                                    value={productType.selected && (productType.products.find(item => item.selected)?.price || "")}
                                    inputProps={{ maxLength: 50, readOnly: true }}
                                    disabled
                                />
                                <TextField
                                    id={`outlined-required-price-offered-${productType.productTypeId}`}
                                    label={<span>Price Offered
                                        {productType.selected &&
                                            productType.products.find(
                                                item => item.selected
                                            ) && <span style={{ color: 'red' }}>*</span>}
                                    </span>}
                                    name="priceoffered"
                                    value={productType.products.find(item => item.selected) && productType.products.find(item => item.selected).priceOffered}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    onChange={(e) => handleProductTypeChange(productType.productTypeId, productType.products.find(item => item.selected).productId, e.target.value)}
                                    disabled={productType.products.filter(item => item.selected).length === 0}
                                    error={productType.products.find(item => item.selected) && productType.products.find(item => item.selected).priceOffered > productType.products.find(item => item.selected).price}
                                    helperText={productType.products.find(item => item.selected) && productType.products.find(item => item.selected).priceOffered > productType.products.find(item => item.selected).price ? 'Price Offered should be lesser than product price' : ''}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px' }}>
                {fromPage === 'Edit Lead'
                    ? <Button className="nav-link" variant="outlined" onClick={onClose}
                        sx={{ color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' } }}>Cancel</Button>
                    : <Button className="clear-button" variant="outlined" onClick={handleClear}
                        sx={{ color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' } }}>Clear</Button>
                }
                <Button variant="contained" sx={{
                    backgroundColor: '#e563a4', textTransform: 'capitalize', '&:hover': { backgroundColor: '#e326a4' }
                }} disabled={!fieldValueChanged || !isPackageComplete()} onClick={handleSaveOffer}>Save Offer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PackageOfferDialog;
