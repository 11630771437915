import React, { useContext, useEffect, useState } from 'react'
import { Badge, Box, Checkbox, Chip, CircularProgress, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ECLeadsToolbar from './ecleads-table-toolbar';
import ECLeadsTableHead from './ecleads-table-head';
import CommonPagination from '../../common/common-pagination';
import { getECInternalStatusStyles, StyledTableRow } from '../../../mui/mui-styling';
import { UserDetailsContext, WindowWidthContext } from '../../context/user-details-context';
import { getECLeads, toTitleCase } from '../ec-leads-helper';
import { useNavigate } from 'react-router-dom';
import { useLeadFilters } from '../../context/eclead-filter-context';
import CommentIcon from '@mui/icons-material/Comment';
import ECCommentDialog from './ec-comments';

function createData(leadId, company, type, fullName, leadLocation, status, statusFrom, statusFromDays, proposal, probabilityStatus, contactMobile, contactEmail, source, agent, agentId, notes) {
    return { leadId, company, type, fullName, leadLocation, status, statusFrom, statusFromDays, proposal, probabilityStatus, contactMobile, contactEmail, source, agent, agentId, notes };
}
function ECLeadsManagement() {
    const { loggedUser } = useContext(UserDetailsContext);
    const navigate = useNavigate();
    const windowWidth = useContext(WindowWidthContext);
    const { leadFilters } = useLeadFilters();
    const [rowsPerPage, setRowPerPage] = useState(25);
    const [ecLeads, setECLeads] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalECLeads, setTotalECLeads] = useState();
    const [apiFailed, setApiFailed] = useState(false);
    const [searchString, setSearchString] = useState(leadFilters.searchString != null && leadFilters.searchString !== "" && leadFilters.filterApply ? leadFilters.searchString : null);
    const [searchType, setSearchType] = useState(leadFilters.searchType != null && leadFilters.searchType !== "" && leadFilters.filterApply ? leadFilters.searchType : null);
    const [leadStatus, setLeadStatus] = useState(leadFilters.leadStatus != null && leadFilters.leadStatus !== "" && leadFilters.filterApply ? leadFilters.leadStatus : null);
    const [leadSource, setLeadSource] = useState(leadFilters.leadSource != null && leadFilters.leadSource !== "" && leadFilters.filterApply ? leadFilters.leadSource : null);
    const [leadType, setLeadType] = useState(leadFilters.leadType != null && leadFilters.leadType !== "" && leadFilters.filterApply ? leadFilters.leadType : null);
    const [proposalSent, setProposalSent] = useState(leadFilters.proposalSent != null && leadFilters.proposalSent !== "" && leadFilters.filterApply ? leadFilters.proposalSent : "");
    const [statusFrom, setStatusFrom] = useState(leadFilters.statusFrom != null && leadFilters.statusFrom !== "" && leadFilters.filterApply ? leadFilters.statusFrom : null);
    const [location, setLocation] = useState(leadFilters.location != null && leadFilters.location !== "" && leadFilters.filterApply ? leadFilters.location : null);
    const [probability, setProbability] = useState(leadFilters.probability != null && leadFilters.probability !== "" && leadFilters.filterApply ? leadFilters.probability : null);
    const [agent, setAgent] = useState(leadFilters.agent != null && leadFilters.agent !== "" && leadFilters.filterApply ? leadFilters.agent : null);
    const [goToPage, setGoToPage] = useState(1);
    const [filterApply, setFilterApply] = useState(leadFilters.filterApply ? leadFilters.filterApply : false);
    const [selectedECLeads, setSelectedECLead] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    useEffect(() => {
        getECLeads(createData, setLoading, currentPage, rowsPerPage, setTotalECLeads, setECLeads, setApiFailed,
            searchString, searchType, leadStatus, leadSource, leadType, proposalSent, statusFrom, location, probability, agent, leadFilters.sortingType, leadFilters.sortBy);
    }, [currentPage, rowsPerPage, filterApply, searchString, searchType, leadStatus, leadSource, leadType, proposalSent, statusFrom, location, probability, agent, leadFilters.sortingType, leadFilters.sortBy]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setGoToPage(value);
        setSelectedECLead([]);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setCurrentPage(goToPage === '' ? 1 : goToPage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && e.target.value.length === 1) {
            setGoToPage(1);
            setCurrentPage(1);
        }
    };

    const handleBlur = () => {
        if (goToPage === '') {
            setGoToPage(1);
            setCurrentPage(1);
        }
    };

    const handleRowPerPageChange = (value) => {
        setRowPerPage(value);
        setGoToPage(1);
        setCurrentPage(1);
        setSelectedECLead([]);
    }

    const editECLead = (lead) => {
        navigate("/enterprise-client/create-internal-ec-lead", { state: { ecLeadId: lead.leadId, fromPage: 'Dashboard' } });
    }

    const viewECLead = (lead) => {
        navigate("/enterprise-client/view-internal-ec-lead", { state: { ecLeadId: lead.leadId } });
    }

    const handleSelectAllClick = (event) => {
        if (selectedECLeads.length === ecLeads.length) {
            setSelectedECLead([]);
        } else {
            const newSelected = ecLeads.map((lead) => lead.leadId);
            setSelectedECLead(newSelected);
        }
    };

    const handleClick = (event, leadId) => {
        event.stopPropagation();
        setSelectedECLead((prevSelected) => {
            if (prevSelected.includes(leadId)) {
                return prevSelected.filter((selectedId) => selectedId !== leadId);
            } else {
                return [...prevSelected, leadId];
            }
        });
    };

    const StatusChip = ({ row }) => {
        return <Chip label={row.status.status} sx={getECInternalStatusStyles(row.status.status)} />
    };

    const openCommentDialog = (lead) => {
        setSelectedLead(lead);
        setOpenDialog(true);
    };

    const closeCommentDialog = () => {
        setOpenDialog(false);
        setSelectedLead(null);
    };

    return (
        <div>
            <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
                <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: '0px 20px 20px 20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <ECLeadsToolbar windowWidth={windowWidth} setCurrentPage={setCurrentPage} setGoToPage={setGoToPage}
                        loading={loading} apiFailed={loading} filterApply={filterApply} setFilterApply={setFilterApply}
                        setSearchString={setSearchString} setSearchType={setSearchType} setLeadStatus={setLeadStatus}
                        setLeadSource={setLeadSource} setLeadType={setLeadType} setProposalSent={setProposalSent}
                        setStatusFrom={setStatusFrom} setLocation={setLocation} setProbability={setProbability} setAgent={setAgent} setSelectedECLead={setSelectedECLead} selectedECLeads={selectedECLeads} />
                    <TableContainer sx={{ padding: '15px 15px 25px 15px' }}>
                        <Table aria-labelledby="tableTitle">
                            <ECLeadsTableHead selectedECLead={selectedECLeads} setSelectedECLead={setSelectedECLead} handleSelectAllClick={handleSelectAllClick}
                                setCurrentPage={setCurrentPage} setGoToPage={setGoToPage} pageSize={rowsPerPage} totalECLeads={totalECLeads} currentPage={currentPage} />
                            <TableBody>
                                {
                                    loading ?
                                        <TableRow>
                                            <TableCell align="center" colSpan={11} style={{ borderBottom: 'none', padding: 0 }}>
                                                <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {loading ? <CircularProgress /> : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        apiFailed
                                            ? (
                                                <TableRow>
                                                    <TableCell align="center" colSpan={11} style={{ borderBottom: 'none', padding: 0 }}>
                                                        <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {apiFailed && !loading ? <div className="nodata-found">No EC Leads Found</div> : null}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : (ecLeads.map((row, index) => {
                                                const isItemSelected = selectedECLeads.includes(row.leadId);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <StyledTableRow className='top-campaign-row'
                                                        tabIndex={-1} key={row.leadId} sx={{ cursor: 'pointer' }}
                                                    >
                                                        {loggedUser && loggedUser.access.includes('Lead:Assign') &&
                                                            <TableCell padding="checkbox">
                                                                <Checkbox color="secondary" inputProps={{ 'aria-labelledby': labelId }}
                                                                    checked={isItemSelected} onClick={(event) => handleClick(event, row.leadId)} />
                                                            </TableCell>
                                                        }
                                                        <TableCell sx={{ maxWidth: 200 }} align="center">
                                                            <>
                                                                <Tooltip title={toTitleCase(row.fullName)} arrow>
                                                                    {toTitleCase(row.fullName)}
                                                                </Tooltip> <br />
                                                                <Tooltip title={row.company} arrow>
                                                                    {`(${row.company})`}
                                                                </Tooltip>
                                                            </>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 100 }} align="center">
                                                            <Tooltip title={row.type.leadType} arrow>
                                                                {row.type.leadType}
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 190 }} align="center">
                                                            <Tooltip title={row.leadLocation} arrow>
                                                                {row.leadLocation != null && (
                                                                    <>
                                                                        {row.leadLocation.includes(' ') ? (
                                                                            <>
                                                                                {row.leadLocation.split(' ')[0]} <br />
                                                                                {row.leadLocation.substring(row.leadLocation.indexOf(' ') + 1)}
                                                                            </>
                                                                        ) : (
                                                                            row.leadLocation
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 180 }} align="center">
                                                            <StatusChip row={row} />
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 180 }} align="center">
                                                            {row.statusFrom != null &&
                                                                <Tooltip title={row.statusFrom} arrow>
                                                                    {row.statusFrom}<br />
                                                                    {row.statusFromDays}
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 50 }} align="center">
                                                            {row.proposal &&
                                                                <Tooltip title='Sent' arrow>
                                                                    Sent
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 180 }} align="center">
                                                            <Tooltip title={row.probabilityStatus.probabilityStatus} arrow>
                                                                {row.probabilityStatus.probabilityStatus}
                                                            </Tooltip>
                                                        </TableCell>
                                                        {loggedUser && loggedUser.role !== 'LEAD_AGENT' &&
                                                            <TableCell sx={{ maxWidth: 180 }} align="center">
                                                                <Tooltip title={row.agent != null
                                                                    ? toTitleCase(row.agent.firstName + " " + row.agent.lastName)
                                                                    : null
                                                                } arrow>
                                                                    {row.agent != null
                                                                        ? toTitleCase(row.agent.firstName + " " + row.agent.lastName)
                                                                        : null}
                                                                </Tooltip>
                                                            </TableCell>
                                                        }
                                                        <TableCell sx={{ maxWidth: 180 }} align="center">
                                                            <div className='action-icons'>
                                                                {loggedUser && loggedUser.access.includes('Lead:Edit') && row.status.status !== 'Won' && loggedUser.userId === row.agent.userId &&
                                                                    <Tooltip title="Edit Lead" arrow>
                                                                        <IconButton onClick={() => editECLead(row)}>
                                                                            <BorderColorIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip title="View Lead Details" arrow>
                                                                    <IconButton onClick={() => viewECLead(row)}>
                                                                        <VisibilityIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <div className='comment-icon'>
                                                                    <Tooltip title="View Comments" arrow>
                                                                        <Badge onClick={() => openCommentDialog(row)}
                                                                            color="secondary"
                                                                            badgeContent={Array.isArray(row.notes) ? row.notes.length : 0}
                                                                            showZero
                                                                            sx={{ cursor: Array.isArray(row.notes) && row.notes.length > 0 ? 'pointer' : 'context-menu' }}
                                                                        >
                                                                            <CommentIcon />
                                                                        </Badge>
                                                                    </Tooltip>
                                                                </div>

                                                            </div>

                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            }))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={windowWidth <= 799 ? 'leads-table-mobile' : 'leads-table-footer'}>
                        <div className='leads-table-footer'>
                            <div>
                                Showing {apiFailed || loading ? 0 : ((currentPage - 1) * rowsPerPage) + 1} - {apiFailed || loading ? 0 : Math.min(currentPage * rowsPerPage, totalECLeads)} of {apiFailed || loading ? 0 : totalECLeads}
                            </div>
                            <div className={windowWidth <= 799 ? 'go-to-page-mobile' : 'go-to-page'}>
                                Go to page :
                                <TextField type="number" id="outlined-size-normal" defaultValue="1" style={{ width: 'fit-content', backgroundColor: '#fff' }}
                                    variant="outlined" value={goToPage} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}
                                    onBlur={handleBlur} onChange={(e) => {
                                        let value = parseInt(e.target.value, 10);
                                        if (isNaN(value)) {
                                            setGoToPage('');
                                        } else if (value < 1 || value > Math.ceil(totalECLeads / rowsPerPage)) {
                                            setGoToPage('');
                                        } else {
                                            setGoToPage(value);
                                        }
                                    }}
                                    inputProps={{ min: 1, max: apiFailed || loading ? 1 : Math.ceil(totalECLeads / rowsPerPage) }} />
                            </div>
                        </div>
                        <div className='leads-table-footer'>
                            <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', columnGap: '13px' }}>
                                <div>Records Per page :</div>
                                <FormControl>
                                    <Select sx={{ backgroundColor: '#fff', width: 'fit-content' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={rowsPerPage}
                                        onChange={(e) => handleRowPerPageChange(e.target.value)}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <CommonPagination currentPage={currentPage} totalPages={Math.ceil(totalECLeads / rowsPerPage)}
                                handlePageChange={handlePageChange} />
                        </div>
                    </div>
                </Paper>
            </Box>
            {selectedLead && (
                <ECCommentDialog
                    open={openDialog}
                    onClose={closeCommentDialog}
                    leadId={selectedLead.leadId}
                />
            )}
        </div>
    )
}

export default ECLeadsManagement;