import { Autocomplete, Button, Checkbox, Chip, createTheme, TextField, ThemeProvider, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const rendeFollowerFilterContent = (adminFilters, handleAdminFilterChange, handleFollowersFilter) => {
  return (
    <>
      <div className='followers-range-div'>
        <div className='followers-range-col'>
          <div className='followers-range-text'>Followers From:</div>
          <TextField
            id="outlined-basic"
            value={adminFilters.followersFrom}
            placeholder='Followers From'
            variant="outlined"
            type='number'
            name='followersFrom'
            inputProps={{ min: "0", pattern: "[0-9]*" }}
            onChange={(e) => handleAdminFilterChange("followersFrom", e.target.value)}
          />
        </div>
        <div className='followers-range-col'>
          <div className='followers-range-text'>Followers To:</div>
          <TextField
            id="outlined-basic"
            value={adminFilters.followersTo}
            placeholder="Followers To"
            variant="outlined"
            type='number'
            name="followersTo"
            inputProps={{ min: "0", pattern: "[0-9]*" }}
            onChange={(e) => handleAdminFilterChange("followersTo", e.target.value)}
          />
        </div>
      </div>
      <div className='followers-range-footer'>
        <Button disabled={adminFilters.followersFrom === null || adminFilters.followersTo === null}
          onClick={() => handleFollowersFilter()}>
          Apply Filter
        </Button>
      </div>
    </>
  );
};

export const renderCityFilterContent = (cities, adminFilters, handleAdminFilterChange, handleCityFilter) => {
  const isStateSelected = adminFilters.state.length > 0;
  const isCitySelected = adminFilters.city.length > 0;
  const cityTheme = createTheme({
    components: {
      MuiChip: {
        styleOverrides: {
          label: {
            maxWidth: '70px',
            paddingLeft: '8px',
            paddingRight: '8px'
          }
        }
      }
    }
  });
  return (
    <>
      <div className='state-city-area'>
        <ThemeProvider theme={cityTheme}>
          <Autocomplete
            freeSolo
            multiple
            limitTags={1}
            options={[...new Set(cities.map(city => city.state))]}
            getOptionLabel={(option) => option || []}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
              </li>
            )}
            onChange={(event, newValue) => {
              handleAdminFilterChange("state", newValue);
            }}
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                InputProps={{
                  ...params.InputProps,
                  style: { width: '100%' }
                }}
              />
            )}
            value={adminFilters.state}
            renderTags={(value, getTagProps) => (
              <>
                {value.slice(0, 1).map((option, index) => (
                  <Tooltip key={index} title={option}>
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))}
                {value.length > 1 && (
                  <Tooltip title={value.slice(1).join(', ')}>
                    <Chip
                      label={`${value.length - 1}+`}
                      {...getTagProps({ index: value.length - 1 })}
                    />
                  </Tooltip>
                )}
              </>
            )}
          />
        </ThemeProvider>
        <ThemeProvider theme={cityTheme}>
          <Autocomplete
            freeSolo
            multiple
            limitTags={1}
            options={[...new Set(cities.map(city => city.city))]}
            getOptionLabel={(option) => option || []}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
              </li>
            )}
            onChange={(event, newValue) => {
              handleAdminFilterChange("city", newValue);
            }}
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                InputProps={{
                  ...params.InputProps,
                  style: { width: '100%' }
                }}
              />
            )}
            value={adminFilters.city}
            renderTags={(value, getTagProps) => (
              <>
                {value.slice(0, 1).map((option, index) => (
                  <Tooltip key={index} title={option}>
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))}
                {value.length > 1 && (
                  <Tooltip title={value.slice(1).join(', ')}>
                    <Chip
                      label={`${value.length - 1}+`}
                      {...getTagProps({ index: value.length - 1 })}
                    />
                  </Tooltip>
                )}
              </>
            )}
            disabled={!isStateSelected}
          />
        </ThemeProvider>
        <ThemeProvider theme={cityTheme}>
          <Autocomplete
            freeSolo
            multiple
            limitTags={1}
            options={[...new Set(cities.map(city => city.area))]}
            getOptionLabel={(option) => option || []}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
              </li>
            )}
            onChange={(event, newValue) => {
              handleAdminFilterChange("area", newValue);
            }}
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Area/Locality"
                InputProps={{
                  ...params.InputProps,
                  style: { width: '100%' }
                }}
              />
            )}
            value={adminFilters.area}
            renderTags={(value, getTagProps) => (
              <>
                {value.slice(0, 1).map((option, index) => (
                  <Tooltip key={index} title={option}>
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                    />
                  </Tooltip>
                ))}
                {value.length > 1 && (
                  <Tooltip title={value.slice(1).join(', ')}>
                    <Chip
                      label={`${value.length - 1}+`}
                      {...getTagProps({ index: value.length - 1 })}
                    />
                  </Tooltip>
                )}
              </>
            )}
            disabled={!isCitySelected || !isStateSelected}
          />
        </ThemeProvider>
      </div>
      <div className='followers-range-footer'>
        <Button disabled={!isStateSelected}
          onClick={() => handleCityFilter()}>
          Apply Filter
        </Button>
      </div>
    </>
  );
};

export const rendeDateRangeFilterContent = (adminFilters, handleAdminFilterChange, handleDateFilter) => {
  return (
    <>
      <div className='followers-range-div'>
        <div className='followers-range-col'>
          <div className='followers-range-text'>From Date:</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={adminFilters.fromDate ? dayjs(adminFilters.fromDate) : null}
              format="YYYY-MM-DD"
              onChange={(newValue) => handleAdminFilterChange("fromDate", newValue ? dayjs(newValue).format('YYYY-MM-DD') : null)}
              renderInput={(params) => (<TextField {...params} variant="outlined" />)}
            />
          </LocalizationProvider>
        </div>
        <div className='followers-range-col'>
          <div className='followers-range-text'>To Date:</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={adminFilters.toDate ? dayjs(adminFilters.toDate) : null}
              onChange={(newValue) => handleAdminFilterChange("toDate", newValue ? dayjs(newValue).format('YYYY-MM-DD') : null)}
              format="YYYY-MM-DD"
              minDate={adminFilters.fromDate ? dayjs(adminFilters.fromDate) : null}
              renderInput={(params) => (<TextField {...params} variant="outlined" />)}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className='followers-range-footer'>
        <Button disabled={adminFilters.fromDate == null || adminFilters.toDate == null}
          onClick={() => handleDateFilter()}>Apply Filter</Button>
      </div>
    </>
  );
};

export const renderInterestFilterContent = (categories, adminFilters, handleAdminFilterChange, handleInterestFilter) => {
  return (
    <>
      <Autocomplete
        freeSolo
        multiple
        limitTags={2}
        id="categories"
        options={categories}
        value={adminFilters.areaOfInterests}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        onChange={(event, newValue) => {
          handleAdminFilterChange("areaOfInterests", newValue);
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        style={{ width: 378 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Categories"
            InputProps={{
              ...params.InputProps,
              style: { width: '100%' }
            }}
          />
        )}
        renderTags={(value, getTagProps) => (
          <>
            {value.slice(0, 2).map((option, index) => (
              <Chip
                key={index}
                title={option}
                label={option}
                {...getTagProps({ index })}
              />
            ))}
            {value.length > 2 && (
              <Chip
                label={`${value.length - 2}+`}
                {...getTagProps({ index: value.length - 1 })}
              />
            )}
          </>
        )}
      />
      <div className='followers-range-footer'>
        <Button disabled={adminFilters.areaOfInterests === 0 || adminFilters.areaOfInterests == null}
          onClick={() => handleInterestFilter()}>Apply Filter</Button>
      </div>
    </>
  );
};
