import React from "react";
import "../../css/campaign.css";
import { Box } from "@mui/material";
import CommonAppbarDrawer from "../common/common-sidebar-navbar";
import { Outlet } from "react-router-dom";
import { DrawerHeader } from "../../mui/appbar-style-component";

function CampaignDashboard() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: '2rem', backgroundColor: '#fff', minHeight: '100vh' }}>
                <DrawerHeader />
                <Outlet/>
            </Box>
        </Box>
    )
}

export default CampaignDashboard
