import React, { useContext, useEffect, useState } from 'react';
import '../../css/discovery.css'
import instagramLogo from '../../assets/instagram-logo.png'
import { AutocompleteBoxStyle, FollowersBoxStyle, CategoriesBoxStyle } from './../../mui/mui-styling';
import { Box, Autocomplete, TextField, Checkbox, Chip, Tooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';
import CommonAppbarDrawer from './../common/common-sidebar-navbar';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { fetchCategories, getRegisterCities } from '../common/common-apis-repo';
import CreatorsList from './creators-list';
import { DrawerHeader } from '../../mui/appbar-style-component';
import { WindowWidthContext } from '../context/user-details-context';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ------------engagement-rate-----------
const engagementRate = [
    '≥1%',
    '≥2%',
    '≥3%',
    '≥4%',
    '≥5%',
    '≥6%',
    '≥7%',
    '≥8%',
    '≥9%',
    '≥10%',
];

// ------------followers-from-----------
const followersFrom = [
    '1,000',
    '3,000',
    '5,000',
    '10,000',
    '15,000',
    '20,000',
    '25,000',
    '35,000',
    '50,000',
    '75,000',
    '100,000',
    '125,000',
    '150,000',
    '175,000',
    '200,000',
    '250,000',
    '300,000',
    '350,000',
    '500,000',
    '1,000,000',
    '2,000,000',
];

// ------------followers-t0-----------
const followersTo = [
    '3,000', '5,000',
    '10,000',
    '15,000',
    '20,000',
    '25,000',
    '35,000',
    '50,000',
    '75,000',
    '100,000',
    '125,000',
    '150,000',
    '175,000',
    '200,000',
    '250,000',
    '300,000',
    '350,000',
    '500,000',
    '1,000,000',
    '2,000,000',
    '3,000,000'
];

const Discovery = () => {
    const { baseUrl } = getConfig();
    const [creators, setCreators] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [categories, setCategories] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedEngagementRate, setSelectedEngagementRate] = useState([]);
    const [selectedFollowersFrom, setSelectedFollowersFrom] = useState([]);
    const [selectedFollowersTo, setSelectedFollowersTo] = useState([]);
    const [apiFailed, setApiFailed] = useState(false);
    const [totalResults, setTotalResults] = useState();
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const windowWidth = useContext(WindowWidthContext);

    // -------------show influencers------------ 
    // eslint-disable-next-line 
    const fetchShowfluencers = async (event) => {
        try {
            setLoading(true);
            let filters = {
                pageNo: currentPage,
                pageSize: rowsPerPage,
            };
            filters.followers = true;

            if (selectedCategories.length > 0) {
                filters.category = selectedCategories;
            }
            if (selectedCity.length > 0) {
                filters.city = selectedCity;
            }
            if (selectedEngagementRate.length > 0) {
                filters.engagementRatio = selectedEngagementRate;
            }
            if (selectedFollowersFrom.length > 0 && selectedFollowersTo.length > 0) {
                filters.followersRange = {
                    min: parseInt(selectedFollowersFrom.replace(/,/g, '')),
                    max: parseInt(selectedFollowersTo.replace(/,/g, ''))
                };
            }

            const response = await fetch(`${baseUrl}/creators`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify(filters)
            });

            const data = await response.json();
            if (response.ok) {
                const transformedResponse = {
                    totalResults: data.totalResults,
                    creators: data.creators.map(item => ({
                        instaId: item.instaId,
                        instaHandle: item.instaHandle,
                        instaFullName: item.instaFullName,
                        followersCount: item.followersCount,
                        mediaCount: item.mediaCount,
                        totalLikes: item.totalLikes,
                        totalComments: item.totalComments,
                        engagement: item.engagement,
                        engagementRate: item.engagementRate,
                        noOfPost: item.noOfPost,
                        profilePictureId: item.profilePictureId,
                        bio: item.bio,
                        creatorRates: item.creatorRates,
                        creatorScore: item.creatorScore,
                        avgLike: item.avgLike,
                        avgComment: item.avgComment,
                        creatorFullName: item.creatorFullName,
                        category: item.category,
                        creatorLocation: item.creatorLocation,
                        state: item.state,
                        city: item.city,
                        area: item.area
                    }))
                };
                setTotalResults(transformedResponse.totalResults);
                setApiFailed(transformedResponse.creators.length === 0);
                setTotalPages(Math.ceil(transformedResponse.totalResults / rowsPerPage));
                setCreators(transformedResponse.creators);
            } else if (response.status === 499) {
                refreshToken();
            } else {
                console.error('Error fetching influencers:', data.message);
                setApiFailed(true);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching influencers:', error);
            setApiFailed(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories(setCategories);
        getRegisterCities(setCities, 'Creator');

        if (initialLoad) {
            fetchShowfluencers();
            setInitialLoad(false);
        } else {
            fetchShowfluencers();
        }

        // eslint-disable-next-line
    }, [setInitialLoad, currentPage, rowsPerPage]);

    const optionsTheme = createTheme({
        components: {
            MuiChip: {
                styleOverrides: {
                    label: {
                        maxWidth: '100px',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                    }
                }
            }
        }
    });
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, backgroundColor: '#fff', minHeight: '100vh' }}>
                <DrawerHeader />
                <>
                    <div className='filter-outer-box'>
                        <div className="influencer-container">
                            <div className="influencer-content">
                                <div className={windowWidth <= 799 ? "discovery-filters-mobile" : "discovery-filters"}>
                                    <div className='instagram-logo'> <img alt="" src={instagramLogo} style={{ width: '35px' }} /> </div>
                                    <div className='discovery-filter-column'>
                                        <div className={windowWidth <= 799 ? "" : "vertical-divider"}></div>
                                        <div className='filter-element-outer-box'>
                                            <div className="_filterLabel_c8zr3_34"> <span>Location</span> </div>
                                            <Box sx={AutocompleteBoxStyle}>
                                                <ThemeProvider theme={optionsTheme}>
                                                    <Autocomplete
                                                        className='custom-city'
                                                        multiple
                                                        limitTags={1}
                                                        id="city"
                                                        options={[...new Set(cities.map(city => `${city.city} - ${city.state}`))]}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option || []}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                const selectedCities = newValue.map(option => option.split(' - ')[0]);
                                                                setSelectedCity(selectedCities);
                                                            } else {
                                                                setSelectedCity([]);
                                                            }
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}> {option} </li>
                                                        )}
                                                        renderInput={(params) => (<TextField {...params} placeholder='Any' />)}
                                                        value={selectedCity.map(city => `${city} - ${cities.find(c => c.city === city)?.state}`)}
                                                        renderTags={(value, getTagProps) => (
                                                            <>
                                                                {value.slice(0, 1).map((option, index) => (
                                                                    <Tooltip key={index} title={option} arrow>
                                                                        <Chip key={index} label={option} {...getTagProps({ index })} />
                                                                    </Tooltip>
                                                                ))}
                                                                {value.length > 1 && (
                                                                    <Tooltip title={value.slice(1).join(', ')} arrow>
                                                                        <Chip label={`${value.length - 1}+`} {...getTagProps({ index: value.length - 1 })} />
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </ThemeProvider>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='discovery-filter-column'>
                                        <div className={windowWidth <= 799 ? "" : "vertical-divider"}></div>
                                        <div className='filter-element-outer-box'>
                                            <div className="_filterLabel_c8zr3_34"><span>Followers Count</span></div>
                                            <div className='followers'>
                                                <Box sx={{ ...AutocompleteBoxStyle, ...FollowersBoxStyle }}>
                                                    <Autocomplete className='custom-followers-from'
                                                        id="followers-from"
                                                        options={followersFrom}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => {
                                                            if (Array.isArray(option) && option.length === 0) {
                                                                return '';
                                                            }
                                                            return option;
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            setSelectedFollowersFrom(newValue || []);
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}> {option} </li>
                                                        )}
                                                        renderInput={(params) => (<TextField {...params} placeholder='From' />)}
                                                        value={selectedFollowersFrom}
                                                    />
                                                </Box>
                                                <Box sx={{ ...AutocompleteBoxStyle, ...FollowersBoxStyle }}>
                                                    <Autocomplete className='custom-followers-to'
                                                        id="followers-to"
                                                        options={followersTo}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => {
                                                            if (Array.isArray(option) && option.length === 0) {
                                                                return '';
                                                            }
                                                            return option;
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            setSelectedFollowersTo(newValue || []);
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}> {option} </li>
                                                        )}
                                                        renderInput={(params) => (<TextField {...params} placeholder='To' />)}
                                                        value={selectedFollowersTo}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='discovery-filter-column'>
                                        <div className={windowWidth <= 799 ? "" : "vertical-divider"}></div>
                                        <div className='filter-element-outer-box'>
                                            <div className="_filterLabel_c8zr3_34">
                                                <div className="_rowWithAlignment_c8zr3_1">
                                                    <span>Engagement rate</span>
                                                    <div className="info-badge">i
                                                        <span className="discovery-tooltip">Find creators based on how engaged the creator's audience is. Engagement Rate is the ratio of likes divided by followers.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Box sx={AutocompleteBoxStyle}>
                                                <Autocomplete className='custom-engagement-rate'
                                                    id="engagement-rate"
                                                    options={engagementRate}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => {
                                                        if (Array.isArray(option) && option.length === 0) {
                                                            return '';
                                                        }
                                                        return option;
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedEngagementRate(newValue || []);
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}> {option} </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder='Any' />
                                                    )}
                                                    value={selectedEngagementRate}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='filter-second-row'>
                            <div className="_location_c8zr3_163">
                                <div className="_filterLabel_c8zr3_34 categories-label">
                                    <span>Categories</span>
                                </div>
                                <Box sx={CategoriesBoxStyle} className="category-box">
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id="checkboxes-tags-demo"
                                        options={categories}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => {
                                            if (Array.isArray(option) && option.length === 0) {
                                                return '';
                                            }
                                            return option;
                                        }}
                                        onChange={(event, newValue) => {
                                            setSelectedCategories(newValue || []);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                                {option}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Categories" />
                                        )}
                                        value={selectedCategories}
                                        renderTags={(value, getTagProps) => (
                                            <>
                                                {value.slice(0, 2).map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))}
                                                {value.length > 2 && (
                                                    <Chip label={`${value.length - 2}+`} {...getTagProps({ index: value.length - 1 })} />
                                                )}
                                            </>
                                        )}
                                    />
                                </Box>
                            </div>
                            <div className='show-result-label'>
                                <button
                                    onClick={() => {
                                        setInitialLoad(true);
                                        setCurrentPage(1);
                                        fetchShowfluencers();
                                    }}
                                >Show results
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='discovery-page-container'>
                        <CreatorsList isDiscovery={true} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} applications={false} totalPages={totalPages} loading={loading} totalResults={totalResults} apiFailed={apiFailed}
                            creators={creators} setInitialLoad={setInitialLoad} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                </>
            </Box>
        </Box>
    )
};

export default Discovery;
