import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Discovery from './home/discovery-page';
import Protected from './common/protected';
import Signin from './user/signin-page';
import Login from './user/login-page';
import LandingPage from './common/landingPages/landing-page';
import ContactUs from './common/landingPages/contact-us';
import PrivacyPolicy from './common/landingPages/privacy-policy';
import TermsOfService from './common/landingPages/terms-of-service';
import CampaignPage from './campaign/campaign-page';
import CreateCampaign from './campaign/create-campaign';
import CampaignDetails from './campaign/campaign-details';
import AdminPanel from './admin/admin-panel';
import AffiliateMarketing from './admin/affiliates/affiliate-marketing';
import EarningPage from './feature/earning-page';
import Dashboard from './creator/dashboard';
import CampaignDashboard from './campaign/campaign-dashboard';
import BillingDashboard from './billing/billing-dashboard';
import CampaignStatistics from './feature/campaign-statistics';
import PagesDashboard from './pages/pages-dashboard';
import PagesPending from './pages/pages-pending';
import Tickets from './tickets/tickets';
import LeadsDashboard from './leads/leads/leads-dashboard';
import TagsDashboard from './leads/tags/tags-dashboard';
import InvoiceList from './billing/invoice-list';
import InvoiceForm from './invoice/invoice-form';
import EcLoginPage from './user/ec-login-page';
import CampaignApplication from './campaign/campaign-application';
import AdminDashboard from './admin/dashboard/admin-dashboard';
import ECLeadsDashboard from './leads/ec-leads/ec-leads-dashboard';
import InternalECleadForm from './leads/ec-leads/internal-eclead-form';
import CreatorDashboard from './admin/creator/creator-dashboard';
import ECDashboard from './admin/enterprise client/ec-dashboard';
import ViewInternalECLead from './leads/ec-leads/view-internal-eclead';
import ErrorPage from './common/error-page';
import PricingPages from './common/pricing-pages/pricing-pages';
import EcLandingPage from './common/ec-landing-pages/ec-landing-page';
import Blogs from './common/pricing-pages/blogs';
import Updates from './common/landingPages/updates';
import Careers from './common/landingPages/careers';
import Helpdesk from './common/landingPages/helpdesk';
import AskQuestions from './common/landingPages/ask-questions';
import EcAffiliateMarketing from './admin/affiliates/ec-affiliate-marketing';

const useScript = (url, condition) => {
    const currentUrl = window.location.href;

    useEffect(() => {
        if (!currentUrl.includes('development.oyecreators.com') && !currentUrl.includes('localhost')) {
            const scriptId = 'tidio-script';

            const addScript = () => {
                if (!document.getElementById(scriptId)) {
                    const script = document.createElement('script');
                    script.id = scriptId;
                    script.src = url;
                    script.async = true;
                    document.body.appendChild(script);
                }
            };

            const removeScript = () => {
                const tidioScript = document.getElementById(scriptId);
                if (tidioScript) {
                    document.body.removeChild(tidioScript);
                }

                const tidioElements = document.querySelectorAll('[id^="tidio-chat-"]');
                tidioElements.forEach(element => element.parentNode.removeChild(element));
            };

            if (condition) {
                addScript();
            } else {
                removeScript();
            }
        }

    }, [url, condition, currentUrl]);
};

const ROLES = {
    ADMIN: 'ADMIN',
    CREATOR: 'CREATOR',
    ENTERPRISE_CLIENT: 'ENTERPRISE_CLIENT',
    LEAD_AGENT: 'LEAD_AGENT',
    LEAD_MANAGER: 'LEAD_MANAGER',
};

const ACCESS = {
    LEADDASHBOARD_VIEW: 'LeadDashboard:View',
    LEAD_VIEW: 'Lead:View',
    LEAD_CREATE: 'Lead:Create',
    LEAD_EDIT: 'Lead:Edit',
    LEAD_ASSIGN: 'Lead:Assign'
}

function AppRoutes() {
    const location = useLocation();
    useScript('//code.tidio.co/9pdwrc6dsbuqh2vnspe4xs7zj06ainc0.js', (location.pathname !== '/' &&
        location.pathname !== '/contact-us' &&
        location.pathname !== '/privacy-policy' &&
        location.pathname !== '/terms-of-service' &&
        location.pathname !== '/signin' &&
        location.pathname !== '/login')
    );

    return (
        <Routes>
            <Route path="/" element={<Protected Component={LandingPage} />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/login" element={<Protected Component={Login} />} />
            <Route path="/pricing-page" element= {<PricingPages/>}/>
            <Route path="/ec-landing-page/:agentId/:adId" element= {<EcLandingPage/>}/>
            <Route path="/blog" element= {<Blogs/>}/>
            <Route path="/updates" element= {<Updates/>}/>
            <Route path="/careers" element= {<Careers/>}/>
            <Route path="/helpdesk" element= {<Helpdesk/>}/>
            <Route path="/ask-a-question" element= {<AskQuestions/>}/>

            <Route path="/discovery" element={<ECAndCreatorElement><Protected Component={Discovery} /></ECAndCreatorElement>} />

            <Route path="/dashboard" element={<CreatorElement><Protected Component={Dashboard} /></CreatorElement>} />
            <Route path="/earning" element={<CreatorElement><Protected Component={EarningPage} /></CreatorElement>} />

            <Route path="/admin-dashboard" element={<AdminElement><Protected Component={AdminDashboard} /></AdminElement>} />

            <Route path="/creator" element={<Protected Component={CreatorDashboard} />} >
                <Route path="admin-panel/:user-list" element={<AdminElement><Protected Component={AdminPanel} /></AdminElement>} />
                <Route path="leads-dashboard" element={<AdminElement><Protected Component={LeadsDashboard} /></AdminElement>} />
                <Route path="users-affiliate-marketing" element={<AdminElement><Protected Component={AffiliateMarketing} /></AdminElement>} />

            </Route>
            <Route path="/creator/tags-dashboard" element={<AdminElement><Protected Component={TagsDashboard} /></AdminElement>} />

            <Route path="/enterprise-client" element={<Protected Component={ECDashboard} />} >
                <Route path="admin-panel/:enterprise-list" element={<AdminElement><Protected Component={AdminPanel} /></AdminElement>} />
                <Route path="ec-leads-dashboard" element={<LeadViewElement><Protected Component={ECLeadsDashboard} /></LeadViewElement>} />
                <Route path="create-internal-ec-lead" element={<LeadEditAndCreateElement><Protected Component={InternalECleadForm} /></LeadEditAndCreateElement>} />
                <Route path="view-internal-ec-lead" element={<LeadViewElement><Protected Component={ViewInternalECLead} /></LeadViewElement>} />
                <Route path="ec-affiliate-marketing" element={<AdminElement><Protected Component={EcAffiliateMarketing} /></AdminElement>} />
            </Route>
            <Route path="/enterprise-client/registration" element={<AdminElement><Protected Component={EcLoginPage} /></AdminElement>} />

            <Route path="/tickets" element={<AdminElement><Protected Component={Tickets} /></AdminElement>} />
            <Route path="/pages" element={<Protected Component={PagesDashboard} />} >
                <Route path="case-studies" element={<AdminElement><Protected Component={PagesPending} /></AdminElement>} />
                <Route path="blogs" element={<AdminElement><Protected Component={PagesPending} /></AdminElement>} />
                <Route path="career" element={<AdminElement><Protected Component={PagesPending} /></AdminElement>} />
            </Route>
            <Route path="/all-campaigns" element={<Protected Component={CampaignDashboard} />} >
                <Route path="create" element={<AdminElement><Protected Component={CreateCampaign} /></AdminElement>} />
                <Route path="details" element={<AdminAndCreatorElement><Protected Component={CampaignDetails} /></AdminAndCreatorElement>} />
                <Route path="completed" element={<AdminAndCreatorElement><Protected Component={CampaignPage} /></AdminAndCreatorElement>} />
                <Route path="live" element={<AdminAndCreatorElement><Protected Component={CampaignPage} /></AdminAndCreatorElement>} />
                <Route path="pending" element={<AdminElement><Protected Component={CampaignPage} /></AdminElement>} />
                <Route path="draft" element={<AdminElement><Protected Component={CampaignPage} /></AdminElement>} />
                <Route path="invoice/:invoiceId" element={<AdminElement><Protected Component={InvoiceForm} /></AdminElement>} />
                <Route path="invoice/create-invoice" element={<AdminElement><Protected Component={InvoiceForm} /></AdminElement>} />
                <Route path="applications" element={<AdminElement><Protected Component={CampaignApplication} /></AdminElement>} />
            </Route>

            <Route path="/my-campaigns" element={<Protected Component={CampaignDashboard} />} >
                <Route path="create" element={<EnterpriseClientElement><Protected Component={CreateCampaign} /></EnterpriseClientElement>} />
                <Route path="details" element={<EnterpriseClientElement><Protected Component={CampaignDetails} /></EnterpriseClientElement>} />
                <Route path="completed" element={<EnterpriseClientElement><Protected Component={CampaignPage} /></EnterpriseClientElement>} />
                <Route path="live" element={<EnterpriseClientElement><Protected Component={CampaignPage} /></EnterpriseClientElement>} />
                <Route path="pending" element={<EnterpriseClientElement><Protected Component={CampaignPage} /></EnterpriseClientElement>} />
                <Route path="draft" element={<EnterpriseClientElement><Protected Component={CampaignPage} /></EnterpriseClientElement>} />
                <Route path="applications" element={<EnterpriseClientElement><Protected Component={CampaignApplication} /></EnterpriseClientElement>} />
            </Route>

            <Route path="/billing" element={<Protected Component={BillingDashboard} />} >
                <Route path="completed" element={<AdminAndECElement><Protected Component={InvoiceList} /></AdminAndECElement>} />
                <Route path="pending" element={<AdminAndECElement><Protected Component={InvoiceList} /></AdminAndECElement>} />
                <Route path="draft" element={<AdminAndECElement><Protected Component={InvoiceList} /></AdminAndECElement>} />
                <Route path="paid" element={<AdminAndECElement><Protected Component={InvoiceList} /></AdminAndECElement>} />
            </Route>

            <Route path="/campaign-statistics" element={<EnterpriseClientElement><Protected Component={CampaignStatistics} /></EnterpriseClientElement>} />
        </Routes>
    )
}

function AdminElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role').match(ROLES.ADMIN)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function CreatorElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role').match(ROLES.CREATOR)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function EnterpriseClientElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
            if (localStorage.getItem('role').match(ROLES.ENTERPRISE_CLIENT)) {
                return <>{children}</>;
            } else {
                return <div>Not Found!</div>;
            }
        } else {
            window.location.href = '/signin';
        }
    }
}

function ECAndCreatorElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role').match(ROLES.ENTERPRISE_CLIENT) ||
            localStorage.getItem('role').match(ROLES.CREATOR)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function AdminAndCreatorElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role').match(ROLES.ADMIN) ||
            localStorage.getItem('role').match(ROLES.CREATOR)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function AdminAndECElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('role').match(ROLES.ADMIN) ||
            localStorage.getItem('role').match(ROLES.ENTERPRISE_CLIENT)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function LeadViewElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('access').includes(ACCESS.LEADDASHBOARD_VIEW) ||
            localStorage.getItem('access').includes(ACCESS.LEAD_VIEW)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

function LeadEditAndCreateElement({ children }) {
    if (localStorage.getItem('role') !== null && localStorage.getItem('role') !== '') {
        if (localStorage.getItem('access').includes(ACCESS.LEAD_CREATE) ||
            localStorage.getItem('access').includes(ACCESS.LEAD_EDIT)) {
            return <>{children}</>;
        } else {
            return <div>Not Found!</div>;
        }
    } else {
        window.location.href = '/signin';
    }
}

AdminElement.propTypes = {
    children: PropTypes.any
};

CreatorElement.propTypes = {
    children: PropTypes.any
};

EnterpriseClientElement.propTypes = {
    children: PropTypes.any
};

ECAndCreatorElement.propTypes = {
    children: PropTypes.any
};

AdminAndCreatorElement.propTypes = {
    children: PropTypes.any
};

AdminAndECElement.propTypes = {
    children: PropTypes.any
};

LeadViewElement.propTypes = {
    children: PropTypes.any
};

LeadEditAndCreateElement.propTypes = {
    children: PropTypes.any
};

export default AppRoutes;
