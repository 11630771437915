import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { autocompleteService, GOOGLE_MAPS_API_KEY, loadScript } from '../common/googlemap-config';
import { Chip } from '@mui/material';

export default function CampaignLocation({ handleChange, locationDescription }) {
    const [campaignLocations, setCampaignLocations] = React.useState([]);
    const [inputLocationValue, setInputLocationValue] = React.useState('');
    const [campaignLocationsOptions, setCampaignLocationsOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                const restrictedRequest = {
                    ...request,
                    componentRestrictions: { country: 'in' },
                };
                autocompleteService.current.getPlacePredictions(restrictedRequest, callback);
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputLocationValue === '') {
            setCampaignLocationsOptions(campaignLocations.length > 0 ? [...campaignLocations] : []);
            return undefined;
        }

        fetch({ input: inputLocationValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (campaignLocations.length > 0) {
                    newOptions = [...campaignLocations];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setCampaignLocationsOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [campaignLocations, inputLocationValue, fetch]);

    const handleValueChange = (event, newValue) => {
        const uniqueLocations = newValue.filter(
            (option, index) =>
                typeof option === 'string' ||
                newValue.findIndex((o) => typeof o !== 'string' && o.place_id === option.place_id) === index
        );

        const placeIds = uniqueLocations
            .map((location) => location.place_id)
            .filter(placeId => placeId !== undefined && placeId !== null);

        handleChange(event, placeIds, 'placeIds');

        setCampaignLocations(uniqueLocations);

        const descriptions = uniqueLocations.map((location) => {
            if (typeof location === 'string') {
                return location;
            }

            if (location && typeof location === 'object' && location.description) {
                return location.description;
            }

            return undefined;
        }).filter(description => description !== undefined && description !== null);

        handleChange(event, descriptions.length > 0 ? descriptions : uniqueLocations, 'campaignLocations');
    }

    return (
        <Autocomplete
            id="google-map-demo"
            className='custom-city'
            sx={{ width: "500px" }}
            multiple
            limitTags={3}
            name="campaignLocations"
            disableCloseOnSelect
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : locationDescription ? locationDescription : option.description
            }
            filterOptions={(x) => x}
            options={campaignLocationsOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={campaignLocations.length > 0 ? campaignLocations : locationDescription}
            noOptionsText="No locations"
            onChange={handleValueChange}
            onInputChange={(event, newInputValue) => {
                setInputLocationValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="City" fullWidth />
            )}
            renderTags={(value, getTagProps) => (
                <>
                    {value.slice(0, 3).map((option, index) => {
                        const description = locationDescription && locationDescription[index]
                            ? locationDescription[index]
                            : option.description;
                        return (
                            <Chip
                                key={index}
                                label={description}
                                {...getTagProps({ index })}
                            />
                        );
                    })}
                    {value.length > 3 && (
                        <Chip
                            label={`${value.length - 3}+`}
                            {...getTagProps({ index: value.length - 1 })}
                        />
                    )}
                </>
            )}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                const structuredFormatting = option.structured_formatting || {};
                const matches = structuredFormatting.main_text_matched_substrings || [];

                const parts = parse(
                    structuredFormatting.main_text || '',
                    matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {structuredFormatting.secondary_text || ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
