import React, { useState } from 'react'
import CommonAppbarDrawer from '../common/common-sidebar-navbar';
import { Box } from '@mui/material';
import '../../css/dashboard.css';
import '../../css/table-list.css';
import CreatorInfo from './creator-info';
import TopCampaign from './top-campaign';
import { DrawerHeader } from '../../mui/appbar-style-component';

function Dashboard() {
    const [isReloadDetails, setIsReloadDetails] = useState(null);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CommonAppbarDrawer setIsReloadDetails={setIsReloadDetails} />
                <Box component="main" sx={{ flexGrow: 1, padding: '2rem', backgroundColor: '#edeff4', minHeight: '100vh' }}>
                    <DrawerHeader />
                    <div className='dashboard-card'>
                        <CreatorInfo isReloadDetails={isReloadDetails} />
                        <div className='dashboard-divider'></div>
                        <TopCampaign topCampaign={true} />
                        <div className='dashboard-divider'></div>
                        <TopCampaign />
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default Dashboard;
