import React from 'react';
import AppRoutes from './app-routes.js';
import { LeadFiltersProvider } from './context/eclead-filter-context.js';
import { UserDetailsProvider, WindowWidthProvider } from './context/user-details-context.js';

const AAACreator = () => {
  return (
    <UserDetailsProvider>
      <WindowWidthProvider>
        <LeadFiltersProvider>
          <React.StrictMode>
            <AppRoutes />
          </React.StrictMode>
        </LeadFiltersProvider>
      </WindowWidthProvider>
    </UserDetailsProvider>
  )
}

export default AAACreator;