
import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress } from '@mui/material';
import { fetchECLead, toTitleCase } from '../ec-leads-helper';
import moment from 'moment';

const ECCommentDialog = ({ open, onClose, leadId }) => {
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const [leadDetails, setLeadsDetails] = useState({
        fullName: "", contactMobile: "", contactEmail: "", leadType: "", companyName: "",
        leadLocation: null, source: "", proposalSent: false, packageName: null, status: "Prospect",
        probabilityStatus: null, mobileVerified: false, emailVerified: false, agentId: null, notes: [{ id: null, note: null, isDeleted: false, updatedOn: null, createdBy: null }], newNote: null,
    });

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        fetchECLead(setLeadsDetails, leadId, setLoading, null);
    }, [leadId]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{
                color: '#3b4758', fontSize: '1.5rem',
                fontWeight: '600'
            }}>Comments</DialogTitle>
            <DialogContent>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    <div className='create-lead-row-3'>
                        {(leadDetails.notes
                            .filter(note => !note.isDeleted))
                            .slice(0, expanded ? leadDetails.notes.length : 2).map((note, index) => (
                                note.note != null
                                    ? (
                                        <Card sx={{ minWidth: 275, marginBottom: '12px', }}>
                                            <CardContent sx={{ padding: '12px 12px 0px 12px', '&.MuiCardContent-root:last-child': { paddingBottom: '12px !important' } }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                                        {moment(note.updatedOn).format("ll")}
                                                    </Typography>
                                                    <Typography color="text.secondary">
                                                    {toTitleCase(note.createdBy.firstName + " " + note.createdBy.lastName)}
                                                    </Typography>
                                                </div>
                                                <Typography dangerouslySetInnerHTML={{ __html: note.note }} />
                                            </CardContent>
                                        </Card>
                                    ) : (
                                        <Typography key={index} variant="body2" sx={{
                                            fontSize: 'x-large', display: 'flex', justifyContent: 'center', color: 'gray',
                                            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'
                                        }} >
                                            No Comments Added
                                        </Typography>
                                    )
                            ))}
                        {leadDetails.notes.length > 2 && <Button sx={{
                            display: 'flex', justifyContent: 'flex-end', color: '#e563a4', '&:hover': { color: '#e326a4' }
                        }} onClick={handleToggleExpand}>{expanded ? 'Show Less' : 'Read More'}</Button>}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ECCommentDialog;
