import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import { itemsTextfieldBoxStyle } from '../../mui/mui-styling';
import NumberInput from './custom-input-number';

const ItemRow = ({ index, item, onDelEvent, handleItemClick }) => {
  const [itemName, setItemName] = useState(item.name || '');
  const [itemDescription, setItemDescription] = useState(item.description || '');
  const [quantity, setQuantity] = useState(item.quantity || 0);
  const [price, setPrice] = useState(item.price || '0.00');
  const [currency] = useState('₹');

  useEffect(() => {
    setItemName(item.name || '');
    setItemDescription(item.description || '');
    setQuantity(item.quantity || 0);
    setPrice(item.price || '0.00');
  }, [item]);

  const handleItemNameChange = (event) => {
    const newName = event.target.value;
    setItemName(newName);
    handleItemClick(index, { name: newName, description: itemDescription, quantity, price });
  };

  const handleItemDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setItemDescription(newDescription);
    handleItemClick(index, { name: itemName, description: newDescription, quantity, price });
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
    handleItemClick(index, { name: itemName, description: itemDescription, quantity: value, price });
  };

  const handlePriceChange = (value) => {
    setPrice(value);
    handleItemClick(index, { name: itemName, description: itemDescription, quantity, price: value });
  };

  const onDelete = () => {
    onDelEvent(index);
  };

  return (
    <div>
      <div className='add-item-container'>
        <div className='item-container'>
          <TextField
            sx={itemsTextfieldBoxStyle}
            value={itemName}
            onChange={handleItemNameChange}
            placeholder={"Item name"}
            required
          />
          <TextField
            sx={itemsTextfieldBoxStyle}
            value={itemDescription}
            onChange={handleItemDescriptionChange}
            placeholder={"Item description"}
            required
          />
        </div>
        <div className='qty-container'>
          <NumberInput initialValue={quantity} onChange={handleQuantityChange} />
          <div className='price'>
            <div className='rupee-icon'>
              {currency}
            </div>
            <NumberInput initialValue={price} onChange={handlePriceChange} />
          </div>
          <div className="delete-icon">
            <RestoreFromTrashOutlinedIcon
              className="delete-icon-btn"
              onClick={onDelete}
              style={{ height: '35px', width: '35px', padding: '6.5px', color: 'white' }}
            />
          </div>
        </div>
      </div>
      <hr className="my-4" />
    </div>
  );
};

const InvoiceItem = ({ items, onItemListChange }) => {
  const [itemList, setItemList] = useState(items);

  useEffect(() => {
    setItemList(items);
  }, [items]);

  useEffect(() => {
    onItemListChange(itemList);
  }, [itemList, onItemListChange]);

  const addItem = () => {
    const newItem = {
      name: '',
      description: '',
      quantity: 0,
      price: '0.00'
    };
    setItemList([...itemList, newItem]);
  };

  const updateItem = (index, updatedItem) => {
    const updatedItemList = itemList.map((item, i) => (i === index ? { ...item, ...updatedItem } : item));
    setItemList(updatedItemList);
  };

  const deleteItem = (index) => {
    const updatedItemList = itemList.filter((_, i) => i !== index);
    setItemList(updatedItemList);
  };

  return (
    <div>
      <div>
        <div className='add-item-heading'>
          <div className='item-heading-text'>
            <div className='text-bold'>ITEM</div>
          </div>
          <div className='qty-heading-text'>
            <div className='text-gap'>
              <div className='text-bold'>QTY</div>
            </div>
            <div className='price-text-heading'>
              <div className='text-bold'>PRICE/RATE</div>
              <div className='text-bold'>ACTION</div>
            </div>
          </div>
        </div>
      </div>

      {itemList.map((item, index) => (
        <ItemRow
          key={index}
          index={index}
          item={item}
          onDelEvent={deleteItem}
          handleItemClick={updateItem}
        />
      ))}
      <button className="add-item-btn common-btn" onClick={addItem}>Add Item</button>
    </div>
  );
};

export default InvoiceItem;