import React from 'react'

function ErrorPage() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Something went wrong</h1>
    </div>
  )
}

export default ErrorPage;
