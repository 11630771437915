import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import "../../../css/landing-page.css";

function ContactUs() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ p: 6 }}>
        <h1 className='policy-header'>Contact Us</h1>
        <Typography>
          For any questions regarding this Policy or your personal data, please contact us at <a href=" mailto:Support@ttlmedia.in " >support@ttlmedia.in</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default ContactUs;