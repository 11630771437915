import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const LeadFiltersContext = createContext();

export const LeadFiltersProvider = ({ children }) => {
    const location = useLocation();
    const [leadFilters, setLeadFilters] = useState({
        searchString: "", searchType: "", leadStatus: "", leadSource: "", leadType: "",
        proposalSent: "", statusFrom: null, location: "", probability: "", agent: "", filterApply: false,
        sortBy: "", sortingType: ""
    });

    const isCreateRoute = location.pathname === '/enterprise-client/ec-leads-dashboard' ||
        location.pathname === '/enterprise-client/view-internal-ec-lead'
        || location.pathname === '/enterprise-client/create-internal-ec-lead';

    useEffect(() => {
        if (!isCreateRoute) {
            setLeadFilters({
                searchString: "", searchType: "", leadStatus: "", leadSource: "", leadType: "",
                proposalSent: "", statusFrom: null, location: "", probability: "", agent: "", filterApply: false
            });
        }
    }, [isCreateRoute]);

    return (
        <LeadFiltersContext.Provider value={{ leadFilters, setLeadFilters }}>
            {children}
        </LeadFiltersContext.Provider>
    );
};

export const useLeadFilters = () => {
    return useContext(LeadFiltersContext);
};
