import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import logo from '../../assets/creator-logo.svg';
import { WindowWidthContext } from '../context/user-details-context';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const navItems = ['Blogs', 'Pricing'];

const CommonLandingPageAppBar = ({ handleDrawerToggle, mobileOpen, setMobileOpen }) => {
    const windowWidth = useContext(WindowWidthContext);
    const location = useLocation();
    const navigate = useNavigate();


    const darkTheme = createTheme({
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#f4f0fa',
                        boxShadow: 'none',
                        paddingTop: '10px',
                        position: 'relative',
                    },
                },
            },
        },
    });

    const getRoutePath = (item) => {
        switch (item) {
           
            case 'Blogs':
                return '/blog';
            case 'Pricing':
                return '/pricing-page';
            default:
                return '/';
        }
    };

    const drawerWidth = 240;

    const drawer = (
        <Box onClick={() => setMobileOpen(false)} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 3 }}>
                <a href="/" aria-current="page" aria-label="home">
                    <img className="aaa-logo-landing-page" src={logo} alt="AAA logo" />
                </a>
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                    <ListItemButton onClick={() => {
                        setMobileOpen(false);
                        if (item === 'Blogs') {
                            window.open('/blog', '_blank');
                        } else {
                            navigate(getRoutePath(item));
                        }
                    }}>
                        <ListItemText primary={item} />
                    </ListItemButton>
                </ListItem>
                
                ))}
            </List>
            <Box sx={{ alignItems: 'center' }}>
                <div className="burger-menu-buttons">
                    <a href="/ec-landing-page/915432167890/Organic" className="landing-page-button demo-button demo-button-mobile full-size">For Businesses</a>
                    <a href='/signin' className="landing-page-button full-size">For Creators</a>
                </div>
            </Box>
        </Box>
    );

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar component="nav">
                <Toolbar sx={{ display: 'flex', justifyContent:'space-between'}}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ display:'block' }}
                        >
                            <a href="/" aria-current="page" aria-label="home">
                                <img className="aaa-logo-landing-page" src={logo} alt="AAA logo" />
                            </a>
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: windowWidth <= 799 ? 'block' : 'none' }}
                        >
                            <MenuIcon sx={{ color: 'black' }} />
                        </IconButton>
                    {!location.pathname.includes('/ec-landing-page') && (
                        <Box sx={{ display: windowWidth <= 799 ? 'none' : 'flex', alignItems: 'center' }}>
                            {navItems.map((item) => (
                                <Button
                                    key={item}
                                    onClick={() => item === 'Blogs' ? window.open('/blog', '_blank') : navigate(getRoutePath(item))}
                                    // to={getRoutePath(item)}
                                    sx={{
                                        color: '#56606E',
                                        textTransform: 'capitalize',
                                        mx: { xs: 2, sm: 1 },
                                        fontSize: { xs: '0.875rem', sm: '1.2rem' },
                                        fontWeight: 600,
                                    }}
                                >
                                    {item}
                                </Button>
                            ))}
                        </Box>
                    )}
                    {/* <div className="nav-items"> */}
                   
                        <Box sx={{ display: windowWidth <= 799 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className="buttons">
                                <a className="landing-page-button demo-button primary" href="/ec-landing-page/915432167890/Organic">For Businesses</a>
                                <a
                                    className="landing-page-button  hero-button" href='/signin'
                                >
                                    For Creators
                                </a>
                            </div>
                        </Box>
                    {/* </div> */}
                </Toolbar>
            </AppBar>
            <Drawer
            anchor="right"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: windowWidth <= 799 ? 'block' : 'none',
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </ThemeProvider>
    );
};

export default CommonLandingPageAppBar;
