import React from "react";
import "../../css/user-list.css";
import AdminTable from "./admin-table";

function AdminPanel() {
    return (
        <AdminTable />
    );
}

export default AdminPanel;
