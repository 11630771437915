import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "../../../css/landing-page.css";
import '../../../css/landing-page-footer.css';
import '../../../css/responsive.css';
import droneeLogo from '../../../assets/dronee-logo.svg';
import fedbankLogo from '../../../assets/fedbank-logo.svg';
import frendoLogo from '../../../assets/frendo-logo.svg';
import genieLogo from '../../../assets/genie-logo.svg';
import kitchenLogo from '../../../assets/kitchen-logo.svg';
import oomphLogo from '../../../assets/oomph-logo.svg';
import landingPageImage from '../../../assets/landing-page.png';
import landingPageImage2 from '../../../assets/landing-page-img-2.png';
import landingPageImage3 from '../../../assets/landing-page-img-3.png';
import landingPageImage4 from '../../../assets/landing-page-img-4.png';
import landingPageImage5 from '../../../assets/landing-page-img-5.png';
import landingPageImage6 from '../../../assets/landing-page-img-6.png';
import LandingAskUsPage from './landing-ask-us-page';
import LandingPageFooter from './landing-page-footer';
import Box from '@mui/material/Box';
import CommonLandingPageAppBar from '../common-landing-page-appbar';

const LandingPage = () => {
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const source = queryParams.get('source');
        const storedSource = localStorage.getItem('source');
        if (source != null && (!storedSource || source !== storedSource)) {
            localStorage.setItem('source', source);
        }
    }, [location.search]);

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CommonLandingPageAppBar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            </Box>

            {/* Landing Page Section 1 */}
            <div className="main-container">
                <div className="container hero vertical no-margin">
                    <div className="w-layout-grid hero-wrapper">
                        <div className="hero-text">
                            <h1 className="landing-page-title hero left-aligned-mobile">Level Up Your
                                <span className="title-color"> Marketing Strategy</span> with<span className="title-color"> Creators</span> as Your Allies!
                            </h1>
                            <p className="paragraph-regular semibold left-aligned-mobile">We help marketers find the perfect creator to make your next campaign a hit</p>
                            <div className="hero-cta-wrapper landing-page-heading-button">
                                <a className="landing-page-button primary" href="/ec-landing-page/915432167890/Organic">Launch Free Campaign <span className="arrow">&gt;</span></a>
                            </div>
                        </div>
                        <div className="hero-image-div">
                            <img className="right-container-img" src={landingPageImage} alt='' />
                        </div>
                    </div>
                </div>
                <div className="container vertical logos-container">
                    <p>Team up with the marketing teams of well-loved brands via Oye Creators</p>
                    <div className="w-layout-grid logos-grid">
                        <img className='logo-img' src={droneeLogo} alt='' />
                        <img className='logo-img' src={fedbankLogo} alt="" />
                        <img className='logo-img' src={frendoLogo} alt="" />
                        <img className='logo-img' src={genieLogo} alt="" />
                        <img className='logo-img' src={kitchenLogo} alt="" />
                        <img className='logo-img' src={oomphLogo} alt="" />
                    </div>
                </div>
            </div>

            {/* Landing Page Section 2 */}
            <div className="container hero vertical no-margin landing-page-section-2">
                <div className="w-layout-grid hero-wrapper">
                    <div className="hero-image-div landing-page-section-2-img">
                        <img className="right-container-img" src={landingPageImage2} alt='' />
                    </div>
                    <div className="hero-text">
                        <h1 className="landing-page-title hero left-aligned-mobile landing-page-section-2-title">Stop wasting time manually searching for creator</h1>
                        <p className="paragraph-regular semibold left-aligned-mobile landing-page-section-2-sub-title">Massively save time by letting our algorithm find the best creator for you</p>
                        <div className="hero-cta-wrapper landing-page-heading-button">
                            <a className="landing-page-button primary" href="/signin">Find Creators Now <span className="arrow">&gt;</span></a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Landing Page Section 3 */}
            <div className="container hero vertical no-margin landing-page-section-2">
                <div className="w-layout-grid hero-wrapper">
                    <div className="hero-text">
                        <h1 className="landing-page-title hero left-aligned-mobile landing-page-section-2-title">All the reporting metrics you care about</h1>
                        <p className="paragraph-regular semibold left-aligned-mobile landing-page-section-2-sub-title">Make decisions based on data - export all relevant information concluding</p>
                        <p className="paragraph-regular semibold left-aligned-mobile landing-page-section-2-sub-title landing-page-section-3-sub-title">Average Likes, Average Video Views, Emails, Phone Numbers, Follower Counts, Most used hastags and so much more</p>
                        <div className="hero-cta-wrapper landing-page-heading-button">
                            <a className="landing-page-button primary" href="/signin">Make Your Campaign <span className="arrow">&gt;</span></a>
                        </div>
                    </div>
                    <div className="hero-image-div landing-page-section-2-img">
                        <img className="right-container-img" src={landingPageImage3} alt='' />
                    </div>
                </div>
            </div>

            {/* Landing Page Section 4 */}
            <div className="landing-page-section-4">
                <div className="landing-page-section-2-container landing-page-section-2-vertical">
                    <div className="landing-page-section-4-features-text-wrapper">
                        <h2 className="landing-page-section-4-heading-2 landing-page-section-4-center">What cool spells can you cast with OyeCreators?<br></br></h2>
                    </div>
                    <div className="landing-page-section-4-w-layout-grid landing-page-section-4-features-body-wrapper landing-page-section-4-top-margin">
                        <div className="landing-page-section-4-feature-section">
                            <div className="landing-page-section-4-top-content">
                                <img className="landing-page-section-4-img" src={landingPageImage4} alt='' />
                            </div>
                            <div className="landing-page-section-4-top-content">
                                <div className="landing-page-section-4-tag landing-page-section-4-color-discovery">Virality with Clarity!</div>
                            </div>
                            <p className="landing-page-section-3-paragraph-regular landing-page-section-3-center">
                                Welcome to Oye Creators, where our AI-infused Data Fetch System turns insights into superpowers. Track everything from who's buzzing about you to the boom in your views, all at the flick of your finger<br></br>
                            </p>
                        </div>
                        <div className="landing-page-section-4-feature-section landing-page-section-4-stretched">
                            <div className="landing-page-section-4-top-content">
                                <img className="landing-page-section-4-img" src={landingPageImage5} alt='' />
                            </div>
                            <div className="landing-page-section-4-tag landing-page-section-4-color-insights">Engage and Pay!</div>
                            <p className="landing-page-section-3-paragraph-regular landing-page-section-3-center">
                                Forget subscriptions and commissions Oye Creators Introducing outcome-based pricing because we value making every penny count for you<br></br>
                            </p>
                        </div>
                        <div className="landing-page-section-4-feature-section">
                            <div className="landing-page-section-4-top-content">
                                <img className="landing-page-section-4-img" src={landingPageImage6} alt='' />
                            </div>
                            <div className="landing-page-section-4-tag">Unlimited Creators & Unlimited Projects!</div>
                            <p className="landing-page-section-3-paragraph-regular landing-page-section-3-center landing-page-section-4-no-margin">
                                With Oye Creators, enjoy unlimited access to creators. Shoot as many projects as you desire without any limitations<br></br>
                            </p>
                        </div>
                        <div id="w-node-ba63f321-84d5-e570-8726-82519f761d6c-8c7c55df" className="landing-page-section-4-top-margin-40">
                            <div className="hero-cta-wrapper landing-page-heading-button landing-page-section-4-button">
                                <a className="landing-page-button primary" href="/signin">Register As Creators<span className="arrow">&gt;</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Landing Ask Us Page Section */}
            <LandingAskUsPage />

            {/* Landing Page Footer */}
            <LandingPageFooter />
        </div>
    );
}

export default LandingPage;
