import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar } from '@mui/material';
import { DialogContent, DialogContentText } from '@mui/material';
import UserLocation from '../user/user-location';
import { getConfig } from './env-config';
import { refreshToken } from './refresh-token-repo';
import { UserDetailsContext } from '../context/user-details-context';

export default function ForceUpdateLocation({ loggedUser, openForceUpdate }) {
    const { baseUrl } = getConfig();
    const { fetchUserDetails } = useContext(UserDetailsContext);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [userLocationData, setUserLocationData] = useState({
        userId: loggedUser != null && loggedUser.userId != null ? loggedUser.userId : null,
        userLocation: loggedUser != null && loggedUser.userLocation != null ? loggedUser.userLocation : '',
        userLocationDescription: loggedUser != null && loggedUser.userLocationDescription != null ? loggedUser.userLocationDescription : '',
        placeId: loggedUser != null && loggedUser.placeId != null ? loggedUser.placeId : null,
        locality: loggedUser != null && loggedUser.locality != null ? loggedUser.locality : null
    });

    const handleChange = (fieldName, value) => {
        setUserLocationData((userLocationData) => {
            if (fieldName === 'placeId') {
                return {
                    ...userLocationData,
                    'placeId': value,
                    'userId': loggedUser.userId,
                    'locality': loggedUser.locality
                };
            }
            return {
                ...userLocationData,
                [fieldName]: value,
                'userId': loggedUser.userId,
                'locality': loggedUser.locality
            }
        });
    };

    const handleSuccessAlert = () => {
        setOpenSuccessAlert(true);
    };

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessAlert(false);
    };

    const handleUpdateLocation = async () => {
        try {
            const response = await fetch(`${baseUrl}/user/update-location?placeId=${userLocationData.placeId}&locationDescription=${userLocationData.userLocationDescription}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
            const responseBody = await response.json();
            if (!response.ok) {
                if (response.status === 499) {
                    refreshToken();
                } else {
                    console.error('Error updating location:', responseBody.message);
                }
            } else {
                handleSuccessAlert();
                await fetchUserDetails();
            }
        } catch (error) {
            console.error('Error Updating location:', error);
        }
    };

    return (
        <>
            <Dialog open={openForceUpdate} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {loggedUser && !loggedUser.locality && loggedUser.userLocation != null ? "Please add your locality to help us find the perfect project for you." : "Please Update Your Location"}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '12px !important' }}>
                    <DialogContentText id="alert-dialog-description">
                        <UserLocation fullWidth={true} handleChange={handleChange} locationDescription={userLocationData.userLocationDescription === '' && loggedUser != null && loggedUser.userLocationDescription != null ? loggedUser.userLocationDescription : userLocationData.userLocationDescription} locationError={false} fieldName='userLocationDescription'
                            placeFor='placeId' label='Location' errorMessage='Please select a location' placeholder='E.g. : Chandani Chowk, New Delhi'
                            helperText='Select your Area and City E.g. : Chandani Chowk, New Delhi' className='' />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={userLocationData.userLocationDescription === '' || userLocationData.userLocationDescription === null} onClick={handleUpdateLocation}>
                        Update Location
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSuccessAlert} autoHideDuration={3000} onClose={handleSuccessAlertClose}>
                <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                    {`Your location ${userLocationData.userLocationDescription} updated successfully`}
                </Alert>
            </Snackbar>
        </>
    );
}