import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import "../../../css/landing-page.css";

function TermsOfService() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="main" sx={{ p: 6 }}>
        <h1 className='policy-header'>Oye Creators Terms of Service</h1>
        <Typography>
          This Agreement is between you and TTL MEDIA PVT. LTD., with its registered office at No- 601/603, Plot, No-32/28, Corner Stone Premises, 6th Floor, Office, Prabhat Rd Pune, Deccan Gymkhana, Ghymkhana, Maharashtra 411004, India (hereinafter referred to as "Oye Creators"). It outlines the Terms of Service ("Terms") regarding your access to and use of content, reports, documents, products, and online services (collectively, the "Services") made available through OyeCreators.com (the "Site"). Please read this Agreement carefully as it constitutes legally binding terms between you and Oye Creators.
        </Typography>

        <h2 className='policy-header'>1. Acceptance of the Agreement</h2>
        <Typography>
          By accessing or using the Site and/or Services, you agree to abide by these Terms, representing either yourself or a legal entity. If you are entering into these Terms on behalf of a legal entity, you confirm that you have the authority to bind said entity and its affiliates to these Terms. The terms "you" and "your" refer to both individuals and entities bound by these Terms. If you disagree with these Terms, you are advised not to use the Site and Services.By accessing or using the Site and/or Services, you agree to abide by these Terms, representing either yourself or a legal entity. If you are entering into these Terms on behalf of a legal entity, you confirm that you have the authority to bind said entity and its affiliates to these Terms. The terms "you" and "your" refer to both individuals and entities bound by these Terms. If you disagree with these Terms, you are advised not to use the Site and Services.
        </Typography>

        <h2 className='policy-header'>2. Amendments to Terms</h2>
        <Typography>
          Oye Creators reserves the right to modify these Terms at any time without prior notice. It is your responsibility to review these Terms periodically. Your continued use of the Services after changes signifies your acceptance of the revised Terms. If you do not agree with the modifications, you must cease using the Services.
        </Typography>

        <h2 className='policy-header'>3. Use of Services</h2>
        <Typography>
          You are granted a limited, non-exclusive, non-transferable, and non-assignable permission to use the Site and Services. You agree not to reproduce, duplicate, copy, sell, trade, or resell the Services for any purpose, except as expressly allowed by Oye Creators or as permitted by law. You shall not attempt to reverse engineer any software part of the Services and must comply with all applicable laws and regulations.
        </Typography>

        <h2 className='policy-header'>4. Service Provision</h2>
        <Typography>
          <h4>4.1 Oye Creators may modify or cease any aspect of the Services without notice.</h4>
          <h4>4.2 Access to the Services may be disabled for non-payment or breach of these Terms.</h4>
          <h4>4.3 Use of the Services is limited to one account per individual or legal entity.</h4>
        </Typography>

        <h2 className='policy-header'>5. Security</h2>
        <Typography>
          You are responsible for maintaining the confidentiality of your account information and for all activities under your account. You agree to provide accurate registration information and to update it as necessary. You will immediately notify Oye Creators of any unauthorized use of your account.
        </Typography>

        <h2 className='policy-header'>6. Privacy and Data Protection</h2>
        <Typography>
          <h4>6.1 Oye Creators complies with the Indian Data Protection Regulations.</h4>
          <h4>6.2 You must comply with applicable data protection laws when processing personal data.</h4>
          <h4>6.3 Personal data processed in personal or household activities may be exempt from data protection laws.</h4>
          <h4>6.4 In legal entity contexts, GDPR-like obligations apply, ensuring data protection compliance independently.</h4>
          <h4>6.5 Oye Creators uses cookies for enhancing user experience and service improvement.</h4 >
        </Typography >

        <h2 className='policy-header'>7. Charged Services</h2>
        <Typography>
          <h4>7.1 Some Services may incur charges, detailed in INR unless stated otherwise.</h4>
          <h4>7.2 You agree to pay all applicable charges and fees, with billing procedures subject to change.</h4>
          <h4>7.3 No refunds will be provided for partial use, service upgrade/downgrade, or external service failures.</h4>
        </Typography>

        <h2 className='policy-header'>8. Content Use</h2>
        <Typography>
          You may use the data/content from the Services for informational purposes only, acknowledging Oye Creators as the source. Misuse of the data/content is strictly prohibited.
        </Typography>

        <h2 className='policy-header'>9. Restrictions</h2>
        <Typography>
          Specific uses of the Service are outlined, with prohibitions against unauthorized modifications, reselling, harmful activities, and infringement of third-party rights.
        </Typography>

        <h2 className='policy-header'>10. Publicity</h2>
        <Typography>
          You permit Oye Creators to use your name and logo in its customer list. All other uses of each party's trademarks require written consent.
        </Typography>

        <h2 className='policy-header'>11. Cancellation and Termination</h2>
        <Typography>
          Services can be cancelled at any time. Oye Creators may terminate your access for cause, including non-payment or breach of Terms, resulting in loss of access to Services and data.
        </Typography>

        <h2 className='policy-header'>12. Proprietary Rights</h2>
        <Typography>
          The Site and Services are the exclusive property of Oye Creators. You agree not to infringe on any proprietary rights and acknowledge that third-party marks are owned by their respective owners.
        </Typography>

        <h2 className='policy-header'>13. Contact Us</h2>
        <Typography>
          For questions about these Terms, contact us at <a href=" mailto:support@OyeCreators.com " >support@OyeCreators.com</a> or at the provided address.
        </Typography>

        <h2 className='policy-header'>14. Disclaimer and Liability Limitation</h2>
        <Typography>
          Oye Creators disclaims all warranties to the extent permitted by law, providing the Services on an "as is" basis. Liability for damages is limited to the fee paid for access to the Services, excluding indirect or consequential damages.
        </Typography>
      </Box >
    </Box >
  );
}

export default TermsOfService;