import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Chip, Paper, Tooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fetchCategories } from '../common/common-apis-repo';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LoginAutocomplete({ handleChange, categoriesError, selectedCategories }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories(setCategories);
    }, []);

    const categoriesTheme = createTheme({
        components: {
            MuiChip: {
                styleOverrides: {
                    label: {
                        maxWidth: '60px',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                    }
                }
            }
        }
    });

    return (
        <>
            <div className='categories info' >
                <ThemeProvider theme={categoriesTheme}>
                    <Autocomplete
                        className='categories-dropdown'
                        multiple
                        limitTags={2}
                        id="categories"
                        options={categories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                            if (Array.isArray(option) && option.length === 0) {
                                return '';
                            }
                            return option;
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        style={{ width: 378 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Categories"
                                error={categoriesError && !selectedCategories.length}
                                helperText={categoriesError && !selectedCategories.length ? 'Please select at least one category' : ''}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleChange('selectedCategories', newValue);
                        }}
                        value={selectedCategories}
                        renderTags={(value, getTagProps) => (
                            <>
                                {value.slice(0, 2).map((option, index) => (
                                    <Chip
                                        key={index}
                                        title={option}
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))}
                                {value.length > 2 && (
                                    <Chip
                                        label={`${value.length - 2}+`}
                                        {...getTagProps({ index: value.length - 1 })}
                                    />
                                )}
                            </>
                        )}
                        PaperComponent={({ children }) => (
                            <Paper style={{ maxHeight: '100px', overflowY: 'hidden' }}>
                                {children}
                            </Paper>
                        )}
                    />
                </ThemeProvider>
                <div className='infoIcon'>
                    <Tooltip title='Categories info' >
                        <IconButton onClick={() => { }}>
                            <InfoOutlinedIcon className="infoIconStyle" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </>
    )
};
