export const getConfig = () => {
    let baseUrl = '';
    let appId = '';
    const currentUrl = window.location.href;
    if (currentUrl.includes('localhost')) {
        baseUrl = "http://localhost:8080"
        appId = "OYKLEOTMGQVGEFUC0LQI"
    } else if (currentUrl.includes('development.oyecreators.com')) {
        baseUrl = "https://development-api.oyecreators.com"
        appId = "OYKLEOTMGQVGEFUC0LQI"
    } else if (currentUrl.includes('shell.oyecreators.com')) {
        baseUrl = "https://staging-api.oyecreators.com"
        appId = "OYKLEOTMGQVGEFUC0LQI"
    } else {
        baseUrl = "https://api.oyecreators.com"
        appId = "TEEFHOAFD8CGNFXK5IH2"
    }
    return { baseUrl, appId }
}
