import { Box } from '@mui/material';
import React from 'react'
import { Outlet } from 'react-router-dom';
import { DrawerHeader } from '../../../mui/appbar-style-component';

function Helpdesk() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 1, padding: '58px' }}>
                <DrawerHeader />
                <Outlet />
                <div>
                   Coming soon...
                </div>
            </Box>
        </Box>
    )
}

export default Helpdesk;
