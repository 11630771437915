export const GOOGLE_MAPS_API_KEY = 'AIzaSyBoaNey12RUDNZaEk1U3Ndvc-IM4JJgkOQ';

export function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export const autocompleteService = { current: null };
