import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Button, CircularProgress, InputBase, Popover, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import UpdateStatusDialog from './update-status-dialog';
import { getLeadsCampaigns } from '../leads-helper';

function LeadsTableToolbar(props) {
    const { windowWidth, leadsTags, setCurrentPage, setGoToPage, selected, leadsStatus, setUpdateSuccess, setSearchString, setStatusSuccessAlert,
        setStatusFailedAlert, setSelected, campaignFilterValue, setCampaignFilterValue, handleExport, setIsTagEditing, setTagUpdated, loading, apiFailed,
        setTagSuccessAlert, setTagFailedAlert, downloading
    } = props;
    const [openBulkUpdateDialog, setOpenBulkUpdateDialog] = useState(false);
    const [selectedEdit, setSelectedEdit] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [leadsCampaigns, setLeadsCampaigns] = useState([]);
    const [optionsOpen, setOptionsOpen] = useState(false);

    useEffect(() => {
        getLeadsCampaigns(setLeadsCampaigns);
    }, []);

    const handleCampaignNameChange = (event, newValue) => {
        setCampaignFilterValue(newValue);
        setAnchorEl(null);
        setCurrentPage(1);
        setGoToPage(1);
    }

    const handleCampFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCampFilterClose = () => {
        setAnchorEl(null);
    };

    const openCampaignFilter = Boolean(anchorEl);
    const id = openCampaignFilter ? 'simple-popover' : undefined;

    const handleAutocompleteOpen = () => {
        setOptionsOpen(true);
    };

    const handleAutocompleteClose = () => {
        setOptionsOpen(false);
    };

    const debouncedHandleChange = useMemo(
        () => debounce((value) => setSearchString(value), 300),
        [setSearchString]
    );

    useEffect(() => {
        return () => {
            debouncedHandleChange.cancel();
        };
    }, [debouncedHandleChange]);


    const handleSearchChange = (event) => {
        const value = event.target.value;
        debouncedHandleChange(value);
        setCurrentPage(1);
        setGoToPage(1);
    };

    const handleBulkUpdateOpen = (event, editValue) => {
        setSelectedEdit(editValue);
        setOpenBulkUpdateDialog(true);
    };

    const handleBulkUpdateClose = () => {
        setOpenBulkUpdateDialog(false);
    };

    const handleCampFilterClear = () => {
        setCurrentPage(1);
        setGoToPage(1);
        setCampaignFilterValue(null);
    }

    return (
        <>
            {windowWidth <= 799 ?
                <>
                    <Toolbar
                        sx={{
                            padding: '20px 20px 20px 20px',
                            display: 'flex', flexDirection: 'column',
                            ...(selected.length > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}
                    >
                        {selected.length > 0 ? (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {selected.length} selected
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    color: '#3b4758',
                                    fontFamily: 'DM Sans',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    opacity: 1,
                                    paddingBottom: '12px'
                                }}
                            >
                                Leads Management
                            </Typography>
                        )}

                        {selected.length > 0 ? (
                            <div className='leads-common-icons'>
                                <Tooltip title="Add Tag">
                                    <Button onClick={(e) => handleBulkUpdateOpen(e, "Update Tag")}
                                        startIcon={<LocalOfferOutlinedIcon />}>
                                        Add Tag
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Change Status">
                                    <Button onClick={(e) => handleBulkUpdateOpen(e, "Change Status")}
                                        startIcon={<EditIcon />}>
                                        Change Status
                                    </Button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className='leads-toolbar-actions'>
                                <div className='leads-search-bar'>
                                    <div style={{ marginRight: '8px' }}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search By Full Name, Email, Phone No..."
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={handleSearchChange}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className='leads-top-filter'>
                                    {campaignFilterValue != null
                                        ? <IconButton aria-describedby={id} onClick={handleCampFilterClear}>
                                            <FilterListOffIcon />
                                        </IconButton>
                                        : <IconButton aria-describedby={id} onClick={handleCampFilterClick}>
                                            <FilterListIcon />
                                        </IconButton>
                                    }
                                    <Popover id={id} open={openCampaignFilter} anchorEl={anchorEl}
                                        onClose={handleCampFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    >
                                        <Typography sx={{ p: optionsOpen ? '30px 30px 187px' : 3 }}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={leadsCampaigns}
                                                sx={{ width: 300 }}
                                                onChange={handleCampaignNameChange}
                                                onOpen={handleAutocompleteOpen}
                                                onClose={handleAutocompleteClose}
                                                value={campaignFilterValue}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Campaign Name" />
                                                )}
                                            />
                                        </Typography>
                                    </Popover>
                                </div>
                                {downloading
                                    ? <CircularProgress size={24} />
                                    : <IconButton disabled={apiFailed || loading} onClick={handleExport}>
                                        <FileDownloadIcon />
                                    </IconButton>
                                }
                            </div>
                        )
                        }
                    </Toolbar >
                </>
                : <>
                    <Toolbar
                        sx={{
                            padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                            ...(selected.length > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 auto' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length} selected
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        flex: '1 1 auto',
                                        color: '#3b4758',
                                        fontFamily: 'DM Sans',
                                        fontSize: '1.5rem',
                                        fontWeight: 600,
                                        opacity: 1
                                    }}
                                >
                                    Leads Management
                                </Typography>
                            )}
                        </div>
                        {selected.length > 0 ? (
                            <div className='leads-common-icons' style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Add Tag">
                                    <Button onClick={(e) => handleBulkUpdateOpen(e, "Add Tag")}
                                        startIcon={<LocalOfferOutlinedIcon />}>
                                        Add Tag
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Change Status">
                                    <Button onClick={(e) => handleBulkUpdateOpen(e, "Change Status")}
                                        startIcon={<EditIcon />}>
                                        Change Status
                                    </Button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: 'flex-end' }}>
                                <div className='leads-search-bar' style={{ display: 'flex', alignItems: 'center' }}>
                                    <SearchIcon style={{ marginRight: '8px' }} />
                                    <InputBase
                                        placeholder="Search By Full Name, Email, Phone No..."
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={handleSearchChange}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className='leads-top-filter' style={{ display: 'flex', alignItems: 'center' }}>
                                    {campaignFilterValue != null
                                        ? <IconButton aria-describedby={id} onClick={handleCampFilterClear}>
                                            <FilterListOffIcon />
                                        </IconButton>
                                        : <IconButton aria-describedby={id} onClick={handleCampFilterClick}>
                                            <FilterListIcon />
                                        </IconButton>
                                    }
                                    <Popover id={id} open={openCampaignFilter} anchorEl={anchorEl}
                                        onClose={handleCampFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    >
                                        <Typography sx={{ p: optionsOpen ? '30px 30px 187px' : 3 }}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={leadsCampaigns}
                                                sx={{ width: 300 }}
                                                onChange={handleCampaignNameChange}
                                                onOpen={handleAutocompleteOpen}
                                                onClose={handleAutocompleteClose}
                                                value={campaignFilterValue}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Campaign Name" />
                                                )}
                                            />
                                        </Typography>
                                    </Popover>
                                </div>
                                {downloading
                                    ? <CircularProgress size={24} />
                                    : <IconButton disabled={apiFailed || loading} onClick={handleExport}>
                                        <FileDownloadIcon />
                                    </IconButton>
                                }
                            </div>
                        )}
                    </Toolbar>
                </>
            }
            {openBulkUpdateDialog &&
                (<UpdateStatusDialog selected={selected} selectedEdit={selectedEdit} leadsTags={leadsTags} leadsStatus={leadsStatus} setUpdateSuccess={setUpdateSuccess}
                    openBulkUpdateDialog={openBulkUpdateDialog} handleBulkUpdateClose={handleBulkUpdateClose} setStatusSuccessAlert={setStatusSuccessAlert} setStatusFailedAlert={setStatusFailedAlert}
                    setSelected={setSelected} setIsTagEditing={setIsTagEditing} setTagUpdated={setTagUpdated} setTagSuccessAlert={setTagSuccessAlert} setTagFailedAlert={setTagFailedAlert} />
                )}
        </>

    )
}
LeadsTableToolbar.propTypes = {
    windowWidth: PropTypes.any,
    leadsTags: PropTypes.any,
    setCurrentPage: PropTypes.any,
    setGoToPage: PropTypes.any,
    selected: PropTypes.any,
    leadsStatus: PropTypes.any,
    setUpdateSuccess: PropTypes.any,
    setSearchString: PropTypes.any,
    setStatusSuccessAlert: PropTypes.any,
    setStatusFailedAlert: PropTypes.any,
    setSelected: PropTypes.any,
    campaignFilterValue: PropTypes.any,
    setCampaignFilterValue: PropTypes.any,
    handleExport: PropTypes.any,
    setTagUpdated: PropTypes.any,
    setIsTagEditing: PropTypes.any,
    apiFailed: PropTypes.any,
    loading: PropTypes.any,
    setTagFailedAlert: PropTypes.any,
    setTagSuccessAlert: PropTypes.any,
    downloading: PropTypes.any,
};
export default LeadsTableToolbar;
