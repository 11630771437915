import React, { useState,useEffect } from 'react';
import { styled } from '@mui/material';

const Input = styled('input')`
  font-size: 1rem;
  padding: 8px;
  width: 60px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  margin-right: 4px;
`;

const NumberInput = ({ initialValue = 0, onChange }) => {
  const [value, setValue] = useState(initialValue);
   useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
    
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    let newValue;
    if (Number.isInteger(value)) {
      newValue = value + 1;
    } else {
      const intValue = Math.round(value * 100);
      const newIntValue = intValue + 1;
      newValue = (newIntValue / 100).toFixed(2);
    }
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    let newValue;
    if (Number.isInteger(value) && value > 0) {
      newValue = value - 1; 
    } else if (!Number.isInteger(value) && parseFloat(value) > 0.01) {
      const intValue = Math.round(value * 100);
      const newIntValue = intValue - 1;
      newValue = (newIntValue / 100).toFixed(2);
    } else {
      newValue = 0;
    }
  
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  
  return (
    <div className='number-input'>
      <Input
        type="text"
        value={value}
        onChange={handleChange}
      />
      <div className='increment-button-container'>
        <button className="increment-button" onClick={handleIncrement}>▴</button>
        <button className="increment-button" onClick={handleDecrement}>▾</button>
      </div>
    </div>
  );
};

export default NumberInput;