import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, DialogActions, TextField, Alert } from '@mui/material';
import { assignAgent, fetchAgents } from '../ec-leads-helper';
import { useLeadFilters } from '../../context/eclead-filter-context';

function EcAssignAgent({ open, setOpen, selectedECLeads }) {
    const [agentsList, setAgentsList] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const { leadFilters, setLeadFilters } = useLeadFilters();
    useEffect(() => {
        fetchAgents(setAgentsList, 'Assign Agent');
    }, []);

    const handleLeadFilterChange = (fieldName, value) => {
        setLeadFilters((leadFilters) => ({
            ...leadFilters,
            [fieldName]: value,
        }));
    };

    const handleAssignAgent = async () => {
        if (leadFilters.agent && selectedECLeads.length > 0) {
            try {
                const success = await assignAgent(leadFilters.agent, selectedECLeads);
                if (success) {
                    setAlertType('success');
                    setAlertOpen(true);
                    setLeadFilters((leadFilters) => ({
                        ...leadFilters,
                        agent: null,
                    }));
                    setTimeout(() => {
                        setAlertOpen(false);
                        setOpen(false);
                        window.location.reload();
                    }, 1000);
                } else {
                    setAlertType('error');
                    setAlertOpen(true);
                }
            } catch (error) {
                setAlertType('error');
                setAlertOpen(true);
            }
        } else {
            console.error('No agent selected');
        }
    };

    const handleClose = () => {
        setLeadFilters((leadFilters) => ({
            ...leadFilters,
            agent: null,
        }));
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm' aria-labelledby="responsive-dialog-title">
                <DialogTitle sx={{ padding: '28px 30px', color: '#3b4758', fontFamily: 'DM Sans', fontSize: '1.5rem', fontWeight: 600, opacity: 1 }} id="responsive-dialog-title">
                    Assign Agent
                    <IconButton aria-label="close" onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='ec-lead-filter-location-row'>
                        <Autocomplete
                            tabIndex={10}
                            disablePortal
                            id="combo-box-agent"
                            name="agent"
                            value={
                                agentsList.find((option) => option.id === leadFilters.agent) || null
                            }
                            options={agentsList}
                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                            onChange={(e, value) =>
                                handleLeadFilterChange('agent', value ? value.id : null)
                            }
                            renderInput={(params) => <TextField {...params} label="Agent" />}
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{ padding: '12px 30px 28px' }}>
                    <Button autoFocus variant="outlined" onClick={handleClose} sx={{
                        color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' }
                    }} >
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleAssignAgent} sx={{
                        backgroundColor: '#e563a4', textTransform: 'capitalize', '&:hover': { backgroundColor: '#e326a4' }
                    }} disabled={!leadFilters.agent} >
                        Assign Agent
                    </Button>
                </DialogActions>
            </Dialog>

            {alertOpen && (
                <Alert
                    severity={alertType}
                    variant="filled"
                    sx={{
                        width: '25%',
                        position: 'fixed',
                        bottom: 16,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1201,
                        backgroundColor: alertType === 'success' ? 'lightgreen' : 'lightcoral',
                        color: alertType === 'success' ? 'black' : 'white'
                    }}
                >
                    {alertType === 'success' ? 'Agent assigned successfully!' : 'Failed to assign agent'}
                </Alert>
            )}
        </>
    );
}

export default EcAssignAgent;