import { getConfig } from "../../common/env-config";
import { refreshToken } from "../../common/refresh-token-repo";

const { baseUrl } = getConfig();

export const getPlatformGrowthOverview = async (setPlatformGrowth, setLoading, dateFilterValue) => {
    try {
        setLoading(true);
        let filters = {};
        if (dateFilterValue != null) {
            filters.dateRange = dateFilterValue;
        }

        const response = await fetch(`${baseUrl}/admin/platform-growth-overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters)
        });

        const data = await response.json();
        if (response.ok) {
            setPlatformGrowth({
                totalCreators: data.totalCreators,
                totalCampaigns: data.totalCampaigns,
                enterpriseClients: data.enterpriseClients,
                totalRevenue: data.totalRevenue
            });
            setLoading(false);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Failed To Fetch Platform Growth, Error: ', data.message);
        }
    } catch (error) {
        console.error('Failed To Fetch Platform Growth, Error: ', error);
    }
};

export const getLeadsOverview = async (setLeadsOverview, setLoading, dateFilterValue) => {
    try {
        setLoading(true);
        let filters = {};
        if (dateFilterValue != null) {
            filters.dateRange = dateFilterValue;
        }

        const response = await fetch(`${baseUrl}/admin/leads-overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters)
        });

        const data = await response.json();
        if (response.ok) {
            setLeadsOverview({
                newLeads: data.newLeads,
                notifyLeads: data.notifyLeads,
                inDiscussionLeads: data.inDiscussionLeads,
                rejectedLeads: data.rejectedLeads,
                registeredLeads: data.registeredLeads
            });
            setLoading(false);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Failed To Fetch Leads Overview, Error: ', data.message);
        }
    } catch (error) {
        console.error('Failed To Fetch Leads Overview, Error: ', error);
    }
};

export const getCreatorsOverview = async (setCreatorOverview, setLoading, dateFilterValue) => {
    try {
        setLoading(true);
        let filters = {};
        if (dateFilterValue != null) {
            filters.dateRange = dateFilterValue;
        }

        const response = await fetch(`${baseUrl}/admin/creators-overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters)
        });

        const data = await response.json();
        if (response.ok) {
            setCreatorOverview({
                creators: data.creators,
                activeCreators: data.activeCreators,
                privateProfile: data.privateProfile,
                incompleteProfile: data.incompleteProfile
            });
            setLoading(false);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Failed To Fetch Creators Overview, Error: ', data.message);
        }
    } catch (error) {
        console.error('Failed To Fetch Creators Overview, Error: ', error);
    }
};

export const getClientCampaignOverview = async (setClientCampaignOverview, setLoading, dateFilterValue) => {
    try {
        setLoading(true);
        let filters = {};
        if (dateFilterValue != null) {
            filters.dateRange = dateFilterValue;
        }

        const response = await fetch(`${baseUrl}/admin/client-campaign-overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters)
        });

        const data = await response.json();
        if (response.ok) {
            setClientCampaignOverview({
                totalClients: data.totalClients,
                activeCampaigns: data.activeCampaigns,
                pendingCampaigns: data.pendingCampaigns,
                totalApplications: data.totalApplications
            });
            setLoading(false);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Failed To Fetch Client Campaign Overview, Error: ', data.message);
        }
    } catch (error) {
        console.error('Failed To Fetch Client Campaign Overview, Error: ', error);
    }
};
