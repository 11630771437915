import React from "react";
import PropTypes from "prop-types";
import { Toolbar, Typography, Button, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { getConfig } from "../common/env-config";
import { refreshToken } from "../common/refresh-token-repo";
const { baseUrl } = getConfig();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ApplicantsTableToolbar = ({ selectedApplicants, campaignId, formatedSelectedApplicants }) => {
  const numSelected = selectedApplicants.length;
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNotifyApplicants = async () => {
    try {
      const response = await fetch(`${baseUrl}/admin/notify-to-selected-applicants/${campaignId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(formatedSelectedApplicants),
      });

      const data = await response.json();
      if (response.ok) {
        if (data.success === true) {
          setAlertMessage("Notification sent successfully!");
        } else if (data.success === false) {
          setAlertMessage("Notification already sent to the applicant");
        } else {
          setAlertMessage(data.message);
        }
        handleClickOpen();
      } else if (response.status === 499) {
        refreshToken();
      } else {
        console.error("Failed To Send Notifications :", data.message);
      }
    } catch (error) {
      console.error("An error occurred while sending notifications:", error);
    }
  };

  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Notification Status
        </DialogTitle>
        <DialogContent>
          <Typography 
            gutterBottom 
            // sx={{ color: 'rgba(0, 0, 0, 0.6)' }} 
          >
            {alertMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            CLOSE
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {
          numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : null
        }
        {numSelected > 0 && (
          <Tooltip title="Notify to selected applicants">
            <Button
              onClick={handleNotifyApplicants}
              startIcon={<NotificationsIcon />}
            >
              Notify
            </Button>
          </Tooltip>
        )}
      </Toolbar>
    </>
  );
};

ApplicantsTableToolbar.propTypes = {
  selectedApplicants: PropTypes.array.isRequired,
  campaignId: PropTypes.any
};

export default ApplicantsTableToolbar;