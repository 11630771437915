
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';

const { baseUrl } = getConfig();

export const fetchCategories = async (setCategories) => {
    try {
        const response = await fetch(`${baseUrl}/interests`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setCategories(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching categories:', data.message);
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
};

export const getRegisterCities = async (setCities, type, states = [], cities = []) => {
    try {
        const stateParam = states.length > 0 ? `states=${states.join(',')}` : '';
        const cityParam = cities.length > 0 ? `cities=${cities.join(',')}` : '';

        const queryParam = [stateParam, cityParam].filter(Boolean).join('&'); 

        const url = type === 'Creator'  
            ? `/get-creator-location${queryParam ? '?' + queryParam : ''}` 
            : `/admin/get-ec-location${queryParam ? '?' + queryParam : ''}`;

        const response = await fetch(`${baseUrl}${url}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setCities(data); 
        } else if (response.status === 499) {
            refreshToken(); 
        } else {
            console.error('Error fetching register cities:', data.message);
        }
    } catch (error) {
        console.error('Error fetching register cities:', error);
    }
};
