import { Pagination } from '@mui/material';
import React from 'react';

function CommonPagination(props) {
    return (
        <Pagination
            sx={{display: 'flex', justifyContent: 'center'}}
            count={props.totalPages}
            color="primary"
            page={props.currentPage}
            onChange={props.handlePageChange}
            boundaryCount={1}
            siblingCount={0}
            classes={{ root: 'custom-pagination-root' }}
        />
    )
}

export default CommonPagination;
