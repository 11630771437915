import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';
const { baseUrl } = getConfig();

const useStartPath = () => {
  const [startPath, setStartPath] = useState('');
  useEffect(() => {
    const pathname = window.location.pathname;
    const pathSegments = pathname.split('/');
    setStartPath('/' + pathSegments[1]);
  }, []);
  return startPath;
};

const createFormData = (campaignName, description, specifyKeywords, projectType, categoryTypes, customCategory, mandatoryKeywords, campaignLocations, placeIds, creators, status, remark, firstRowOptions, secondRowOptions, lastDate, attachments, prevAttachments, brandHandle, campaignOwner, createdBy) => {
  const formData = new FormData();
  formData.append('campaignDetails', JSON.stringify({
    campaignName: campaignName,
    description: description,
    projectType: projectType,
    categoryTypes: categoryTypes,
    customCategory: customCategory,
    specifyKeywords: specifyKeywords,
    mandatoryKeywords: mandatoryKeywords,
    campaignLocations: campaignLocations,
    placeIds: placeIds,
    creators: creators,
    status: status,
    remark: remark,
    productionAvailable: firstRowOptions[0].value,
    travelAllowance: firstRowOptions[1].value,
    stayAllowance: secondRowOptions[0].value,
    foodAllowance: secondRowOptions[1].value,
    prevAttachments: prevAttachments,
    lastDate: lastDate,
    brandHandle: brandHandle,
    campaignOwner: campaignOwner,
    createdBy: createdBy
  }));

  if (attachments) {
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
  }

  return formData;
};

export const useCreateCampaign = () => {
  const navigate = useNavigate();
  const startPath = useStartPath();
  const createCampaign = useCallback(async (campaignName, description, specifyKeywords, projectType, categoryTypes, customCategory, mandatoryKeywords, campaignLocations, placeIds, creators, status, remark, firstRowOptions, secondRowOptions, lastDate, attachments, prevAttachments, setAlertOpen, setAlertMessage, setAlertSeverity, brandHandle, campaignOwner, createdBy) => {

    try {
      const formData = createFormData(campaignName, description, specifyKeywords, projectType, categoryTypes, customCategory, mandatoryKeywords, campaignLocations, placeIds, creators, status, remark, firstRowOptions, secondRowOptions, lastDate, attachments, null, brandHandle, campaignOwner, createdBy);
      const response = await fetch(`${baseUrl}/campaign/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: formData
      });

      if (response.ok) {
        if (status === "Draft") {
          setAlertMessage('Campaign saved as draft successfully');
        } else {
          setAlertMessage('Campaign created successfully');
        }
        setAlertSeverity('success');
        setAlertOpen(true);
        setTimeout(() => {
          if (startPath === '/dashboard') {
            navigate('/all-campaigns/live');
          } else {
            navigate(`${startPath}/${status.toLowerCase()}`);
          }
        }, 700);
      } else {
        setAlertMessage('Failed to create campaign');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error: ' + error.message);
    }
  }, [navigate, startPath]);

  return createCampaign;
};

export const useUpdateCampaign = () => {
  const navigate = useNavigate();
  const startPath = useStartPath();

  const updateCampaign = useCallback(async (campaignId, campaignName, description, specifyKeywords, projectType, categoryTypes, customCategory, mandatoryKeywords, campaignLocations, placeIds, creators, status, remark, firstRowOptions, secondRowOptions, lastDate, attachments, prevAttachments, setAlertOpen, setAlertMessage, setAlertSeverity, brandHandle, campaignOwner, createdBy) => {
    try {
      const formData = createFormData(campaignName, description, specifyKeywords, projectType, categoryTypes, customCategory, mandatoryKeywords, campaignLocations, placeIds, creators, status, remark, firstRowOptions, secondRowOptions, lastDate, attachments, prevAttachments, brandHandle, campaignOwner, createdBy);

      const response = await fetch(`${baseUrl}/campaign/update/${campaignId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: formData
      });

      if (response.ok) {
        setAlertMessage('Campaign updated successfully');
        setAlertSeverity('success');
        setAlertOpen(true);
        setTimeout(() => {
          if (startPath === '/dashboard') {
            navigate('/all-campaigns/live');
          } else {
            navigate(`${startPath}/${status.toLowerCase()}`);
          }
        }, 700);
      } else {
        setAlertMessage('Failed to update campaign');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error: ' + error.message);
    }
  }, [navigate, startPath]);

  return updateCampaign;
};

export const useUpdateCampaignStatus = () => {
  const navigate = useNavigate();
  const startPath = useStartPath();

  const updateCampaignsStatus = useCallback(async (campaignId, status, setAlertOpen, setAlertMessage, setAlertSeverity) => {
    try {
      const url = `${baseUrl}/campaign/update/status/${campaignId}/${status}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      if (response.ok) {
        setAlertMessage('Campaign accepted successfully');
        setAlertSeverity('success');
        setAlertOpen(true);
        setTimeout(() => {
          if (startPath === '/dashboard') {
            navigate('/all-campaigns/live');
          } else {
            navigate(`${startPath}/live`);
          }
        }, 700);
      } else {
        setAlertMessage('Failed to accept campaign');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error: ' + error.message);
    }
  }, [navigate, startPath]);

  return updateCampaignsStatus;
};

export const getCampaignsList = async (pathname, setCampaignList, setIsLoading, pageNumber, pageSize) => {
  try {
    let activeTab;
    if (pathname.includes('live')) {
      activeTab = 'Live';
    } else if (pathname.includes('completed')) {
      activeTab = 'Completed';
    } else if (pathname.includes('pending')) {
      activeTab = 'Pending';
    } else if (pathname.includes('draft')) {
      activeTab = 'Draft';
    } else {
      activeTab = 'Live';
    }
    const url = pathname.includes('/all-campaigns') ?
      `${baseUrl}/all-campaigns?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${activeTab}` :
      `${baseUrl}/my-campaigns?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${activeTab}`;

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    const data = await response.json();
    if (response.ok) {
      setCampaignList(data);
    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error('Error fetching campaigns:', data.message);
    }
  } catch (error) {
    console.error('Error fetching campaigns:', error);
  } finally {
    if (setIsLoading != null) {
      setIsLoading(false);
    }
  }
};

export const downloadFiles = async (fileId, fileName) => {
  try {
    const response = await fetch(`${baseUrl}/campaign/file/download/${fileId}/${fileName}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to download file');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error while downloading file');
  }
};

export const getCampaign = async (campaignId, setCampaign) => {
  try {
    const response = await fetch(`${baseUrl}/campaign/${campaignId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });
    const data = await response.json();
    if (response.ok) {
      setCampaign(data);
      return data;
    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error('Error fetching campaign:', data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching campaign:', error);
  }
};

export const handleApplyCampaign = async (e, campaignId, isInstaAccountPrivate, setApply, setOpenInstaHandleAlert, setAlertOpen, setAlertMessage, setAlertSeverity) => {
  const appliedStatus = e.target.textContent.trim() === 'Yes' ? 'Withdraw' : e.target.textContent.trim();
  if (isInstaAccountPrivate !== null && !isInstaAccountPrivate) {
    await applyCampaign(appliedStatus === 'Apply Now' ? 'Apply' : appliedStatus, campaignId, setApply, setAlertOpen, setAlertMessage, setAlertSeverity);
  } else {
    setOpenInstaHandleAlert(true);
  }
}

export const fetchCampaignApplication = async (setLoading, campaignId, currentApplicationsPage, rowsPerPage,
  setTotalResults, setApiFailed, setTotalPages, setCreators, acceptedApplication, rejectedApplication, fitStatus, maybeStatus, notFitStatus, followersFilterValue, engagement) => {
  try {
    setLoading(true);
    let filters = {
      campaignId: campaignId,
      acceptedApplication: acceptedApplication,
      rejectedApplication: rejectedApplication,
      fitStatus: fitStatus,
      maybeStatus: maybeStatus,
      notFitStatus: notFitStatus,
      engagement: engagement,
      pageNo: currentApplicationsPage,
      pageSize: rowsPerPage,
    };

    if (followersFilterValue.min != null && followersFilterValue.max != null &&
      followersFilterValue.min !== '' && followersFilterValue.max !== '') {
      filters.followersRange = followersFilterValue
    }
    const response = await fetch(`${baseUrl}/admin-ec/applied-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify(filters)
    });

    const data = await response.json();
    if (response.ok) {
      const transformedResponse = {
        totalResults: data.totalResults,
        creators: data.creators.map(item => ({
          instaId: item.instaId,
          instaHandle: item.instaHandle,
          instaFullName: item.instaFullName,
          followersCount: item.followersCount,
          mediaCount: item.mediaCount,
          totalLikes: item.totalLikes,
          totalComments: item.totalComments,
          engagement: item.engagement,
          engagementRate: item.engagementRate,
          noOfPost: item.noOfPost,
          profilePictureId: item.profilePictureId,
          bio: item.bio,
          creatorRates: item.creatorRates,
          creatorScore: item.creatorScore,
          avgLike: item.avgLike,
          avgComment: item.avgComment,
          creatorId: item.creatorId,
          creatorFullName: item.creatorFullName,
          applicationStatus: item.applicationStatus,
          appliedStatus: item.appliedStatus,
          appliedDate: item.appliedDate,
          actionPerformBy: {
            userId: item.actionPerformBy != null ? item.actionPerformBy.userId : null,
            fullName: item.actionPerformBy != null ? item.actionPerformBy.fullName : null
          },
          acceptDate: item.acceptDate,
          notificationSent: item.notificationSent
        }))
      };
      setTotalResults(transformedResponse.totalResults);
      setApiFailed(transformedResponse.creators.length === 0);
      setTotalPages(Math.ceil(transformedResponse.totalResults / rowsPerPage));
      setCreators(transformedResponse.creators);
    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error('Error fetching campaign application:', data.message);
      setApiFailed(true);
    }
    setLoading(false);
  } catch (error) {
    console.error('Error fetching campaign application:', error);
    setApiFailed(true);
    setLoading(false);
  }
};

const applyCampaign = async (appliedStatus, campaignId, setApply, setAlertOpen, setAlertMessage, setAlertSeverity) => {
  try {
    const response = await fetch(`${baseUrl}/apply-campaign?campaignId=${campaignId}&appliedStatus=${appliedStatus}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });

    const data = await response.json();
    if (response.ok) {
      setApply(Math.random().toString(36).substring(7));
      if (appliedStatus === 'Apply') {
        setAlertMessage('Campaign applied successfully');
      } else if (appliedStatus === 'Withdraw') {
        setAlertMessage('Campaign withdrawn successfully');
      }
      setAlertSeverity('success');
      setAlertOpen(true);
    } else if (response.status === 499) {
      refreshToken();
    } else {
      setAlertMessage('Failed to apply for campaign');
      setAlertSeverity('error');
      setAlertOpen(true);
      console.error('Failed To Apply For Campaign :', data.message);
    }
  } catch (error) {
    console.error('Error applying for campaign:', error);
    setAlertMessage('Failed to apply for campaign');
    setAlertSeverity('error');
    setAlertOpen(true);
  }
};

export const acceptCampaignApplication = async (campaignId, creatorId, acceptStatus, setAccept) => {
  const response = await fetch(`${baseUrl}/admin/accept-campaign-application?campaignId=${campaignId}&creatorId=${creatorId}&acceptStatus=${acceptStatus}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  const data = await response.json();
  if (response.ok) {
    setAccept(Math.random().toString(36).substring(7));
  } else if (response.status === 499) {
    refreshToken();
  } else {
    console.error('Failed To Apply For Campaign :', data.message);
  }
};

export const exportCampaignApplication = async (campaignId, acceptedApplication, rejectedApplication, fitStatus, maybeStatus, notFitStatus) => {
  try {
    let filters = {
      campaignId: campaignId,
      appliedDate: true,
      acceptedApplication: acceptedApplication,
      rejectedApplication: rejectedApplication,
      fitStatus: fitStatus,
      maybeStatus: maybeStatus,
      notFitStatus: notFitStatus
    };
    const response = await fetch(`${baseUrl}/admin/export-applications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify(filters)
    });
    if (!response.ok) {
      console.error('Failed to export campagn application');
    } else {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', "campaign-applications.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    console.error('Failed to export campagn application');
  }
};

export const fetchEnterpriseClients = async (setEnterpriseClients) => {
  try {
    const response = await fetch(`${baseUrl}/get-campaign-ec`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
    });
    const data = await response.json();
    if (response.ok) {
      setEnterpriseClients(data.users);
    } else if (response.status === 499) {
      refreshToken();
    }
  } catch (error) {
    console.error("Error fetching enterprise client:", error);
  }
};

export const closeCampaign = async (campaignId, previouseStatus, navigate) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');
  const startPath = '/' + pathSegments[1];
  const response = await fetch(`${baseUrl}/admin/close-campaign?campaignId=${campaignId}&previouseStatus=${previouseStatus}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  if (response.ok) {
    navigate(`${startPath}/${previouseStatus.toLowerCase()}`);
  } else if (response.status === 499) {
    refreshToken();
  }
};

export const discardCampiagn = async (campaignId, navigate) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');
  const startPath = '/' + pathSegments[1];
  const response = await fetch(`${baseUrl}/admin/discard-campaign?campaignId=${campaignId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  if (response.ok) {
    navigate(`${startPath}/draft`);
  } else if (response.status === 499) {
    refreshToken();
  }
};

export const cancelCampaign = async (campaignId, previouseStatus, navigate) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');
  const startPath = '/' + pathSegments[1];
  const response = await fetch(`${baseUrl}/admin/cancel-campaign?campaignId=${campaignId}&previouseStatus=${previouseStatus}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  if (response.ok) {
    navigate(`${startPath}/${previouseStatus.toLowerCase()}`);
  } else if (response.status === 499) {
    refreshToken();
  }
};

export const getAcceptedApplicationCount = async (campaignId, setAcceptApplicationCount) => {
  const response = await fetch(`${baseUrl}/admin/accepted-application-count/${campaignId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  const data = await response.json();
  if (response.ok) {
    setAcceptApplicationCount(data);
  } else if (response.status === 499) {
    refreshToken();
  }
}
